/*******************************  Profarma Page ********************************************* */

import {
  Checkbox,
  React,
  TextField,
  LocalizationProvider,
  AdapterDateFns,
  Radio,
  useState,
  swal,
  axios,
  Button,
  useEffect,
  RadioGroup,
  FormControlLabel,
  DesktopDatePicker,
  Dialog,
  DialogContent,
  BeatLoader,
  NativeSelect,
  InputLabel,
  Cookies,
  moment,
  jwtDecode,
  Autocomplete,
  useCallback,
  Webcam,
  lodash,
} from "../commonFiles/Import";

const CreateProforma = () => {
  // get userId and adminUserName from session
  const userId = window.sessionStorage.getItem("adminUserId");
  const adminUserName = window.sessionStorage.getItem("adminUserName");

  const webcamRef = React.useRef(null);

  const [usedStates, setUsedStates] = useState({
    loading: false,

    profarmaDetails: {
      ACK_status: false,
      captureImage: null,
      email: "",
      mobileNumber: "",
      isProfarmaLink: false,
      userName: sessionStorage.getItem("userName"),
      transferYear: "",
      metalNo: "",
      empCode: "",
      snListNo: "",
      fullName: "",
      dateOfBirth: null,
      dateOfJoin: null,
      dateOfProbation: null,
      dateOfPromotion: null,
      dateOfPresentPlaceWork: null,
      gradationNo: "",
      anBn: "",
      designation: "",
      workinPlace: "",
      natureOfDuty: "",
      selfNativeAddress: "",
      selfPresentAddress: "",
      selfNPS: "",
      spouseNativeAddress: "",
      spousePresentAddress: "",
      spouseNPS: "",
      placeWilling: [],
      postPerticularData: [],
      multiplePendingDESData: [
        {
          DateOfIDE: null,
          briefIDE: "",
          statusOfCase: "",
          punishmentData: "",
          runningDE: "",
          punishmentDE: "",
        },
      ],
      multipleEnquiryData: [],
      RecomFromName: "",
      RecomFromDesignation: "",
      RecomMade: "",
      dateRecieved: null,
      RecomPlaceRecomended: "",
      DateOfIDE: null,
      briefIDE: "",
      statusOfCase: "",
      enquiryFromOtheragency: "",
      punishmentAwards: "",
      typeOfpunishmentAwards: "awards",
      division: "",
      entryBy: Cookies.get("linkToken")
        ? ""
        : sessionStorage.getItem("userName"),
      checkedBy: Cookies.get("linkToken")
        ? ""
        : sessionStorage.getItem("userName"),
      remark: "",
      reasons: "",
      otherReason: "",
      typeOfData: "",
      ACRGradings: Array.from({ length: 5 }, (_, index) => ({
        passoutYear: "",
        remarks: "",
      })),
      departmentExamDetails: {
        isPassed: false,
        examsDetails: [
          {
            typeOfExam: "Kannada Language Exam",
            regNo: "",
            session: "",
            date: null,
            type: "", // it will be pass / Exemption
            sslcRegNo: "",
            sslcDate: null,
            agencyName: "",
            agencyRegNo: "",
            agencyDate: null,
            certificationNo: "",
            certificationDate: null,
          },
          { typeOfExam: "CLT Exam", regNo: "", session: "", date: null },
          {
            typeOfExam: "General Law -1 & 2",
            regNo: "",
            session: "",
            date: null,
          },
          { typeOfExam: "Accounts Higher", regNo: "", session: "", date: null },
        ],
      },
      KK_NON_KK: "",
      caste: "",
      FIT_UN_FIT: "",
    },

    errors: {
      postPerticularDataError: [],
      metalNoError: "",
      empCodeError: "",
      fullNameError: "",
      dateOfBirthError: "",
      dateOfJoinError: "",
      dateOfProbationError: "",
      dateOfPromotionError: "",
      workingPlaceError: "",
      dateOfPresentPlaceWorkError: "",
      placeWillingError: "",
      reasonsError: "",
      snListNoError: "",
      emailError: "",
    },

    workingPlaces: [],
    designations: [],

    webcamModal: {
      open: false,
    },
  });

  /*************************** Destructering Objects start **********************/
  const {
    profarmaDetails,
    loading,
    errors,
    webcamModal,
    workingPlaces,
    designations,
  } = usedStates;

  const {
    ACK_status,
    email,
    mobileNumber,
    multipleEnquiryData,
    multiplePendingDESData,
    otherReason,
    isProfarmaLink,
    transferYear,
    metalNo,
    empCode,
    snListNo,
    fullName,
    dateOfBirth,
    dateOfJoin,
    designation,
    workinPlace,
    natureOfDuty,
    selfNativeAddress,
    selfPresentAddress,
    selfNPS,
    spouseNativeAddress,
    spousePresentAddress,
    spouseNPS,
    placeWilling,
    postPerticularData,
    reasons,
    RecomFromName,
    RecomFromDesignation,
    RecomMade,
    dateRecieved,
    RecomPlaceRecomended,
    DateOfIDE,
    briefIDE,
    statusOfCase,
    punishmentAwards,
    entryBy,
    checkedBy,
    remark,
    anBn,
    dateOfProbation,
    dateOfPromotion,
    dateOfPresentPlaceWork,
    gradationNo,
    typeOfpunishmentAwards,
    enquiryFromOtheragency,
    division,
    typeOfData,
    ACRGradings,
    departmentExamDetails,
    KK_NON_KK,
    caste,
    FIT_UN_FIT,
  } = profarmaDetails;

  const {
    postPerticularDataError,
    metalNoError,
    empCodeError,
    fullNameError,
    dateOfBirthError,
    dateOfJoinError,
    dateOfProbationError,
    dateOfPromotionError,
    workingPlaceError,
    dateOfPresentPlaceWorkError,
    placeWillingError,
    reasonsError,
    snListNoError,
    emailError,
  } = errors;

  const { isPassed, examsDetails } = departmentExamDetails;

  const { open } = webcamModal;

  /*************************** Destructering Objects end **********************/

  // get division based on police Station Name
  const getDivision = useCallback(
    async ({ policeStation }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getDivision", {
          params: { policeStation, userId },
        });

        // on success
        if (result.data.officeType) {
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: false,
              profarmaDetails: {
                ...prevValue.profarmaDetails,
                division: result.data.officeType,
              },
            };
          });
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });
        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  // update proforma link details
  const updateProfarmaLinkDetails = useCallback(async () => {
    try {
      // update proforma states and  disable the fields
      if (Cookies.get("linkToken")) {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const { isProfarmaLink, KGIDNo, transferYear, typeOfData } = jwtDecode(
          Cookies.get("linkToken")
        );

        const result = await axios.get("/getProFormaDetails", {
          params: { empCode: KGIDNo, transferYear, typeOfData, userId },
        });

        // on success
        if (result.data) {
          const {
            FIT_UNFIT,
            ExamData,
            ACRGradingData,
            enquiryDesc,
            gradationNo,
            CAST,
            AnBn,
            BriefRecomendation1,
            Cadre,
            DateReceived1,
            Date_birth,
            Date_join,
            EmpCode,
            FromDesig1,
            FromName1,
            Full_Name,
            MetalNo,
            Nature_desi,
            PlacesRecomended1,
            Self_NP,
            Self_NPS,
            Self_Taluka,
            SnList,
            Spouse_NP,
            Spouse_NPS,
            Spouse_Taluka,
            checkBy,
            enteredBy,
            postPerticularData,
            multipleEnquiryData,
            multiplePendingDESData,
            pres_Working_place,
            remark,
            Punish_Awards,
            dateOfProbation,
            dateOfPromotion,
            dateOfPresentWork,
            NKK_KK,
          } = result.data;

          // update value to differentiate whether it's pass / exemption
          if (ExamData.length > 0 && typeOfData === "P") {
            ExamData[0]["type"] =
              ExamData[0].isPassed === "true"
                ? ExamData[0]?.regNo
                  ? "Pass"
                  : "Exemption"
                : "";

            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                profarmaDetails: {
                  ...prevValue.profarmaDetails,
                  departmentExamDetails: {
                    ...prevValue.profarmaDetails.departmentExamDetails,
                    isPassed: ExamData[0]
                      ? ExamData[0].isPassed === "false"
                        ? false
                        : true
                      : false,
                    examsDetails: ExamData,
                  },
                },
              };
            });
          }

          // rename key
          const newMultiplePendingDESData = multiplePendingDESData.map(
            (data) => {
              // Assign new key
              data["DateOfIDE"] = data["DateofDE"];

              // Delete old key
              delete data["DateofDE"];

              return data;
            }
          );

          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              profarmaDetails: {
                ...prevValue.profarmaDetails,
                DateOfIDE: newMultiplePendingDESData[0].DateOfIDE,
                briefIDE: newMultiplePendingDESData[0].briefIDE,
                statusOfCase: newMultiplePendingDESData[0].statusOfCase,
                ACK_status: !isProfarmaLink,
                ACRGradings: ACRGradingData,
                caste: CAST,
                enquiryFromOtheragency: enquiryDesc,
                gradationNo: gradationNo.toString(),
                isProfarmaLink,
                transferYear,
                metalNo: MetalNo,
                KK_NON_KK: NKK_KK,
                snListNo: SnList,
                fullName: Full_Name,
                dateOfBirth: Date_birth,
                anBn: AnBn,
                dateOfProbation: dateOfProbation,
                dateOfPromotion: dateOfPromotion,
                designation: Cadre,
                workinPlace: pres_Working_place,
                dateOfPresentPlaceWork: dateOfPresentWork,
                natureOfDuty: Nature_desi,
                selfPresentAddress: Self_NP,
                selfNativeAddress: Self_Taluka,
                selfNPS: Self_NPS,
                spousePresentAddress: Spouse_NP,
                spouseNativeAddress: Spouse_Taluka,
                spouseNPS: Spouse_NPS,
                postPerticularData,
                multipleEnquiryData,
                multiplePendingDESData: newMultiplePendingDESData,
                RecomFromName: FromName1,
                RecomFromDesignation: FromDesig1,
                RecomMade: BriefRecomendation1,
                dateRecieved: DateReceived1,
                RecomPlaceRecomended: PlacesRecomended1,
                punishmentAwards: Punish_Awards,
                empCode: EmpCode.toString(),
                entryBy: enteredBy,
                checkedBy: checkBy,
                remark,
                dateOfJoin: Date_join,
                typeOfData,
                FIT_UN_FIT: FIT_UNFIT,
              },
            };
          });

          getDivision({ policeStation: pres_Working_place });
        }
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [getDivision, userId]);

  // handleInput event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          [name]: value,
        },
      };
    });
  };

  //handle Date
  const handleDate = ({ type, value }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          [type]: value,
        },
      };
    });
  };

  // handle post perticular data
  const handlePostPerticularData = ({ type, index, value }) => {
    postPerticularData[index][type] = value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          postPerticularData,
        },
      };
    });
  };

  // handle multiple pending DEs Data
  const handleMultiplePendingDEsData = ({ type, index, value }) => {
    multiplePendingDESData[index][type] = value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          multiplePendingDESData,
        },
      };
    });
  };

  // handle multiple enquiry Data
  const handleMultipleEnquiryData = ({ type, index, value }) => {
    multipleEnquiryData[index][type] = value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          multipleEnquiryData,
        },
      };
    });
  };

  // handle ACR gradings
  const handleACRGradings = ({ type, index, value }) => {
    ACRGradings[index][type] = value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          ACRGradings,
        },
      };
    });
  };

  // handle Exam Details
  const handleExamDetails = ({ type, index, value }) => {
    examsDetails[index][type] = value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          departmentExamDetails: {
            ...prevValue.profarmaDetails.departmentExamDetails,
            examsDetails,
          },
        },
      };
    });
  };

  // handle reset Errors
  const handleResetErrors = (props) => {
    // reset specific Errors
    if (props) {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          errors: {
            ...prevValue.errors,
            [props]: "",
          },
        };
      });
    }
  };

  // handle postPerticular Data errors
  const handlePostPerticularDataErrors = ({ type, index }) => {
    postPerticularDataError[index][type] = "";

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          postPerticularDataError,
        },
      };
    });
  };

  // handle reset All states
  const handleResetAllStates = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        profarmaDetails: {
          ...prevValue.profarmaDetails,
          captureImage: null,
          email: "",
          mobileNumber: "",
          isProfarmaLink: false,
          userName: sessionStorage.getItem("userName"),
          metalNo: "",
          empCode: "",
          snListNo: "",
          fullName: "",
          dateOfBirth: null,
          dateOfJoin: null,
          dateOfProbation: null,
          dateOfPromotion: null,
          dateOfPresentPlaceWork: null,
          gradationNo: "",
          anBn: "",
          workinPlace: "",
          natureOfDuty: "",
          selfNativeAddress: "",
          selfPresentAddress: "",
          selfNPS: "",
          spouseNativeAddress: "",
          spousePresentAddress: "",
          spouseNPS: "",
          placeWilling: [],
          postPerticularData: [],
          multiplePendingDESData: [
            {
              DateOfIDE: null,
              briefIDE: "",
              statusOfCase: "",
              punishmentData: "",
              runningDE: "",
              punishmentDE: "",
            },
          ],
          multipleEnquiryData: [],
          RecomFromName: "",
          RecomFromDesignation: "",
          RecomMade: "",
          dateRecieved: null,
          RecomPlaceRecomended: "",
          DateOfIDE: null,
          briefIDE: "",
          statusOfCase: "",
          enquiryFromOtheragency: "",
          punishmentAwards: "",
          typeOfpunishmentAwards: "awards",
          division: "",
          entryBy: sessionStorage.getItem("userName"),
          checkedBy: sessionStorage.getItem("userName"),
          remark: "",
          reasons: "",
          otherReason: "",
          ACRGradings: Array.from({ length: 5 }, (_, index) => ({
            passoutYear: "",
            remarks: "",
          })),
          departmentExamDetails: {
            ...prevValue.profarmaDetails.departmentExamDetails,
            isPassed: false,
            examsDetails: [
              {
                typeOfExam: "Kannada Language Exam",
                regNo: "",
                session: "",
                date: null,
                type: "", // it will be pass / Exemption
                sslcRegNo: "",
                sslcDate: null,
                agencyName: "",
                agencyRegNo: "",
                agencyDate: null,
                certificationNo: "",
                certificationDate: null,
              },
              { typeOfExam: "CLT Exam", regNo: "", session: "", date: null },
              {
                typeOfExam: "General Law -1 & 2",
                regNo: "",
                session: "",
                date: null,
              },
              {
                typeOfExam: "Accounts Higher",
                regNo: "",
                session: "",
                date: null,
              },
            ],
          },
          KK_NON_KK: "",
          caste: "",
          FIT_UN_FIT: "",
        },
        errors: {
          ...prevValue.errors,
          postPerticularDataError: [],
          metalNoError: "",
          empCodeError: "",
          fullNameError: "",
          dateOfBirthError: "",
          dateOfJoinError: "",
          dateOfProbationError: "",
          dateOfPromotionError: "",
          workingPlaceError: "",
          dateOfPresentPlaceWorkError: "",
          placeWillingError: "",
          reasonsError: "",
          snListNoError: "",
          emailError: "",
        },
      };
    });
  };

  // handle Submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable Loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      //added because web app taking one day before  selected date
      profarmaDetails["dateOfBirth"] = moment(
        profarmaDetails["dateOfBirth"]
      ).format("YYYY-MM-DD");

      profarmaDetails["dateOfJoin"] = moment(
        profarmaDetails["dateOfJoin"]
      ).format("YYYY-MM-DD");

      profarmaDetails["dateOfProbation"] = moment(
        profarmaDetails["dateOfProbation"]
      ).format("YYYY-MM-DD");

      profarmaDetails["dateOfPromotion"] = profarmaDetails["dateOfPromotion"]
        ? moment(profarmaDetails["dateOfProbation"]).format("YYYY-MM-DD")
        : profarmaDetails["dateOfPromotion"];

      profarmaDetails["dateOfPresentPlaceWork"] = moment(
        profarmaDetails["dateOfPresentPlaceWork"]
      ).format("YYYY-MM-DD");

      profarmaDetails["DateOfIDE"] = profarmaDetails["DateOfIDE"]
        ? moment(profarmaDetails["DateOfIDE"]).format("YYYY-MM-DD")
        : profarmaDetails["DateOfIDE"];

      profarmaDetails["dateRecieved"] = profarmaDetails["dateRecieved"]
        ? moment(profarmaDetails["dateRecieved"]).format("YYYY-MM-DD")
        : profarmaDetails["dateRecieved"];

      // post perticular data
      postPerticularData.forEach((data) => {
        data["fromDate"] = moment(data["fromDate"]).format("YYYY-MM-DD");
        data["toDate"] = moment(data["toDate"]).format("YYYY-MM-DD");
      });

      // multiple pending DES data
      multiplePendingDESData.forEach((data) => {
        data["DateOfIDE"] = data["DateOfIDE"]
          ? moment(data["DateOfIDE"]).format("YYYY-MM-DD")
          : null;
      });

      multipleEnquiryData.forEach((data) => {
        data["DateOfEnquiry"] = data["DateOfEnquiry"]
          ? moment(data["DateOfEnquiry"]).format("YYYY-MM-DD")
          : null;
      });

      departmentExamDetails["examsDetails"][0].date = departmentExamDetails[
        "examsDetails"
      ][0].date
        ? moment(departmentExamDetails["examsDetails"][0].date).format(
            "YYYY-MM-DD"
          )
        : departmentExamDetails["examsDetails"][0].date;

      departmentExamDetails["examsDetails"][0].sslcDate = departmentExamDetails[
        "examsDetails"
      ][0].sslcDate
        ? moment(departmentExamDetails["examsDetails"][0].sslcDate).format(
            "YYYY-MM-DD"
          )
        : departmentExamDetails["examsDetails"][0].sslcDate;

      departmentExamDetails["examsDetails"][0].agencyDate =
        departmentExamDetails["examsDetails"][0].agencyDate
          ? moment(departmentExamDetails["examsDetails"][0].agencyDate).format(
              "YYYY-MM-DD"
            )
          : departmentExamDetails["examsDetails"][0].agencyDate;

      departmentExamDetails["examsDetails"][0].certificationDate =
        departmentExamDetails["examsDetails"][0].certificationDate
          ? moment(
              departmentExamDetails["examsDetails"][0].certificationDate
            ).format("YYYY-MM-DD")
          : departmentExamDetails["examsDetails"][0].certificationDate;

      departmentExamDetails["examsDetails"][1].date = departmentExamDetails[
        "examsDetails"
      ][1].date
        ? moment(departmentExamDetails["examsDetails"][1].date).format(
            "YYYY-MM-DD"
          )
        : departmentExamDetails["examsDetails"][1].date;

      departmentExamDetails["examsDetails"][2].date = departmentExamDetails[
        "examsDetails"
      ][2].date
        ? moment(departmentExamDetails["examsDetails"][2].date).format(
            "YYYY-MM-DD"
          )
        : departmentExamDetails["examsDetails"][2].date;

      departmentExamDetails["examsDetails"][3].date = departmentExamDetails[
        "examsDetails"
      ][3].date
        ? moment(departmentExamDetails["examsDetails"][3].date).format(
            "YYYY-MM-DD"
          )
        : departmentExamDetails["examsDetails"][3].date;

      //pass workingPlaces to match the entered data with dropdown data
      Object.assign(profarmaDetails, { workingPlaces, userId, adminUserName });

      const result = await axios.post("/storeProfarmaDetails", profarmaDetails);

      // on success
      if (result.data.success) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record saved successfully`,
          timer: "3000",
          buttons: false,
        }).then(() => {
          // reset all states
          handleResetAllStates();

          // close current window
          if (isProfarmaLink) {
            Cookies.remove("linkToken");
            window.close();
          }
        });
      }
    } catch (err) {
      // disable Loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // scroll top
      window.scrollTo(0, 0);

      // show errors to user
      const { errors } = err.response.data;

      // show custom Errors
      if (errors) {
        const {
          postPerticularDataError,
          metalNoError,
          empCodeError,
          fullNameError,
          dateOfBirthError,
          dateOfJoinError,
          dateOfProbationError,
          dateOfPromotionError,
          workingPlaceError,
          dateOfPresentPlaceWorkError,
          placeWillingError,
          reasonsError,
          snListNoError,
          emailError,
        } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              snListNoError,
              metalNoError,
              empCodeError,
              fullNameError,
              dateOfBirthError,
              dateOfJoinError,
              dateOfProbationError,
              dateOfPromotionError,
              workingPlaceError,
              dateOfPresentPlaceWorkError,
              placeWillingError,
              reasonsError,
              emailError,
              postPerticularDataError: postPerticularDataError
                ? postPerticularDataError
                : [],
            },
          };
        });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        const { settingStatusDetails } = result.data;

        const { actionType, designation, transferYear, ACK_status } =
          settingStatusDetails;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            profarmaDetails: {
              ...prevValue.profarmaDetails,
              typeOfData: actionType,
              designation,
              transferYear,
              ACK_status: ACK_status === "Y" ? true : false,
            },

            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get working place details
  const getWorkingPlaceDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getWorkingPlaceDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            workingPlaces: lodash.uniqBy(result.data, "Name"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  useEffect(() => {
    getSettingData();
    getDesignationDetails();
    getWorkingPlaceDetails();
    updateProfarmaLinkDetails();
  }, [
    updateProfarmaLinkDetails,
    getSettingData,
    getDesignationDetails,
    getWorkingPlaceDetails,
  ]);

  return (
    <>
      <section
        className="form-section"
        style={{ marginTop: isProfarmaLink ? "1rem" : "5rem" }}
        // disable rightClick is it's user acknowldegment form
        onContextMenu={(event) => {
          if (isProfarmaLink) {
            event.preventDefault();
          }
        }}
      >
        <div className="container-fluid">
          <form
            method="POST"
            autoComplete="off"
            onSubmit={handleSubmit}
            id="proformaForm"
          >
            <div className="card  border-0 mb-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-2">
                    <h1 className="fs-4 fw-bold">Create Proforma</h1>
                  </div>

                  {/* Type of data */}
                  {isProfarmaLink ? null : (
                    <div className="col-xl">
                      <RadioGroup row className="ms-3" value={typeOfData}>
                        {/* General Transfer */}
                        <FormControlLabel
                          value="G"
                          control={<Radio size="small" />}
                          label="General Transfer"
                          name="typeOfData"
                          id="typeOfData"
                          onChange={(event) => {
                            handleInputEvent(event);
                            handleResetAllStates();
                          }}
                        />

                        {/* Request Transfer */}
                        <FormControlLabel
                          value="T"
                          control={<Radio size="small" />}
                          label="Request Transfer"
                          name="typeOfData"
                          id="typeOfData"
                          onChange={(event) => {
                            handleInputEvent(event);
                            handleResetAllStates();
                          }}
                        />

                        {/* Promotion */}
                        <FormControlLabel
                          value="P"
                          control={<Radio size="small" />}
                          label="Promotion"
                          name="typeOfData"
                          id="typeOfData"
                          onChange={(event) => {
                            handleInputEvent(event);
                            handleResetAllStates();
                          }}
                        />
                      </RadioGroup>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className={isProfarmaLink ? "pe-none row g-1" : "row g-1"}>
                  {/* Name and metal Number */}
                  {typeOfData !== "P" ? (
                    <div className="col-md-6">
                      <div className="card h-100 ">
                        <div className="card-header fw-bold ">
                          Name and Metal No
                        </div>
                        <div className="card-body">
                          <div className="row g-2">
                            {/* Transfer Year */}
                            <div className="col-sm-2 pe-none">
                              <TextField
                                label="Transfer year"
                                id="transferYear"
                                name="transferYear"
                                variant="standard"
                                fullWidth
                                value={transferYear}
                              />
                            </div>

                            {/* Metal Number */}
                            <div className="col-sm-6">
                              <TextField
                                label="Metal number(Only for CHC and CPC)"
                                required={[
                                  "Civil Head Constable",
                                  "Civil Police Constable",
                                ].includes(designation)}
                                id="metalNo"
                                name="metalNo"
                                variant="standard"
                                fullWidth
                                value={metalNo}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("metalNoError");
                                }}
                                error={metalNoError ? true : false}
                                helperText={metalNoError}
                              />
                            </div>

                            {/* NKK OR KK */}
                            <div className="col-sm-4 d-flex flex-column justfy-content-start">
                              <RadioGroup
                                row
                                value={KK_NON_KK}
                                className="ms-3"
                              >
                                <FormControlLabel
                                  required
                                  value="NKK"
                                  control={<Radio size="small" />}
                                  label="NKK"
                                  name="KK_NON_KK"
                                  id="KK_NON_KK"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                                <FormControlLabel
                                  required
                                  value="KK"
                                  control={<Radio size="small" />}
                                  label="KK"
                                  name="KK_NON_KK"
                                  id="KK_NON_KK"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                              </RadioGroup>
                            </div>

                            {/* Employee Code */}
                            <div className="col-sm-6">
                              <TextField
                                label="KGID No"
                                required
                                id="empCode"
                                name="empCode"
                                variant="standard"
                                fullWidth
                                value={empCode}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("empCodeError");
                                }}
                                error={empCodeError ? true : false}
                                helperText={empCodeError}
                              />
                            </div>

                            {/* SNList No */}
                            <div className="col-sm-6">
                              <TextField
                                label="Sn.List No"
                                required
                                id="snListNo"
                                name="snListNo"
                                variant="standard"
                                fullWidth
                                value={snListNo}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("snListNoError");
                                }}
                                error={snListNoError ? true : false}
                                helperText={snListNoError}
                              />
                            </div>

                            {/* Full Name */}
                            <div className="col-sm-6">
                              <TextField
                                label="Full Name( as per SR)"
                                required
                                id="fullName"
                                name="fullName"
                                variant="standard"
                                fullWidth
                                value={fullName}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("fullNameError");
                                }}
                                error={fullNameError ? true : false}
                                helperText={fullNameError}
                              />
                            </div>

                            {/* Date of birth */}
                            <div className="col-sm-6">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  label="DOB"
                                  value={dateOfBirth}
                                  disableFuture
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      variant="standard"
                                      required
                                      fullWidth
                                      {...props}
                                      name="dateOfBirth"
                                      id="dateOfBirth"
                                      size="small"
                                      error={dateOfBirthError ? true : false}
                                      helperText={dateOfBirthError}
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfBirth",
                                      value: newValue,
                                    });

                                    handleResetErrors("dateOfBirthError");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* Date of entry into service */}
                            <div className="col-sm-6">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  disableFuture
                                  label="DOA"
                                  value={dateOfJoin}
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      required
                                      variant="standard"
                                      fullWidth
                                      {...props}
                                      name="dateOfJoin"
                                      id="dateOfJoin"
                                      size="small"
                                      error={dateOfJoinError ? true : false}
                                      helperText={dateOfJoinError}
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfJoin",
                                      value: newValue,
                                    });
                                    handleResetErrors("dateOfJoinError");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* AN and BN */}
                            <div className="col-sm-6 d-flex flex-column">
                              <RadioGroup row className="ms-3" value={anBn}>
                                <FormControlLabel
                                  required
                                  value="An"
                                  control={<Radio size="small" />}
                                  label="An"
                                  name="anBn"
                                  id="anBn"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                                <FormControlLabel
                                  required
                                  value="Bn"
                                  control={<Radio size="small" />}
                                  label="Bn"
                                  name="anBn"
                                  id="anBn"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                              </RadioGroup>
                            </div>

                            {/* Date of Probationary declared  */}
                            <div className="col-sm-6">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  label="Date of Probationary declared"
                                  value={dateOfProbation}
                                  disableFuture
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      variant="standard"
                                      fullWidth
                                      required
                                      {...props}
                                      name="dateOfProbation"
                                      id="dateOfProbation"
                                      size="small"
                                      error={
                                        dateOfProbationError ? true : false
                                      }
                                      helperText={dateOfProbationError}
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfProbation",
                                      value: newValue,
                                    });

                                    handleResetErrors("dateOfProbationError");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* Date of Promotion as CHC/ASI/PSI  */}
                            <div className="col-sm-6">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  disableFuture
                                  label="Date of Promotion as CHC/ASI/PSI"
                                  value={dateOfPromotion}
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      required={[
                                        "Civil Head Constable",
                                        "Assistant Sub-Inspector",
                                        "Police Sub-Inspector",
                                      ].includes(designation)}
                                      variant="standard"
                                      fullWidth
                                      {...props}
                                      name="dateOfPromotion"
                                      id="dateOfPromotion"
                                      size="small"
                                      error={
                                        dateOfPromotionError ? true : false
                                      }
                                      helperText={dateOfPromotionError}
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfPromotion",
                                      value: newValue,
                                    });
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <div className="card h-100 ">
                        <div className="card-header fw-bold ">
                          Name and Metal No
                        </div>
                        <div className="card-body">
                          <div className="row g-2">
                            {/* Promotion year*/}
                            <div className="col-sm-4">
                              <TextField
                                label="Promotion year"
                                required
                                id="transferYear"
                                name="transferYear"
                                variant="standard"
                                fullWidth
                                value={transferYear}
                              />
                            </div>

                            {/* Employee Code */}
                            <div className="col-sm-4">
                              <TextField
                                label="KGID No"
                                required
                                id="empCode"
                                name="empCode"
                                variant="standard"
                                fullWidth
                                value={empCode}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("empCodeError");
                                }}
                                error={empCodeError ? true : false}
                                helperText={empCodeError}
                              />
                            </div>

                            {/* NKK OR KK */}
                            <div className="col-sm-4 d-flex flex-column justfy-content-start">
                              <RadioGroup
                                row
                                className="ms-3"
                                value={KK_NON_KK}
                              >
                                <FormControlLabel
                                  required
                                  value="NKK"
                                  control={<Radio size="small" />}
                                  label="NKK"
                                  name="KK_NON_KK"
                                  id="KK_NON_KK"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                                <FormControlLabel
                                  required
                                  value="KK"
                                  control={<Radio size="small" />}
                                  label="KK"
                                  name="KK_NON_KK"
                                  id="KK_NON_KK"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                              </RadioGroup>
                            </div>

                            {/* Full Name */}
                            <div className="col-sm-6">
                              <TextField
                                label="Full Name( as per SR)"
                                required
                                id="fullName"
                                name="fullName"
                                variant="standard"
                                fullWidth
                                value={fullName}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("fullNameError");
                                }}
                                error={fullNameError ? true : false}
                                helperText={fullNameError}
                              />
                            </div>

                            {/* Metal Number */}
                            <div className="col-sm-6">
                              <TextField
                                label="Metal number(Only for CHC and CPC)"
                                required={[
                                  "Civil Head Constable",
                                  "Civil Police Constable",
                                ].includes(designation)}
                                id="metalNo"
                                name="metalNo"
                                variant="standard"
                                fullWidth
                                value={metalNo}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("metalNoError");
                                }}
                                error={metalNoError ? true : false}
                                helperText={metalNoError}
                              />
                            </div>

                            {/* Date of birth */}
                            <div className="col-sm-4">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  label="DOB"
                                  value={dateOfBirth}
                                  disableFuture
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      variant="standard"
                                      required
                                      fullWidth
                                      {...props}
                                      name="dateOfBirth"
                                      id="dateOfBirth"
                                      size="small"
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfBirth",
                                      value: newValue,
                                    });

                                    handleResetErrors("dateOfBirthError");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* Date of entry into service */}
                            <div className="col-sm-4">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  disableFuture
                                  label="DOA"
                                  value={dateOfJoin}
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      variant="standard"
                                      required
                                      fullWidth
                                      {...props}
                                      name="dateOfJoin"
                                      id="dateOfJoin"
                                      size="small"
                                      error={dateOfJoinError ? true : false}
                                      helperText={dateOfJoinError}
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfJoin",
                                      value: newValue,
                                    });
                                    handleResetErrors("dateOfJoinError");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* AN and BN */}
                            <div className="col-sm-4 d-flex  flex-column">
                              <RadioGroup row className="ms-3" value={anBn}>
                                <FormControlLabel
                                  required
                                  value="An"
                                  control={<Radio size="small" />}
                                  label="An"
                                  name="anBn"
                                  id="anBn"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                    handleResetErrors("anBnError");
                                  }}
                                />
                                <FormControlLabel
                                  required
                                  value="Bn"
                                  control={<Radio size="small" />}
                                  label="Bn"
                                  name="anBn"
                                  id="anBn"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                    handleResetErrors("anBnError");
                                  }}
                                />
                              </RadioGroup>
                            </div>

                            {/* Date of Probationary declared  */}
                            <div className="col-sm-6">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  label="Date of Probationary declared"
                                  value={dateOfProbation}
                                  disableFuture
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      variant="standard"
                                      fullWidth
                                      required
                                      {...props}
                                      name="dateOfProbation"
                                      id="dateOfProbation"
                                      size="small"
                                      error={
                                        dateOfProbationError ? true : false
                                      }
                                      helperText={dateOfProbationError}
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfProbation",
                                      value: newValue,
                                    });

                                    handleResetErrors("dateOfProbationError");
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* Date of Promotion as CHC/ASI/PSI  */}
                            <div className="col-sm-6">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  disableFuture
                                  label="Date of Promotion as CHC/ASI/PSI"
                                  value={dateOfPromotion}
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      required={[
                                        "Civil Head Constable",
                                        "Assistant Sub-Inspector",
                                        "Police Sub-Inspector",
                                      ].includes(designation)}
                                      variant="standard"
                                      fullWidth
                                      {...props}
                                      name="dateOfPromotion"
                                      id="dateOfPromotion"
                                      size="small"
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "dateOfPromotion",
                                      value: newValue,
                                    });
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* caste */}
                            <div className="col-sm-12">
                              <InputLabel>Select caste*</InputLabel>
                              <NativeSelect
                                required
                                fullWidth
                                variant="standard"
                                inputProps={{
                                  name: "caste",
                                  id: "caste",
                                }}
                                onChange={(event) => {
                                  setUsedStates((prevValue) => {
                                    return {
                                      ...prevValue,
                                      profarmaDetails: {
                                        ...prevValue.profarmaDetails,
                                        caste: event.target.value,
                                      },
                                    };
                                  });
                                }}
                                value={caste}
                              >
                                <option value=""></option>
                                {["SC", "ST", "Others"].map((data, index) => {
                                  return (
                                    <option key={index} value={data}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Present Designation and working place */}
                  <div
                    className={isProfarmaLink ? "pe-none col-md-6" : "col-md-6"}
                  >
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        Present Designation and working place
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* Rank */}
                          <div className="col-sm-12">
                            <InputLabel>Designation*</InputLabel>
                            <NativeSelect
                              fullWidth
                              required
                              variant="standard"
                              inputProps={{
                                name: "designation",
                                id: "designation",
                              }}
                              onChange={(event) => {
                                handleInputEvent(event);
                              }}
                              value={designation}
                            >
                              {designations.map((data, index) => {
                                return (
                                  <option key={index} value={data.Cadre}>
                                    {data.Cadre}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </div>

                          {/* Present Place of working */}
                          <div className="col-sm-6">
                            <InputLabel>Present Place of working*</InputLabel>
                            <Autocomplete
                              disableClearable
                              onChange={(event, value) => {
                                setUsedStates((prevValue) => {
                                  return {
                                    ...prevValue,
                                    profarmaDetails: {
                                      ...prevValue.profarmaDetails,
                                      workinPlace: value.Name,
                                    },
                                  };
                                });

                                handleResetErrors("workingPlaceError");

                                // get division based on police Station
                                getDivision({
                                  policeStation: value.Name,
                                });
                              }}
                              inputValue={workinPlace}
                              options={workingPlaces}
                              getOptionLabel={(options) => options.Name}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  name="workinPlace"
                                  id="workinPlace"
                                  variant="standard"
                                  {...params}
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                    handleResetErrors("workingPlaceError");
                                  }}
                                  error={workingPlaceError ? true : false}
                                  helperText={workingPlaceError}
                                />
                              )}
                            />
                          </div>

                          {/* Division */}
                          <div className="col-sm-6 pe-none">
                            <TextField
                              variant="standard"
                              fullWidth
                              label="Division"
                              id="division"
                              name="division"
                              className="mt-2"
                              value={division}
                            />
                          </div>

                          {/* Present place of working date  */}
                          <div
                            className={
                              typeOfData !== "P" ? "col-sm-12" : "col-sm-6"
                            }
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                disableFuture
                                label="Present place of working date"
                                value={dateOfPresentPlaceWork}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(props) => (
                                  <TextField
                                    required
                                    variant="standard"
                                    fullWidth
                                    {...props}
                                    name="dateOfPresentPlaceWork"
                                    id="dateOfPresentPlaceWork"
                                    size="small"
                                    error={
                                      dateOfPresentPlaceWorkError ? true : false
                                    }
                                    helperText={dateOfPresentPlaceWorkError}
                                  />
                                )}
                                onChange={(newValue) => {
                                  handleDate({
                                    type: "dateOfPresentPlaceWork",
                                    value: newValue,
                                  });
                                  handleResetErrors(
                                    "dateOfPresentPlaceWorkError"
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </div>

                          {/*Gradation Number  */}
                          {typeOfData === "P" ? (
                            <div className="col-sm-6">
                              <TextField
                                variant="standard"
                                fullWidth
                                label="Gradation Number "
                                id="gradationNo"
                                name="gradationNo"
                                value={gradationNo}
                                onChange={handleInputEvent}
                                required
                              />
                            </div>
                          ) : null}

                          {/* Nature of Duty */}
                          <div className="col-sm-12">
                            <TextField
                              variant="standard"
                              fullWidth
                              label="Nature of Duty"
                              id="natureOfDuty"
                              name="natureOfDuty"
                              value={natureOfDuty}
                              onChange={handleInputEvent}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-1 mt-1">
                  {/* self native place Details */}
                  <div className={isProfarmaLink ? "col-md pe-none" : "col-md"}>
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        Self native place details
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* Present Address */}
                          <div className="col-sm-12">
                            <TextField
                              name="selfPresentAddress"
                              id="selfPresentAddress"
                              label="Present Address"
                              fullWidth
                              variant="standard"
                              value={selfPresentAddress}
                              onChange={handleInputEvent}
                            />
                          </div>

                          {/* Native Address */}
                          <div className="col-sm-12">
                            <TextField
                              name="selfNativeAddress"
                              id="selfNativeAddress"
                              label="Native Address"
                              fullWidth
                              variant="standard"
                              value={selfNativeAddress}
                              onChange={handleInputEvent}
                            />
                          </div>

                          {/* Jurisdiction Police station */}
                          <div className="col-sm-12">
                            <InputLabel>Jurisdiction Police station</InputLabel>
                            <NativeSelect
                              fullWidth
                              variant="standard"
                              inputProps={{
                                name: "selfNPS",
                                id: "selfNPS",
                              }}
                              onChange={(event) => {
                                handleInputEvent(event);
                              }}
                              value={selfNPS}
                            >
                              <option value=""></option>
                              {workingPlaces.map((data, index) => {
                                return (
                                  <option key={index} value={data.Name}>
                                    {data.Name}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* spouse native place details */}
                  <div className={isProfarmaLink ? "col-md pe-none" : "col-md"}>
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        Spouse native place details
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* Present Address  */}
                          <div className="col-sm-12">
                            <TextField
                              name="spousePresentAddress"
                              id="spousePresentAddress"
                              label="Present Address "
                              fullWidth
                              variant="standard"
                              value={spousePresentAddress}
                              onChange={handleInputEvent}
                            />
                          </div>

                          {/* Native address */}
                          <div className="col-sm-12">
                            <TextField
                              name="spouseNativeAddress"
                              id="spouseNativeAddress"
                              label="Native Address"
                              fullWidth
                              variant="standard"
                              value={spouseNativeAddress}
                              onChange={handleInputEvent}
                            />
                          </div>

                          {/* Jurisdiction Police station */}
                          <div className="col-sm-12">
                            <InputLabel>Jurisdiction Police station</InputLabel>
                            <NativeSelect
                              fullWidth
                              variant="standard"
                              inputProps={{
                                name: "spouseNPS",
                                id: "spouseNPS",
                              }}
                              onChange={(event) => {
                                handleInputEvent(event);
                              }}
                              value={spouseNPS}
                            >
                              <option value=""></option>
                              {workingPlaces.map((data, index) => {
                                return (
                                  <option key={index} value={data.Name}>
                                    {data.Name}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Places willing to go */}
                  {typeOfData !== "P" ? (
                    <div className="col-md-4">
                      <div className="card h-100">
                        <div className="card-header fw-bold">
                          Places willing to go (Maximum 3 options)
                        </div>
                        <div className="card-body">
                          <div className="row g-2">
                            {/* Police station */}
                            <div className="col-sm-12">
                              <Autocomplete
                                disabled={ACK_status ? true : false}
                                multiple
                                className="w-100"
                                disableClearable
                                onChange={(event, values) => {
                                  setUsedStates((prevValue) => {
                                    return {
                                      ...prevValue,
                                      profarmaDetails: {
                                        ...prevValue.profarmaDetails,
                                        placeWilling: values,
                                      },
                                    };
                                  });
                                }}
                                value={placeWilling}
                                options={workingPlaces}
                                getOptionLabel={(options) => options.Name}
                                renderInput={(params) => (
                                  <TextField
                                    label="Police Station*"
                                    variant="standard"
                                    error={placeWillingError ? true : false}
                                    helperText={placeWillingError}
                                    {...params}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* Previous Place of working with date/ tenure */}
                <div
                  className={
                    isProfarmaLink ? "row g-1 mt-1 pe-none" : "row g-1 mt-1"
                  }
                >
                  <div className="col-xl">
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        Previous Place of working with date/ tenure
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          <div className="col-xl-12 postPerticularData">
                            <table className="table table-bordered table-sm">
                              <thead className="table-dark">
                                <tr className="fw-bold">
                                  <th scope="col" className="text-start">
                                    Police Station
                                  </th>
                                  <th scope="col" className="text-start">
                                    From
                                  </th>
                                  <th scope="col" className="text-start">
                                    To
                                  </th>
                                  <th scope="col" className="text-start">
                                    OOD or Trans or Deputation
                                  </th>
                                  <th scope="col" className="text-start">
                                    Executive or Non-Executive
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {postPerticularData.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      {/* police station */}
                                      <td className="text-start w-25">
                                        <NativeSelect
                                          fullWidth
                                          variant="standard"
                                          inputProps={{
                                            name: "workedPlace",
                                            id: "workedPlace",
                                          }}
                                          onChange={(event) => {
                                            handlePostPerticularData({
                                              type: "workedPlace",
                                              index,
                                              value: event.target.value,
                                            });

                                            handlePostPerticularDataErrors({
                                              type: "workedPlaceError",
                                              index,
                                            });
                                          }}
                                          value={
                                            postPerticularData[index]
                                              .workedPlace
                                          }
                                          error={
                                            postPerticularDataError[index]
                                              ?.workedPlaceError
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value=""></option>
                                          {workingPlaces.map((data, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={data.Name}
                                              >
                                                {data.Name}
                                              </option>
                                            );
                                          })}
                                        </NativeSelect>
                                        <small
                                          style={{
                                            color: "var(--error-color)",
                                          }}
                                        >
                                          {
                                            postPerticularDataError[index]
                                              ?.workedPlaceError
                                          }
                                        </small>
                                      </td>

                                      {/* From */}
                                      <td className="text-start">
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DesktopDatePicker
                                            value={
                                              postPerticularData[index].fromDate
                                            }
                                            disableFuture
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(props) => (
                                              <TextField
                                                variant="standard"
                                                fullWidth
                                                {...props}
                                                name="fromDate"
                                                id="fromDate"
                                                size="small"
                                                error={
                                                  postPerticularDataError[index]
                                                    ?.fromDateError
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  postPerticularDataError[index]
                                                    ?.fromDateError
                                                }
                                              />
                                            )}
                                            onChange={(newValue) => {
                                              handlePostPerticularData({
                                                type: "fromDate",
                                                index,
                                                value: newValue,
                                              });

                                              handlePostPerticularDataErrors({
                                                type: "fromDateError",
                                                index,
                                              });
                                            }}
                                          />
                                        </LocalizationProvider>
                                      </td>

                                      {/* To */}
                                      <td className="text-start">
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <DesktopDatePicker
                                            value={
                                              postPerticularData[index].toDate
                                            }
                                            disableFuture
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(props) => (
                                              <TextField
                                                variant="standard"
                                                fullWidth
                                                {...props}
                                                name="toDate"
                                                id="toDate"
                                                size="small"
                                                error={
                                                  postPerticularDataError[index]
                                                    ?.toDateError
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  postPerticularDataError[index]
                                                    ?.toDateError
                                                }
                                              />
                                            )}
                                            onChange={(newValue) => {
                                              handlePostPerticularData({
                                                type: "toDate",
                                                index,
                                                value: newValue,
                                              });

                                              handlePostPerticularDataErrors({
                                                type: "toDateError",
                                                index,
                                              });
                                            }}
                                          />
                                        </LocalizationProvider>
                                      </td>

                                      {/* OOD or Trans or Deputation */}
                                      <td className="text-start">
                                        <NativeSelect
                                          fullWidth
                                          variant="standard"
                                          inputProps={{
                                            name: "ODTransfers",
                                            id: "ODTransfers",
                                          }}
                                          onChange={(event) => {
                                            handlePostPerticularData({
                                              type: "ODTransfers",
                                              index,
                                              value: event.target.value,
                                            });

                                            handlePostPerticularDataErrors({
                                              type: "ODTransfersError",
                                              index,
                                            });
                                          }}
                                          value={
                                            postPerticularData[index]
                                              .ODTransfers
                                          }
                                          error={
                                            postPerticularDataError[index]
                                              ?.ODTransfersError
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value=""></option>
                                          {["OOD", "Trans", "Deputation"].map(
                                            (data, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={data}
                                                >
                                                  {data}
                                                </option>
                                              );
                                            }
                                          )}
                                        </NativeSelect>
                                        <small
                                          style={{
                                            color: "var(--error-color)",
                                          }}
                                        >
                                          {
                                            postPerticularDataError[index]
                                              ?.ODTransfersError
                                          }
                                        </small>
                                      </td>

                                      {/*	Executive or Non-Executive */}
                                      <td className="text-start">
                                        <NativeSelect
                                          fullWidth
                                          variant="standard"
                                          inputProps={{
                                            name: "execNoExec",
                                            id: "execNoExec",
                                          }}
                                          onChange={(event) => {
                                            handlePostPerticularData({
                                              type: "execNoExec",
                                              index,
                                              value: event.target.value,
                                            });

                                            handlePostPerticularDataErrors({
                                              type: "execNoExecError",
                                              index,
                                            });
                                          }}
                                          value={
                                            postPerticularData[index].execNoExec
                                          }
                                          error={
                                            postPerticularDataError[index]
                                              ?.execNoExecError
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value=""></option>
                                          {["Non-Executive", "Executive"].map(
                                            (data, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={data}
                                                >
                                                  {data}
                                                </option>
                                              );
                                            }
                                          )}
                                        </NativeSelect>
                                        <small
                                          style={{
                                            color: "var(--error-color)",
                                          }}
                                        >
                                          {
                                            postPerticularDataError[index]
                                              ?.execNoExecError
                                          }
                                        </small>
                                      </td>
                                    </tr>
                                  );
                                })}

                                <tr>
                                  <td colSpan={5}>
                                    {/* Add Row */}
                                    <Button
                                      className={
                                        isProfarmaLink
                                          ? "d-none "
                                          : "success-button"
                                      }
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        postPerticularData.push({
                                          workedPlace: "",
                                          PS: "other",
                                          CPI: "other",
                                          DSP: "other",
                                          ODTransfers: "",
                                          execNoExec: "",
                                          fromDate: null,
                                          toDate: null,
                                        });

                                        postPerticularDataError.push({
                                          workedPlaceError: "",
                                          ODTransfersError: "",
                                          execNoExecError: "",
                                          fromDateError: "",
                                          toDateError: "",
                                        });

                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            profarmaDetails: {
                                              ...prevValue.profarmaDetails,
                                              postPerticularData,
                                            },
                                            errors: {
                                              ...prevValue.errors,
                                              postPerticularDataError,
                                            },
                                          };
                                        });
                                      }}
                                    >
                                      Add Row
                                    </Button>

                                    {/* Clear Row */}
                                    {postPerticularData.length > 0 ? (
                                      <Button
                                        className={
                                          isProfarmaLink
                                            ? "d-none"
                                            : "reset-button ms-2"
                                        }
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                          if (postPerticularData.length > 0) {
                                            postPerticularData.pop();

                                            postPerticularDataError.pop();

                                            setUsedStates((prevValue) => {
                                              return {
                                                ...prevValue,
                                                profarmaDetails: {
                                                  ...prevValue.profarmaDetails,
                                                  postPerticularData,
                                                },
                                                errors: {
                                                  ...prevValue.errors,
                                                  postPerticularDataError,
                                                },
                                              };
                                            });
                                          }
                                        }}
                                      >
                                        clear
                                      </Button>
                                    ) : null}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Reasons for transfer request */}
                {typeOfData !== "P" ? (
                  <div className="row g-1 mt-1">
                    <div className="col-sm-12">
                      <div className="card h-100">
                        <div className="card-header fw-bold">
                          Reasons for transfer request
                        </div>
                        <div className="card-body">
                          <div className="row g-2">
                            {/* Reasons */}
                            <div className="col-sm-12">
                              <InputLabel>Reason*</InputLabel>
                              <NativeSelect
                                required
                                disabled={ACK_status ? true : false}
                                error={reasonsError ? true : false}
                                fullWidth
                                variant="standard"
                                inputProps={{
                                  name: "reasons",
                                  id: "reasons",
                                }}
                                onChange={(event) => {
                                  handleInputEvent(event);

                                  handleResetErrors("reasonsError");

                                  //reset other reason value if reason is not Other
                                  if (event.target.value !== "Others") {
                                    setUsedStates((prevValue) => {
                                      return {
                                        ...prevValue,
                                        profarmaDetails: {
                                          ...prevValue.profarmaDetails,
                                          otherReason: "",
                                        },
                                      };
                                    });
                                  }
                                }}
                                value={reasons}
                              >
                                <option value=""></option>
                                {[
                                  { reason: "Medical" },
                                  { reason: "Family issues" },
                                  { reason: "Distance" },
                                  { reason: "Couple case" },
                                  { reason: "single parent" },
                                  { reason: "Others" },
                                ].map((data, index) => {
                                  return (
                                    <option key={index} value={data.reason}>
                                      {data.reason}
                                    </option>
                                  );
                                })}
                              </NativeSelect>
                              <small style={{ color: "var(--error-color)" }}>
                                {" "}
                                {reasonsError}
                              </small>

                              {/* show textfield if Other option is selected */}
                              {reasons === "Others" ? (
                                <div className="row mt-2">
                                  <div className="col-xl-12">
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      label="Others"
                                      value={otherReason}
                                      onChange={(event) => {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            profarmaDetails: {
                                              ...prevValue.profarmaDetails,
                                              otherReason: event.target.value,
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div
                  className={
                    isProfarmaLink ? "row g-1 mt-1 pe-none" : "row g-1 mt-1"
                  }
                >
                  {/* DE Details of applicant (from the date of Appointment ) */}
                  <div
                    className={typeOfData !== "P" ? "col-xl-12" : "col-xl-6"}
                  >
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        DE Details of applicant (from the date of Appointment)
                      </div>
                      <div className="card-body">
                        {typeOfData === "P" ? (
                          <div className="row g-2">
                            {/* Date of IDE */}
                            <div className="col-xl-12">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DesktopDatePicker
                                  label="Date of DE"
                                  value={DateOfIDE}
                                  disableFuture
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(props) => (
                                    <TextField
                                      variant="standard"
                                      fullWidth
                                      {...props}
                                      name="DateOfIDE"
                                      id="DateOfIDE"
                                      size="small"
                                    />
                                  )}
                                  onChange={(newValue) => {
                                    handleDate({
                                      type: "DateOfIDE",
                                      value: newValue,
                                    });
                                  }}
                                />
                              </LocalizationProvider>
                            </div>

                            {/* Brief Facts of the DE */}
                            <div className="col-xl-12">
                              <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="standard"
                                label="Brief Facts of the DE"
                                name="briefIDE"
                                id="briefIDE"
                                value={briefIDE}
                                onChange={handleInputEvent}
                              />
                            </div>

                            {/* Present Status of the case */}
                            <div className="col-xl-12">
                              <TextField
                                fullWidth
                                variant="standard"
                                label="Present Status of the case"
                                id="statusOfCase"
                                name="statusOfCase"
                                value={statusOfCase}
                                onChange={handleInputEvent}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="row g-2">
                            <div className="col-xl-12 postPerticularData">
                              <table className="table table-bordered table-sm">
                                <thead className="table-dark">
                                  <tr className="fw-bold">
                                    <th scope="col" className="text-start">
                                      DE Running (Yes/No)*
                                    </th>
                                    <th scope="col" className="text-start">
                                      DE Punishment (Yes/No)*
                                    </th>
                                    <th scope="col" className="text-start">
                                      Date of DE
                                    </th>
                                    <th scope="col" className="text-start">
                                      Brief Facts of the DE
                                    </th>
                                    <th scope="col" className="text-start">
                                      Present Status of the case
                                    </th>
                                    <th scope="col" className="text-start">
                                      DE Punishment Details
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {multiplePendingDESData.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        {/* Running DE */}
                                        <td className="text-start">
                                          <NativeSelect
                                            required
                                            fullWidth
                                            variant="standard"
                                            inputProps={{
                                              name: "runningDE",
                                              id: "runningDE",
                                            }}
                                            onChange={(event) => {
                                              handleMultiplePendingDEsData({
                                                type: "runningDE",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                            value={
                                              multiplePendingDESData[index]
                                                .runningDE
                                            }
                                          >
                                            <option value=""></option>
                                            {["Yes", "No"].map(
                                              (data, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={data}
                                                  >
                                                    {data}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </NativeSelect>
                                        </td>

                                        {/* Punishment DE */}
                                        <td className="text-start">
                                          <NativeSelect
                                            required
                                            fullWidth
                                            variant="standard"
                                            inputProps={{
                                              name: "punishmentDE",
                                              id: "punishmentDE",
                                            }}
                                            onChange={(event) => {
                                              handleMultiplePendingDEsData({
                                                type: "punishmentDE",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                            value={
                                              multiplePendingDESData[index]
                                                .punishmentDE
                                            }
                                          >
                                            <option value=""></option>
                                            {["Yes", "No"].map(
                                              (data, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={data}
                                                  >
                                                    {data}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </NativeSelect>
                                        </td>
                                        {/* Date of IDE */}

                                        <td className="text-start w-25">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DesktopDatePicker
                                              value={
                                                multiplePendingDESData[index]
                                                  .DateOfIDE
                                              }
                                              disableFuture
                                              inputFormat="dd/MM/yyyy"
                                              renderInput={(props) => (
                                                <TextField
                                                  variant="standard"
                                                  fullWidth
                                                  {...props}
                                                  name="DateOfIDE"
                                                  id="DateOfIDE"
                                                  size="small"
                                                  required={
                                                    multiplePendingDESData[
                                                      index
                                                    ].runningDE === "Yes" ||
                                                    multiplePendingDESData[
                                                      index
                                                    ].punishmentDE === "Yes"
                                                      ? true
                                                      : false
                                                  }
                                                  className={
                                                    multiplePendingDESData[
                                                      index
                                                    ].runningDE === "Yes" ||
                                                    multiplePendingDESData[
                                                      index
                                                    ].punishmentDE === "Yes"
                                                      ? ""
                                                      : "pe-none"
                                                  }
                                                />
                                              )}
                                              onChange={(newValue) => {
                                                handleMultiplePendingDEsData({
                                                  type: "DateOfIDE",
                                                  index,
                                                  value: newValue,
                                                });
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </td>

                                        {/* Brief Facts of the DE */}
                                        <td className="text-start">
                                          <TextField
                                            required={
                                              multiplePendingDESData[index]
                                                .runningDE === "Yes" ||
                                              multiplePendingDESData[index]
                                                .punishmentDE === "Yes"
                                                ? true
                                                : false
                                            }
                                            className={
                                              multiplePendingDESData[index]
                                                .runningDE === "Yes" ||
                                              multiplePendingDESData[index]
                                                .punishmentDE === "Yes"
                                                ? ""
                                                : "pe-none"
                                            }
                                            fullWidth
                                            variant="standard"
                                            name="briefIDE"
                                            id="briefIDE"
                                            value={
                                              multiplePendingDESData[index]
                                                .briefIDE
                                            }
                                            onChange={(event) =>
                                              handleMultiplePendingDEsData({
                                                type: "briefIDE",
                                                index,
                                                value: event.target.value,
                                              })
                                            }
                                          />
                                        </td>

                                        {/* Present Status of the case */}
                                        <td className="text-start">
                                          <TextField
                                            required={
                                              multiplePendingDESData[index]
                                                .runningDE === "Yes" ||
                                              multiplePendingDESData[index]
                                                .punishmentDE === "Yes"
                                                ? true
                                                : false
                                            }
                                            className={
                                              multiplePendingDESData[index]
                                                .runningDE === "Yes" ||
                                              multiplePendingDESData[index]
                                                .punishmentDE === "Yes"
                                                ? ""
                                                : "pe-none"
                                            }
                                            fullWidth
                                            variant="standard"
                                            id="statusOfCase"
                                            name="statusOfCase"
                                            value={
                                              multiplePendingDESData[index]
                                                .statusOfCase
                                            }
                                            onChange={(event) => {
                                              handleMultiplePendingDEsData({
                                                type: "statusOfCase",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                          />
                                        </td>

                                        {/* DE Punishment Details */}
                                        <td className="text-start">
                                          <TextField
                                            required={
                                              multiplePendingDESData[index]
                                                .punishmentDE === "Yes"
                                                ? true
                                                : false
                                            }
                                            className={
                                              multiplePendingDESData[index]
                                                .runningDE === "Yes" ||
                                              multiplePendingDESData[index]
                                                .punishmentDE === "Yes"
                                                ? ""
                                                : "pe-none"
                                            }
                                            fullWidth
                                            variant="standard"
                                            id="punishmentData"
                                            name="punishmentData"
                                            value={
                                              multiplePendingDESData[index]
                                                .punishmentData
                                            }
                                            onChange={(event) => {
                                              handleMultiplePendingDEsData({
                                                type: "punishmentData",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={6}>
                                      {/* Add Row */}
                                      <Button
                                        className={
                                          isProfarmaLink
                                            ? "d-none"
                                            : "success-button"
                                        }
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          multiplePendingDESData.push({
                                            DateOfIDE: null,
                                            briefIDE: "",
                                            statusOfCase: "",
                                            punishmentData: "",
                                            runningDE: "",
                                            punishmentDE: "",
                                          });

                                          setUsedStates((prevValue) => {
                                            return {
                                              ...prevValue,
                                              profarmaDetails: {
                                                ...prevValue.profarmaDetails,
                                                multiplePendingDESData,
                                              },
                                            };
                                          });
                                        }}
                                      >
                                        Add Row
                                      </Button>

                                      {/* Clear Row */}
                                      {multiplePendingDESData.length > 0 ? (
                                        <Button
                                          className={
                                            isProfarmaLink
                                              ? "d-none"
                                              : "reset-button ms-2"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            if (
                                              multiplePendingDESData.length > 0
                                            ) {
                                              multiplePendingDESData.pop();

                                              setUsedStates((prevValue) => {
                                                return {
                                                  ...prevValue,
                                                  profarmaDetails: {
                                                    ...prevValue.profarmaDetails,
                                                    multiplePendingDESData,
                                                  },
                                                };
                                              });
                                            }
                                          }}
                                        >
                                          clear
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Enquiry from other Agencies (Lokayukta/ Court ) */}
                  <div
                    className={typeOfData !== "P" ? "col-xl-12" : "col-xl-6"}
                  >
                    <div
                      className={
                        isProfarmaLink ? "card h-100 pe-none" : "card h-100"
                      }
                    >
                      <div className="card-header fw-bold">
                        Enquiry from other Agencies (Lokayukta/ Court )
                      </div>
                      <div className="card-body">
                        {typeOfData === "P" ? (
                          <div className="row g-2">
                            {/* Enquiry from other Agencies (Lokayukta/ Court ) */}
                            <div className="col-xl-12">
                              <TextField
                                fullWidth
                                variant="standard"
                                label="Enquiry from other Agencies (Lokayukta/ Court )"
                                id="enquiryFromOtheragency"
                                name="enquiryFromOtheragency"
                                onChange={handleInputEvent}
                                value={enquiryFromOtheragency}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="row g-2">
                            <div className="col-xl-12 postPerticularData">
                              <table className="table table-bordered table-sm">
                                <thead className="table-dark">
                                  <tr className="fw-bold">
                                    <th scope="col" className="text-start">
                                      Date of Enquiry
                                    </th>
                                    <th scope="col" className="text-start">
                                      Brief Facts of Enquiry
                                    </th>
                                    <th scope="col" className="text-start">
                                      Present Status of the case
                                    </th>
                                    <th scope="col" className="text-start">
                                      Enquiry Punishment Details
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {multipleEnquiryData.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        {/* Date of Enquiry */}
                                        <td className="text-start w-25">
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DesktopDatePicker
                                              value={
                                                multipleEnquiryData[index]
                                                  .DateOfEnquiry
                                              }
                                              disableFuture
                                              inputFormat="dd/MM/yyyy"
                                              renderInput={(props) => (
                                                <TextField
                                                  variant="standard"
                                                  fullWidth
                                                  {...props}
                                                  name="DateOfEnquiry"
                                                  id="DateOfEnquiry"
                                                  size="small"
                                                />
                                              )}
                                              onChange={(newValue) => {
                                                handleMultipleEnquiryData({
                                                  type: "DateOfEnquiry",
                                                  index,
                                                  value: newValue,
                                                });
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </td>

                                        {/* Brief Facts of the Enquiry */}
                                        <td className="text-start">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            name="briefEnquiry"
                                            id="briefEnquiry"
                                            value={
                                              multipleEnquiryData[index]
                                                .briefEnquiry
                                            }
                                            onChange={(event) =>
                                              handleMultipleEnquiryData({
                                                type: "briefEnquiry",
                                                index,
                                                value: event.target.value,
                                              })
                                            }
                                          />
                                        </td>

                                        {/* Present Status of the case */}
                                        <td className="text-start">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            id="statusOfCase"
                                            name="statusOfCase"
                                            value={
                                              multipleEnquiryData[index]
                                                .statusOfCase
                                            }
                                            onChange={(event) => {
                                              handleMultipleEnquiryData({
                                                type: "statusOfCase",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                          />
                                        </td>

                                        {/* DE Punishment Details */}
                                        <td className="text-start">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            id="punishmentData"
                                            name="punishmentData"
                                            value={
                                              multipleEnquiryData[index]
                                                .punishmentData
                                            }
                                            onChange={(event) => {
                                              handleMultipleEnquiryData({
                                                type: "punishmentData",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan={5}>
                                      {/* Add Row */}
                                      <Button
                                        className={
                                          isProfarmaLink
                                            ? "d-none"
                                            : "success-button"
                                        }
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          multipleEnquiryData.push({
                                            DateOfEnquiry: null,
                                            briefEnquiry: "",
                                            statusOfCase: "",
                                            punishmentData: "",
                                          });

                                          setUsedStates((prevValue) => {
                                            return {
                                              ...prevValue,
                                              profarmaDetails: {
                                                ...prevValue.profarmaDetails,
                                                multipleEnquiryData,
                                              },
                                            };
                                          });
                                        }}
                                      >
                                        Add Row
                                      </Button>

                                      {/* Clear Row */}
                                      {multipleEnquiryData.length > 0 ? (
                                        <Button
                                          className={
                                            isProfarmaLink
                                              ? "d-none"
                                              : "reset-button ms-2"
                                          }
                                          variant="contained"
                                          size="small"
                                          onClick={() => {
                                            if (
                                              multipleEnquiryData.length > 0
                                            ) {
                                              multipleEnquiryData.pop();

                                              setUsedStates((prevValue) => {
                                                return {
                                                  ...prevValue,
                                                  profarmaDetails: {
                                                    ...prevValue.profarmaDetails,
                                                    multipleEnquiryData,
                                                  },
                                                };
                                              });
                                            }
                                          }}
                                        >
                                          clear
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Punishments/Awards */}
                  <div
                    className={isProfarmaLink ? "col-xl-6 pe-none" : "col-xl-6"}
                  >
                    <div className="card h-100">
                      <div className="card-header fw-bold">Awards</div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* Punishments/Awards */}
                          <div
                            className={
                              typeOfData !== "P" ? "col-xl-12" : "col-xl-6"
                            }
                          >
                            <TextField
                              fullWidth
                              variant="standard"
                              label={
                                typeOfData !== "P"
                                  ? "Awards"
                                  : "Punishments/Awards"
                              }
                              id="punishmentAwards"
                              name="punishmentAwards"
                              onChange={handleInputEvent}
                              value={punishmentAwards}
                            />
                          </div>

                          {/* Punishment/Awards */}
                          {typeOfData === "P" ? (
                            <div className="col-xl d-flex flex-column">
                              <RadioGroup
                                row
                                className="ms-3"
                                value={typeOfpunishmentAwards}
                              >
                                <FormControlLabel
                                  value="awards"
                                  control={<Radio size="small" />}
                                  label="Awards"
                                  name="typeOfpunishmentAwards"
                                  id="typeOfpunishmentAwards"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />

                                <FormControlLabel
                                  value="punishment"
                                  control={<Radio size="small" />}
                                  label="Punishments"
                                  name="typeOfpunishmentAwards"
                                  id="typeOfpunishmentAwards"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                              </RadioGroup>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {typeOfData === "P" ? (
                    <div
                      className={
                        isProfarmaLink ? "col-xl-6 pe-none" : "col-xl-6"
                      }
                    >
                      <div className="card h-100">
                        <div className="card-header fw-bold">
                          ACR Gradings (Last 5 years)*
                        </div>
                        <div className="card-body">
                          <div className="row g-2">
                            <div className="col-xl-12 postPerticularData">
                              <table className="table table-bordered table-sm">
                                <thead className="table-dark">
                                  <tr className="fw-bold">
                                    <th scope="col" className="text-start">
                                      Pass out - year*
                                    </th>
                                    <th scope="col" className="text-start">
                                      Remarks*
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ACRGradings.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        {/* pass out  Year */}
                                        <td className="text-start w-50">
                                          <TextField
                                            required
                                            variant="standard"
                                            fullWidth
                                            onChange={(event) => {
                                              handleACRGradings({
                                                type: "passoutYear",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                            value={
                                              ACRGradings[index].passoutYear
                                            }
                                          />
                                        </td>

                                        {/* Remarks */}
                                        <td className="text-start w-50">
                                          <NativeSelect
                                            required
                                            fullWidth
                                            variant="standard"
                                            inputProps={{
                                              name: "remarks",
                                              id: "remarks",
                                            }}
                                            onChange={(event) => {
                                              handleACRGradings({
                                                type: "remarks",
                                                index,
                                                value: event.target.value,
                                              });
                                            }}
                                            value={ACRGradings[index].remarks}
                                          >
                                            <option value=""></option>
                                            {[
                                              "Outstanding",
                                              "Very good",
                                              "Good",
                                              "Average",
                                              "Below average",
                                            ].map((data, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={data}
                                                >
                                                  {data}
                                                </option>
                                              );
                                            })}
                                          </NativeSelect>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {typeOfData === "P" ? (
                    <>
                      <div
                        className={
                          isProfarmaLink ? "col-xl-12 pe-none" : "col-xl-12"
                        }
                      >
                        <div className="card h-100">
                          <div className="card-header fw-bold">
                            Department Exam Details
                          </div>
                          <div className="card-body">
                            <div className="row g-1">
                              <div className="col-xl-12 d-flex flex-row align-items-center ">
                                <InputLabel className="fw-bold">
                                  {" "}
                                  Departmental Exam Passed ?{" "}
                                </InputLabel>
                                <RadioGroup
                                  row
                                  className="ms-3"
                                  value={isPassed}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label="Yes"
                                    name="isPassed"
                                    id="isPassed"
                                    onChange={(event) => {
                                      setUsedStates((prevValue) => {
                                        return {
                                          ...prevValue,
                                          profarmaDetails: {
                                            ...prevValue.profarmaDetails,
                                            departmentExamDetails: {
                                              ...prevValue.profarmaDetails
                                                .departmentExamDetails,
                                              isPassed: true,
                                            },
                                          },
                                        };
                                      });
                                    }}
                                  />

                                  <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label="No"
                                    name="isPassed"
                                    id="isPassed"
                                    onChange={(event) => {
                                      setUsedStates((prevValue) => {
                                        return {
                                          ...prevValue,
                                          profarmaDetails: {
                                            ...prevValue.profarmaDetails,
                                            departmentExamDetails: {
                                              ...prevValue.profarmaDetails
                                                .departmentExamDetails,
                                              isPassed: false,
                                            },
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </RadioGroup>
                              </div>

                              {/* enter exam Details */}
                              {isPassed ? (
                                <>
                                  <div className="row g-4 mt-2">
                                    {/* Kannada Language Exam */}
                                    <div className="col-xl-12">
                                      <div className="card">
                                        <div className="card-header fw-bold">
                                          {" "}
                                          Kannada Language Exam
                                        </div>
                                        <div className="card-body">
                                          <div className="row">
                                            {/* options */}
                                            <div className="col-xl-12">
                                              <RadioGroup
                                                row
                                                className="ms-3"
                                                value={examsDetails[0].type}
                                              >
                                                <FormControlLabel
                                                  value="Pass"
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  label="Pass"
                                                  name="type"
                                                  id="type"
                                                  onChange={(event) => {
                                                    examsDetails[0].type =
                                                      event.target.value;
                                                    setUsedStates(
                                                      (prevValue) => {
                                                        return {
                                                          ...prevValue,
                                                          profarmaDetails: {
                                                            ...prevValue.profarmaDetails,
                                                            departmentExamDetails:
                                                              {
                                                                ...prevValue
                                                                  .profarmaDetails
                                                                  .departmentExamDetails,
                                                                examsDetails,
                                                              },
                                                          },
                                                        };
                                                      }
                                                    );
                                                  }}
                                                />
                                                <FormControlLabel
                                                  value="Exemption"
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  label="Exemption"
                                                  name="type"
                                                  id="type"
                                                  onChange={(event) => {
                                                    examsDetails[0].type =
                                                      event.target.value;
                                                    setUsedStates(
                                                      (prevValue) => {
                                                        return {
                                                          ...prevValue,
                                                          profarmaDetails: {
                                                            ...prevValue.profarmaDetails,
                                                            departmentExamDetails:
                                                              {
                                                                ...prevValue
                                                                  .profarmaDetails
                                                                  .departmentExamDetails,
                                                                examsDetails,
                                                              },
                                                          },
                                                        };
                                                      }
                                                    );
                                                  }}
                                                />
                                              </RadioGroup>
                                            </div>

                                            {/* kannada langauge exam details */}
                                            {examsDetails[0].type === "Pass" ? (
                                              <>
                                                {/* Exam Details */}
                                                <div className="col-xl mt-2">
                                                  <div className="card">
                                                    <div className="card-header fw-bold ">
                                                      Exam Details
                                                    </div>
                                                    <div className="card-body">
                                                      <div className="row g-2">
                                                        {/* Register No */}
                                                        <div className="col-xl">
                                                          <TextField
                                                            label="Reg No."
                                                            fullWidth
                                                            required
                                                            variant="standard"
                                                            onChange={(event) =>
                                                              handleExamDetails(
                                                                {
                                                                  type: "regNo",
                                                                  index: 0,
                                                                  value:
                                                                    event.target
                                                                      .value,
                                                                }
                                                              )
                                                            }
                                                            value={
                                                              examsDetails[0]
                                                                .regNo
                                                            }
                                                          />
                                                        </div>

                                                        {/* Session */}
                                                        <div className="col-xl">
                                                          <TextField
                                                            required
                                                            label="Session"
                                                            fullWidth
                                                            variant="standard"
                                                            onChange={(event) =>
                                                              handleExamDetails(
                                                                {
                                                                  type: "session",
                                                                  index: 0,
                                                                  value:
                                                                    event.target
                                                                      .value,
                                                                }
                                                              )
                                                            }
                                                            value={
                                                              examsDetails[0]
                                                                .session
                                                            }
                                                          />
                                                        </div>

                                                        {/* Date */}
                                                        <div className="col-xl">
                                                          <LocalizationProvider
                                                            dateAdapter={
                                                              AdapterDateFns
                                                            }
                                                          >
                                                            <DesktopDatePicker
                                                              label="Date"
                                                              value={
                                                                examsDetails[0]
                                                                  .date
                                                              }
                                                              disableFuture
                                                              inputFormat="dd/MM/yyyy"
                                                              renderInput={(
                                                                props
                                                              ) => (
                                                                <TextField
                                                                  variant="standard"
                                                                  fullWidth
                                                                  required
                                                                  {...props}
                                                                  size="small"
                                                                />
                                                              )}
                                                              onChange={(
                                                                newValue
                                                              ) => {
                                                                handleExamDetails(
                                                                  {
                                                                    type: "date",
                                                                    index: 0,
                                                                    value:
                                                                      newValue,
                                                                  }
                                                                );
                                                              }}
                                                            />
                                                          </LocalizationProvider>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {/* Certificate Details */}
                                                <div className="col-xl mt-2">
                                                  <div className="card">
                                                    <div className="card-header fw-bold">
                                                      Certificate Details
                                                    </div>
                                                    <div className="card-body">
                                                      <div className="row g-2">
                                                        {/* Certificate No */}
                                                        <div className="col-xl">
                                                          <TextField
                                                            label="Certificate No."
                                                            fullWidth
                                                            required
                                                            variant="standard"
                                                            onChange={(event) =>
                                                              handleExamDetails(
                                                                {
                                                                  type: "certificationNo",
                                                                  index: 0,
                                                                  value:
                                                                    event.target
                                                                      .value,
                                                                }
                                                              )
                                                            }
                                                            value={
                                                              examsDetails[0]
                                                                .certificationNo
                                                            }
                                                          />
                                                        </div>

                                                        {/* Certification Date */}
                                                        <div className="col-xl">
                                                          <LocalizationProvider
                                                            dateAdapter={
                                                              AdapterDateFns
                                                            }
                                                          >
                                                            <DesktopDatePicker
                                                              label="Certification Date"
                                                              value={
                                                                examsDetails[0]
                                                                  .certificationDate
                                                              }
                                                              disableFuture
                                                              inputFormat="dd/MM/yyyy"
                                                              renderInput={(
                                                                props
                                                              ) => (
                                                                <TextField
                                                                  variant="standard"
                                                                  fullWidth
                                                                  required
                                                                  {...props}
                                                                  size="small"
                                                                />
                                                              )}
                                                              onChange={(
                                                                newValue
                                                              ) => {
                                                                handleExamDetails(
                                                                  {
                                                                    type: "certificationDate",
                                                                    index: 0,
                                                                    value:
                                                                      newValue,
                                                                  }
                                                                );
                                                              }}
                                                            />
                                                          </LocalizationProvider>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}

                                            {/* exemption Details */}
                                            {examsDetails[0].type ===
                                            "Exemption" ? (
                                              <div className="col-xl mt-2">
                                                <div className="row g-1">
                                                  {/* SSLC Details */}
                                                  <div className="col-xl">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        SSLC Details
                                                      </div>
                                                      <div className="card-body">
                                                        <div className="row g-2">
                                                          {/* Register No */}
                                                          <div className="col-xl-6">
                                                            <TextField
                                                              label="Reg No."
                                                              fullWidth
                                                              variant="standard"
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleExamDetails(
                                                                  {
                                                                    type: "sslcRegNo",
                                                                    index: 0,
                                                                    value:
                                                                      event
                                                                        .target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                              value={
                                                                examsDetails[0]
                                                                  .sslcRegNo
                                                              }
                                                            />
                                                          </div>

                                                          {/* Date */}
                                                          <div className="col-xl-6">
                                                            <LocalizationProvider
                                                              dateAdapter={
                                                                AdapterDateFns
                                                              }
                                                            >
                                                              <DesktopDatePicker
                                                                label="Date"
                                                                value={
                                                                  examsDetails[0]
                                                                    .sslcDate
                                                                }
                                                                disableFuture
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(
                                                                  props
                                                                ) => (
                                                                  <TextField
                                                                    variant="standard"
                                                                    fullWidth
                                                                    {...props}
                                                                    size="small"
                                                                  />
                                                                )}
                                                                onChange={(
                                                                  newValue
                                                                ) => {
                                                                  handleExamDetails(
                                                                    {
                                                                      type: "sslcDate",
                                                                      index: 0,
                                                                      value:
                                                                        newValue,
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            </LocalizationProvider>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* Agency Details */}
                                                  <div className="col-xl">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        Other Agencies
                                                      </div>
                                                      <div className="card-body">
                                                        <div className="row g-2">
                                                          {/* Agency Name */}
                                                          <div className="col-xl-4">
                                                            <TextField
                                                              label="Agency Name"
                                                              fullWidth
                                                              variant="standard"
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleExamDetails(
                                                                  {
                                                                    type: "agencyName",
                                                                    index: 0,
                                                                    value:
                                                                      event
                                                                        .target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                              value={
                                                                examsDetails[0]
                                                                  .agencyName
                                                              }
                                                            />
                                                          </div>

                                                          {/* Register No */}
                                                          <div className="col-xl-4">
                                                            <TextField
                                                              label="Reg No."
                                                              fullWidth
                                                              variant="standard"
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleExamDetails(
                                                                  {
                                                                    type: "agencyRegNo",
                                                                    index: 0,
                                                                    value:
                                                                      event
                                                                        .target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                              value={
                                                                examsDetails[0]
                                                                  .agencyRegNo
                                                              }
                                                            />
                                                          </div>

                                                          {/* Date */}
                                                          <div className="col-xl-4">
                                                            <LocalizationProvider
                                                              dateAdapter={
                                                                AdapterDateFns
                                                              }
                                                            >
                                                              <DesktopDatePicker
                                                                label="Date"
                                                                value={
                                                                  examsDetails[0]
                                                                    .agencyDate
                                                                }
                                                                disableFuture
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(
                                                                  props
                                                                ) => (
                                                                  <TextField
                                                                    variant="standard"
                                                                    fullWidth
                                                                    {...props}
                                                                    size="small"
                                                                  />
                                                                )}
                                                                onChange={(
                                                                  newValue
                                                                ) => {
                                                                  handleExamDetails(
                                                                    {
                                                                      type: "agencyDate",
                                                                      index: 0,
                                                                      value:
                                                                        newValue,
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            </LocalizationProvider>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* Certificate Details */}
                                                  <div className="col-xl">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        Exemption Certificate
                                                        Details
                                                      </div>
                                                      <div className="card-body">
                                                        <div className="row g-2">
                                                          {/* Certificate No */}
                                                          <div className="col-xl">
                                                            <TextField
                                                              label="Certificate No."
                                                              fullWidth
                                                              required
                                                              variant="standard"
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                handleExamDetails(
                                                                  {
                                                                    type: "certificationNo",
                                                                    index: 0,
                                                                    value:
                                                                      event
                                                                        .target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                              value={
                                                                examsDetails[0]
                                                                  .certificationNo
                                                              }
                                                            />
                                                          </div>

                                                          {/* Certification Date */}
                                                          <div className="col-xl">
                                                            <LocalizationProvider
                                                              dateAdapter={
                                                                AdapterDateFns
                                                              }
                                                            >
                                                              <DesktopDatePicker
                                                                label="Certification Date"
                                                                value={
                                                                  examsDetails[0]
                                                                    .certificationDate
                                                                }
                                                                disableFuture
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(
                                                                  props
                                                                ) => (
                                                                  <TextField
                                                                    variant="standard"
                                                                    fullWidth
                                                                    required
                                                                    {...props}
                                                                    size="small"
                                                                  />
                                                                )}
                                                                onChange={(
                                                                  newValue
                                                                ) => {
                                                                  handleExamDetails(
                                                                    {
                                                                      type: "certificationDate",
                                                                      index: 0,
                                                                      value:
                                                                        newValue,
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            </LocalizationProvider>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* CLT Exam */}
                                    <div className="col-xl-4">
                                      <div className="card">
                                        <div className="card-header fw-bold">
                                          {" "}
                                          CLT Exam
                                        </div>
                                        <div className="card-body">
                                          {/* Register No */}
                                          <TextField
                                            label="Reg No."
                                            fullWidth
                                            variant="standard"
                                            onChange={(event) =>
                                              handleExamDetails({
                                                type: "regNo",
                                                index: 1,
                                                value: event.target.value,
                                              })
                                            }
                                            value={examsDetails[1].regNo}
                                          />

                                          {/* Session */}
                                          <TextField
                                            label="Session"
                                            fullWidth
                                            variant="standard"
                                            onChange={(event) =>
                                              handleExamDetails({
                                                type: "session",
                                                index: 1,
                                                value: event.target.value,
                                              })
                                            }
                                            value={examsDetails[1].session}
                                          />

                                          {/* Date */}
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DesktopDatePicker
                                              label="Date"
                                              value={examsDetails[1].date}
                                              disableFuture
                                              inputFormat="dd/MM/yyyy"
                                              renderInput={(props) => (
                                                <TextField
                                                  variant="standard"
                                                  fullWidth
                                                  {...props}
                                                  size="small"
                                                />
                                              )}
                                              onChange={(newValue) => {
                                                handleExamDetails({
                                                  type: "date",
                                                  index: 1,
                                                  value: newValue,
                                                });
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </div>

                                    {/* General Law - 1 & 2  */}
                                    <div className="col-xl-4">
                                      <div className="card">
                                        <div className="card-header fw-bold">
                                          General Law - 1 & 2
                                        </div>
                                        <div className="card-body">
                                          {/* Register No */}
                                          <TextField
                                            label="Reg No."
                                            fullWidth
                                            variant="standard"
                                            onChange={(event) =>
                                              handleExamDetails({
                                                type: "regNo",
                                                index: 2,
                                                value: event.target.value,
                                              })
                                            }
                                            value={examsDetails[2].regNo}
                                          />

                                          {/* Session */}
                                          <TextField
                                            label="Session"
                                            fullWidth
                                            variant="standard"
                                            onChange={(event) =>
                                              handleExamDetails({
                                                type: "session",
                                                index: 2,
                                                value: event.target.value,
                                              })
                                            }
                                            value={examsDetails[2].session}
                                          />
                                          {/* Date */}
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DesktopDatePicker
                                              label="Date"
                                              value={examsDetails[2].date}
                                              disableFuture
                                              inputFormat="dd/MM/yyyy"
                                              renderInput={(props) => (
                                                <TextField
                                                  variant="standard"
                                                  fullWidth
                                                  {...props}
                                                  size="small"
                                                />
                                              )}
                                              onChange={(newValue) => {
                                                handleExamDetails({
                                                  type: "date",
                                                  index: 2,
                                                  value: newValue,
                                                });
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Accounts Higher */}
                                    <div className="col-xl-4">
                                      <div className="card">
                                        <div className="card-header fw-bold">
                                          Accounts Higher
                                        </div>
                                        <div className="card-body">
                                          {/* Register No */}
                                          <TextField
                                            label="Reg No."
                                            fullWidth
                                            variant="standard"
                                            onChange={(event) =>
                                              handleExamDetails({
                                                type: "regNo",
                                                index: 3,
                                                value: event.target.value,
                                              })
                                            }
                                            value={examsDetails[3].regNo}
                                          />

                                          {/* Session */}
                                          <TextField
                                            label="Session"
                                            fullWidth
                                            variant="standard"
                                            onChange={(event) =>
                                              handleExamDetails({
                                                type: "session",
                                                index: 3,
                                                value: event.target.value,
                                              })
                                            }
                                            value={examsDetails[3].session}
                                          />

                                          {/* Date */}
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DesktopDatePicker
                                              label="Date"
                                              value={examsDetails[3].date}
                                              disableFuture
                                              inputFormat="dd/MM/yyyy"
                                              renderInput={(props) => (
                                                <TextField
                                                  variant="standard"
                                                  fullWidth
                                                  {...props}
                                                  size="small"
                                                />
                                              )}
                                              onChange={(newValue) => {
                                                handleExamDetails({
                                                  type: "date",
                                                  index: 3,
                                                  value: newValue,
                                                });
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* FIT/UN FIT */}
                  {typeOfData === "P" ? (
                    <div
                      className={
                        isProfarmaLink ? "col-xl-6 pe-none" : "col-xl-6"
                      }
                    >
                      <div className="card h-100">
                        <div className="card-header fw-bold">FIT/ UNFIT</div>
                        <div className="card-body">
                          <div className="row g-2">
                            {/* Enquiry from other Agencies (Lokayukta/ Court ) */}
                            <div className="col-xl-12">
                              <RadioGroup
                                row
                                className="ms-3"
                                value={FIT_UN_FIT}
                              >
                                <FormControlLabel
                                  value="FIT"
                                  required
                                  control={<Radio size="small" />}
                                  label="FIT"
                                  name="FIT_UN_FIT"
                                  id="FIT_UN_FIT"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                                <FormControlLabel
                                  required
                                  value="UNFIT"
                                  control={<Radio size="small" />}
                                  label="UNFIT"
                                  name="FIT_UN_FIT"
                                  id="FIT_UN_FIT"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                  }}
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Recomendation */}
                  <div
                    className={
                      isProfarmaLink
                        ? "col-xl-6 col-md-5 pe-none"
                        : "col-xl-6 col-md-5"
                    }
                  >
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        Recommendation from DCP
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* From Name */}
                          <div className="col-sm-6 ">
                            <TextField
                              variant="standard"
                              fullWidth
                              label="From Name"
                              id="RecomFromName"
                              name="RecomFromName"
                              value={RecomFromName}
                              onChange={handleInputEvent}
                            />
                          </div>

                          {/* From Designation */}
                          <div className="col-sm-6">
                            <TextField
                              variant="standard"
                              fullWidth
                              label="From Designation"
                              name="RecomFromDesignation"
                              id="RecomFromDesignation"
                              onChange={handleInputEvent}
                              value={RecomFromDesignation}
                            />
                          </div>

                          {/* Brief about Recommendation made */}
                          <div className="col-sm-12">
                            <TextField
                              multiline
                              rows={4}
                              variant="standard"
                              fullWidth
                              label="Brief about Recommendation made"
                              id="RecomMade"
                              name="RecomMade"
                              onChange={handleInputEvent}
                              value={RecomMade}
                            />
                          </div>

                          {/* Date recieved */}
                          <div className="col-sm-6">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label="Date Recieved"
                                value={dateRecieved}
                                disableFuture
                                inputFormat="dd/MM/yyyy"
                                renderInput={(props) => (
                                  <TextField
                                    variant="standard"
                                    fullWidth
                                    {...props}
                                    name="dateRecieved"
                                    id="dateRecieved"
                                    size="small"
                                  />
                                )}
                                onChange={(newValue) => {
                                  handleDate({
                                    type: "dateRecieved",
                                    value: newValue,
                                  });
                                }}
                              />
                            </LocalizationProvider>
                          </div>

                          {/* Place recomended*/}
                          <div className="col-sm-6">
                            <TextField
                              variant="standard"
                              fullWidth
                              label="Places recomended"
                              id="RecomPlaceRecomended"
                              name="RecomPlaceRecomended"
                              value={RecomPlaceRecomended}
                              onChange={handleInputEvent}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Remarks from DCP’s  */}
                <div
                  className={
                    isProfarmaLink ? "row g-1 mt-1 pe-none" : "row g-1 mt-1"
                  }
                >
                  <div className={ACK_status ? "col-sm-6" : "col-sm-12"}>
                    <div className="card h-100">
                      <div className="card-header fw-bold">
                        Remarks from DCP
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* Entry By */}
                          <div className="col-sm-4">
                            <TextField
                              disabled
                              fullWidth
                              variant="standard"
                              label="Entry By*"
                              name="entryBy"
                              id="entryBy"
                              value={entryBy}
                            />
                          </div>

                          {/* Checked By */}
                          <div className="col-sm-4">
                            <TextField
                              disabled
                              fullWidth
                              variant="standard"
                              label="Checked By*"
                              name="checkedBy"
                              id="checkedBy"
                              value={checkedBy}
                            />
                          </div>

                          {/* Remark */}
                          <div className="col-sm-4">
                            <TextField
                              fullWidth
                              variant="standard"
                              label="Remark"
                              name="remark"
                              id="remark"
                              value={remark}
                              onChange={handleInputEvent}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Generate Link Details  */}
                  {ACK_status ? (
                    <div className="col-sm-6">
                      <div className="card h-100">
                        <div className="card-header fw-bold">
                          Generate Link Details
                        </div>
                        <div className="card-body">
                          <div className="row g-2">
                            {/* email address */}
                            <div className="col-sm-6">
                              <TextField
                                type="email"
                                required
                                fullWidth
                                variant="standard"
                                label="Email Address"
                                name="email"
                                id="email"
                                value={email}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("emailError");
                                }}
                                error={emailError ? true : false}
                                helperText={emailError}
                              />
                            </div>

                            {/* contact No */}
                            <div className="col-sm-6">
                              <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Contact No."
                                name="mobileNumber"
                                id="mobileNumber"
                                value={mobileNumber}
                                onChange={(event) => {
                                  handleInputEvent(event);
                                }}
                                type="tel"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* user Info */}
                {isProfarmaLink ? (
                  <>
                    <div className="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="showExistingVacancy"
                            id="showExistingVacancy"
                            required
                            onClick={() =>
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  webcamModal: {
                                    ...prevValue.webcamModal,
                                    open: true,
                                  },
                                };
                              })
                            }
                          />
                        }
                        label={
                          <span className="ms-1">
                            Above mentioned information are correct as per my
                            Knowledge.
                          </span>
                        }
                      />
                    </div>
                  </>
                ) : null}

                <div className="buttons mt-1 ">
                  {/* submit button */}
                  <Button
                    type="submit"
                    variant="outlined"
                    className="primary-button  shadow mt-3"
                    disabled={loading}
                  >
                    save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>

      {/* webcam modal */}
      <Dialog open={open}>
        <DialogContent>
          <div className="webcam-div">
            <p className="text-center">
              To initiate the proforma process, kindly click the button below to
              capture a live user picture using the webcam.
            </p>

            <div className="d-flex justify-content-center">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                  width: "45%",
                  height: "45%",
                }}
              />
            </div>

            {/* capture button */}
            <Button
              type="button"
              fullWidth
              className="success-button  shadow mt-3"
              onClick={() => {
                setUsedStates((prevValue) => {
                  return {
                    ...prevValue,
                    profarmaDetails: {
                      ...prevValue.profarmaDetails,
                      captureImage: webcamRef.current.getScreenshot(),
                    },
                    webcamModal: {
                      ...prevValue.webcamModal,
                      open: false,
                    },
                  };
                });
              }}
            >
              Take Picture
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateProforma;
