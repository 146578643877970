/*******************************  Login Page ********************************************* */
import {
  React,
  TextField,
  swal,
  axios,
  useHistory,
  useState,
  InputAdornment,
  Button,
  BeatLoader,
  Dialog,
  DialogContent,
  useCallback,
  lodash,
  FadeLoader,
  Autocomplete,
  useEffect,
  Webcam,
} from "./commonFiles/Import";
import {
  BiSolidLockAlt,
  FaUserCircle,
  FaClipboardUser,
} from "./commonFiles/Icons";

const Login = () => {
  const webcamRef = React.useRef(null);

  // get data from session
  const adminRole = window.sessionStorage.getItem("adminRole");
  const adminUserId = window.sessionStorage.getItem("adminUserId");
  const adminUserName = window.sessionStorage.getItem("adminUserName");

  // used for redirecting routes
  const history = useHistory();

  const [usedStates, setUsedStates] = useState({
    // login details
    loginDetails: {
      userName: "",
      password: "",
      empCode: "",
      transferYear: "",
      designation: "",
      loginProfile: "",
      division: "",
      operationType: "",
      actionType: "",
    },

    // loading button
    loading: false,

    //input Errors
    errors: {
      userNameError: "",
      passwordError: "",
    },

    additionaModal: {
      open: false,
      showWaitingMessage: false,
    },

    divisions: [],
  });

  /************************* destructering objects start *******************************/
  const { loginDetails, errors, loading, additionaModal, divisions } =
    usedStates;

  const {
    userName,
    password,
    empCode,
    transferYear,
    designation,
    division,
    operationType,
  } = loginDetails;

  const { userNameError, passwordError } = errors;

  const { open, showWaitingMessage } = additionaModal;

  /************************* destructering objects end *******************************/

  // change input fields based on [onchange ]
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loginDetails: {
          ...prevValue.loginDetails,
          [name]: value,
        },
      };
    });
  };

  // reset Errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  // handle login
  const handleLogin = async ({ loginDetails }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get(
        adminRole === "superAdmin" ? "/superAdminLogin" : "/login",
        { params: Object.assign(loginDetails, { adminUserId }) }
      );

      // on success
      if (result.data) {
        // get data
        const { userName, assignedTabs, city } = result.data;

        //store userName into session storage
        window.sessionStorage.setItem("userName", userName);

        // store assignTabs into session storage
        window.sessionStorage.setItem("assignedTabs", assignedTabs);

        // store city into session storage
        window.sessionStorage.setItem("city", city);

        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // Redirect to /proforma
        history.push(`/${adminUserName}/welcome`);
      }
    } catch (err) {
      // show errors to user
      const { loginError, errors } = err.response.data;

      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // input Errors
      if (errors) {
        const { userNameError, passwordError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              userNameError,
              passwordError,
            },
          };
        });
      } else if (loginError) {
        swal({ title: "Sorry", text: `${loginError}`, icon: "error" });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  //on submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    // if operation  type is User-Specific
    if (operationType === "User-Specific") {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,

          additionaModal: {
            ...prevValue.additionaModal,
            open: true,
          },
        };
      });
    }

    // user is not guest
    else {
      handleLogin({ loginDetails });
    }
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", {
        params: { userId: adminUserId },
      });

      // on success
      if (result.data) {
        let { settingStatusDetails, divisionDetails } = result.data;

        // get data of setting status
        const { transferYear, designation, operation_Type, actionType } =
          settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loginDetails: {
              ...prevValue.loginDetails,
              transferYear,
              designation,
              operationType: operation_Type,
              actionType,
            },

            divisions: lodash.uniqBy(divisionDetails, "name"),

            loading: false,
          };
        });
      }
    } catch (err) {
      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [adminUserId]);

  useEffect(() => {
    if (adminRole !== "superAdmin") {
      getSettingData();
    }
  }, [getSettingData, adminRole]);

  return (
    <>
      {/* login section */}
      <section className="login-section ">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-4 col-md-6">
              <div className="card shadow border-0">
                <div className="card-header fs-4 text-center">
                  <img
                    src={require("../dist/img/user.png")}
                    alt="user.png"
                    className="img-fluid p-2 bg-light rounded mb-2"
                    width="100"
                    height="100"
                  />
                  <h1 className="fw-bold fs-4 mt-1">WELCOME</h1>
                  <small className="fs-6 text-muted mt-1">
                    Sign in by entering the information below.
                  </small>
                </div>
                <div className="card-body">
                  <form
                    method="POST"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    {/* User Name */}
                    <TextField
                      label="User Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaUserCircle size={20} color="black" />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      name="userName"
                      id="userName"
                      className="w-100"
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetErrors("userNameError");
                      }}
                      value={userName}
                      error={userNameError ? true : false}
                      helperText={userNameError}
                    />

                    {/* password  / KGID No */}
                    {operationType === "User-Specific" ? (
                      <TextField
                        label="KGID No"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FaClipboardUser size={20} color="black" />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        name="empCode"
                        id="empCode"
                        className="w-100 mt-1"
                        onChange={(event) => {
                          handleInputEvent(event);
                        }}
                        value={empCode}
                        required
                      />
                    ) : (
                      <div>
                        <TextField
                          label="Password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BiSolidLockAlt size={20} color="black" />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          name="password"
                          id="password"
                          type="password"
                          className="mt-1 w-100"
                          onChange={(event) => {
                            handleInputEvent(event);

                            handleResetErrors("passwordError");
                          }}
                          value={password}
                          error={passwordError ? true : false}
                          helperText={passwordError}
                        />

                        {operationType === "Divisional" &&
                        userName === "GuestDivision" ? (
                          <Autocomplete
                            disableClearable
                            forcePopupIcon={true}
                            onChange={(event, value) => {
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  loginDetails: {
                                    ...prevValue.loginDetails,
                                    division: value.name,
                                  },
                                };
                              });
                            }}
                            inputValue={division}
                            options={divisions}
                            getOptionLabel={(options) => options.name}
                            renderInput={(params) => (
                              <TextField
                                label="Select Division"
                                variant="standard"
                                {...params}
                                name="division"
                                id="division"
                                className="w-100 mt-1"
                                onChange={(event) => {
                                  handleInputEvent(event);
                                }}
                                value={division}
                                required
                              />
                            )}
                          />
                        ) : null}
                      </div>
                    )}

                    {/* login button */}
                    <Button
                      type="submit"
                      fullWidth
                      className="primary-button  shadow mt-3"
                      disabled={loading}
                    >
                      get started
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>

      {/* webcam modal */}
      <Dialog className={showWaitingMessage ? "loading-modal" : ""} open={open}>
        <DialogContent>
          {showWaitingMessage ? (
            <div className="message-section">
              <div className="d-flex justify-content-center">
                <FadeLoader size={35} color="var(--white)" />
              </div>

              {/* message */}
              <div className="text-center mt-2 text-white">
                <h2 className="fw-bolder">Just a moment</h2>

                <p className="fs-5">
                  {" "}
                  The administrator hasn't initiated the transfer yet.
                </p>
              </div>
            </div>
          ) : (
            <div className="webcam-div">
              <p className="text-center">
                To proceed with the transfer, please click below button to
                capture a live user picture using the webcam.
              </p>

              <div className="d-flex justify-content-center">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{
                    width: "45%",
                    height: "45%",
                  }}
                />
              </div>

              {/* capture button */}
              <Button
                type="button"
                fullWidth
                className="success-button  shadow mt-3"
                onClick={() => {
                  setUsedStates((prevValue) => {
                    return {
                      ...prevValue,
                      loginDetails: {
                        ...prevValue.loginDetails,
                        loginProfile: webcamRef.current.getScreenshot(),
                      },
                      additionaModal: {
                        ...prevValue.additionaModal,
                        open: false,
                      },
                    };
                  });

                  // allow user to login
                  handleLogin({
                    loginDetails: {
                      userName,
                      password,
                      empCode,
                      transferYear,
                      designation,
                      loginProfile: webcamRef.current.getScreenshot(),
                    },
                  });
                }}
              >
                Take Picture
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Login;
