/*******************************  View  TransferList ********************************************* */

import {
  React,
  useEffect,
  useState,
  swal,
  axios,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  RadioGroup,
  FormControlLabel,
  Radio,
  lodash,
  InputLabel,
  NativeSelect,
  Button,
  ExportPdf,
  ExportCsv,
  useCallback,
} from "./commonFiles/Import";

const TransferList = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,

    interval: 0, // used for autoRefresh

    viewTransferListData: [],

    transferYears: [],
    designations: [],

    searchDetails: {
      typeOfData: "", // by default it's transfer
      transferYear: "",
      designation: "",
    },

    // maintain transfer start status
    transferStatus: false,
  });

  /********************* Destructring Objects start ************************************/
  const {
    loading,
    viewTransferListData,
    transferYears,
    designations,
    searchDetails,
    interval,
    transferStatus,
  } = usedStates;

  const { typeOfData, transferYear, designation } = searchDetails;

  /********************* Destructring Objects end ************************************/

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get transferYears
  const getTransferYears = useCallback(
    async ({ transferYear, typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getUserDetailsSR", {
          params: { transferYear, typeOfData, userId },
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            transferYears: lodash.uniqBy(result.data, "transferYear"),
            loading: false,
          };
        });
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  //get transfer data [ call this function every 10 sec]
  const getTransferListData = async ({ searchDetails }) => {
    try {
      const result = await axios.get("/getViewTransferListData", {
        params: Object.assign(searchDetails, { userId }),
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((element) => {
          //add count field
          Object.assign(element, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            viewTransferListData: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });
      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  //get transfer List
  const handleSubmit = (event) => {
    try {
      event.preventDefault();

      // call this function only one time without any interval
      getTransferListData({ searchDetails });

      // every 10 sec update transfer list
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          interval: transferStatus
            ? setInterval(() => {
                getTransferListData({ searchDetails });
              }, 10000)
            : 0,
        };
      });
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // view Transfer/Promotion columns
  const viewTransferColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
    },

    {
      title: typeOfData === "P" ? "Gradation No." : "Seniority List No.",
      field: typeOfData === "P" ? "gradationNo" : "SnList",
    },

    {
      title: `${typeOfData !== "P" ? "Transfer Year" : "Promotion Year"}`,
      field: "Trn_No",
    },

    {
      title: "KGID No.",
      field: "EmpCode",
    },

    {
      title: "Full Name",
      field: "Full_Name",
    },

    {
      title: "Designation",
      field: "Cadre",
    },

    {
      title: `${typeOfData !== "P" ? `Transfer From` : `Promote From`}`,
      field: "Trs_From",
    },

    {
      title: `${typeOfData !== "P" ? `Transfer To` : `Promote To`}`,
      field: "Trs_To",
    },
  ];

  // handle  input event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          [name]: value,
        },
      };
    });
  };

  // handle Reset states
  const handleResetStates = ({ typeOfData }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loading: false,
        viewTransferListData: [],
        searchDetails: {
          ...prevValue.searchDetails,
          typeOfData,
        },
        interval: 0,
      };
    });

    // clear auto Referesh interval
    clearInterval(interval);

    if (typeOfData === "All") {
      getSettingData();
    }
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        let { transferStatus, settingStatusDetails } = result.data;

        // get data of setting status
        const { transferYear, designation, actionType } = settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
            searchDetails: {
              ...prevValue.searchDetails,
              transferYear,
              designation,
              typeOfData: actionType,
            },

            transferStatus,
          };
        });

        getTransferYears({ typeOfData: actionType, transferYear: "" });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, getTransferYears]);

  useEffect(() => {
    getDesignationDetails();
    getSettingData();

    // clear auto Referesh interval
    return () => {
      clearInterval(interval);
    };
  }, [interval, getSettingData, getDesignationDetails]);

  return (
    <>
      <section className="form-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="card border-0 mb-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-2">
                    <h1 className="fs-4 fw-bold">
                      View {typeOfData !== "P" ? `Transfer` : `Promotion`} List
                    </h1>
                  </div>

                  {/* Type of data */}
                  <div className="col-xl d-flex align-items-center">
                    <RadioGroup row value={typeOfData}>
                      {/* General Transfer */}
                      <FormControlLabel
                        value="G"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">General Transfer</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />

                      {/* Request Transfer */}
                      <FormControlLabel
                        className="ms-1"
                        value="T"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">Request Transfer</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />

                      {/* Promotion */}
                      <FormControlLabel
                        className="ms-1"
                        value="P"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">Promotion</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* generate Form */}
                  <div className="col-xl-12">
                    <form
                      method="POST"
                      autoComplete="Off"
                      onSubmit={handleSubmit}
                    >
                      <div className="row mb-3 g-3 mt-1 ">
                        {/* select transferYear */}
                        <div className="col-xl-3">
                          <InputLabel>
                            {typeOfData !== "P"
                              ? "Select transfer year*"
                              : "Select promotion year*"}
                          </InputLabel>
                          <NativeSelect
                            required
                            fullWidth
                            variant="standard"
                            inputProps={{
                              name: "transferYear",
                              id: "transferYear",
                            }}
                            onChange={(event) => {
                              handleInputEvent(event);
                            }}
                            value={transferYear}
                          >
                            {transferYears.map((data, index) => {
                              return (
                                <option key={index} value={data.transferYear}>
                                  {data.transferYear}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>

                        {/* select Designation */}
                        <div className="col-xl-3">
                          <InputLabel>Select Designation*</InputLabel>
                          <NativeSelect
                            required
                            fullWidth
                            variant="standard"
                            inputProps={{
                              name: "designation",
                              id: "designation",
                            }}
                            onChange={(event) => {
                              handleInputEvent(event);
                            }}
                            value={designation}
                          >
                            {designations.map((data, index) => {
                              return (
                                <option key={index} value={data.Cadre}>
                                  {data.Cadre}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>
                      </div>

                      <div className="buttons">
                        {/* submit button  */}
                        <Button
                          variant="outlined"
                          className="primary-button  shadow"
                          type="submit"
                        >
                          generate
                        </Button>

                        {/* reset button */}
                        <Button
                          type="button"
                          variant="outlined"
                          className="reset-button shadow ms-2"
                          onClick={() =>
                            handleResetStates({ typeOfData: "All" })
                          }
                        >
                          reset
                        </Button>
                      </div>
                    </form>
                  </div>

                  {/* Result */}
                  <div className="col-xl-12 mt-4">
                    <MaterialTable
                      title={
                        <h5 className="fw-bold">
                          {" "}
                          {typeOfData !== "P" ? "Transfer" : "Promotion"} List
                        </h5>
                      }
                      columns={viewTransferColumns}
                      data={viewTransferListData}
                      options={{
                        exportAllData: true,
                        paging: false,
                        headerStyle: {
                          backgroundColor: "var(--glass-border)",
                          color: "black",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          position: "sticky",
                          zIndex: 100,
                          backdropFilter: "blur(100px)",
                        },
                        maxBodyHeight: 600,
                        rowStyle: {
                          whiteSpace: "nowrap",
                        },

                        exportMenu: [
                          {
                            label: "Export PDF",
                            exportFunc: (cols, datas) =>
                              ExportPdf(cols, datas, "TransferList"),
                          },
                          {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                              ExportCsv(cols, datas, "TransferList"),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransferList;
