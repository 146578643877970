/*************************************** Welcome  page *****************************************/

import { React } from "./commonFiles/Import";

const Welcome = () => {
  // get data from session storage
  const userName = window.sessionStorage.getItem("userName");
  const city = window.sessionStorage.getItem("city");

  return (
    <>
      {/* welcome banner */}
      <section className="welcome-banner ">
        <div className="container-fluid">
          <div className="d-flex justify-content-center align-items-center text-center  min-vh-100">
            <div className=" welcome-message">
              <h1
                className="fw-bold text-uppercase"
                style={{ color: "var(--primary-color)", fontSize: "4rem" }}
              >
                online transfer portal
              </h1>
              <h2 className="fs-3">Welcome, {userName} </h2>
              <img
                className="img-fluid mt-4 mb-4"
                src={require("../dist/img/city_police.png")}
                alt="city_police.png"
                width="150"
                height="150"
              />
              <h1 className="fw-bold text-uppercase fs-5">
                office of the commissioner of police
              </h1>
              <h2 className="fs-3">{city} City</h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
