/*******************************  Page Not found *****************************************************/

import { React, Button, useHistory } from "./commonFiles/Import";

const PageNotFound = () => {
  // used for redirecting page
  const history = useHistory();

  return (
    <>
      {/* error section */}
      <section className="error-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card border-0">
                <div className="card-body text-center">
                  <img
                    src={require("../dist/img/connection.png")}
                    alt="connection.png"
                    className="img-fluid shadow-none p-2 bg-light rounded mb-2"
                    width="100"
                    height="100"
                  />
                  <h1 className="card-title fs-3 fw-bold d-flex flex-column ">
                    <span>Page Not found</span>
                  </h1>

                  <p className="mt-3 fs-6 text-muted">
                    The Page you're looking for doesn't exist. it might have
                    been moved or deleted.
                  </p>

                  <Button
                    type="button"
                    className="primary-button shadow"
                    onClick={() => history.push("/")}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
