/********************* PoliceStation page ****************************************/

import {
  React,
  useState,
  swal,
  useEffect,
  axios,
  lodash,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  DialogTitle,
  TextField,
  Button,
  Autocomplete,
  useCallback,
  ExportCsv,
  ExportPdf,
} from "../commonFiles/Import";

import { CgClose, MdLibraryAdd, MdEdit, MdDelete } from "../commonFiles/Icons";

const PoliceStation = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,
    poliseStations: [],
    underDetails: [],

    stationModal: {
      open: false,
      modalTitle: "",
      stationDetails: {
        pstCode: "",
        name: "",
        address: "",
        city: "",
        taluka: "",
        pinCode: "",
        under: "",
        officeType: "",
        state: "",
        maxPstCode: 0,
      },
    },

    errors: {
      nameError: "",
      underError: "",
      officeTypeError: "",
      stateError: "",
    },
  });

  /******************* Destructering objects start *****************************/

  const { loading, poliseStations, stationModal, errors, underDetails } =
    usedStates;

  const { open, stationDetails, modalTitle } = stationModal;

  const {
    name,
    address,
    city,
    taluka,
    pinCode,
    under,
    officeType,
    state,
    maxPstCode,
  } = stationDetails;

  const { nameError, underError, officeTypeError, stateError } = errors;

  /******************* Destructering objects end *****************************/

  // get policeStation Details by editDelete option
  const getStationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getWorkingPlaceDetails", {
        params: { editDeleteOpt: true, userId },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((element) => {
          //add count field
          Object.assign(element, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            poliseStations: lodash.uniqBy(result.data, "Name"),
            underDetails: lodash.uniqBy(result.data, "Under"),

            stationModal: {
              ...prevValue.stationModal,
              stationDetails: {
                ...prevValue.stationModal.stationDetails,
                maxPstCode: result.data[0]
                  ? result.data[0].maxPstCode
                  : maxPstCode,
              },
            },
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, maxPstCode]);

  // columns
  const stationColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
    },

    {
      title: "Name",
      field: "Name",
    },

    {
      title: "Address",
      field: "Addr",
      render: (rowData) => {
        return rowData.Addr ? rowData.Addr : "-";
      },
    },

    {
      title: "City",
      field: "City",
      render: (rowData) => {
        return rowData.City ? rowData.City : "-";
      },
    },

    {
      title: "Taluka",
      field: "Taluka",
      render: (rowData) => {
        return rowData.Taluka ? rowData.Taluka : "-";
      },
    },

    {
      title: "PinCode",
      field: "PinCode",
      render: (rowData) => {
        return rowData.PinCode ? rowData.PinCode : "-";
      },
    },

    {
      title: "Under the",
      field: "Under",
    },

    {
      title: "Office Type",
      field: "OfficeType",
    },

    {
      title: "State",
      field: "State",
    },
  ];

  // handle ResetModal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        stationModal: {
          ...prevValue.stationModal,
          open: false,
          modalTitle: "",
          stationDetails: {
            ...prevValue.stationModal.stationDetails,
            pstCode: "",
            name: "",
            address: "",
            city: "",
            taluka: "",
            pinCode: "",
            under: "",
            officeType: "",
            state: "",
          },
        },

        errors: {
          ...prevValue.errors,
          nameError: "",
          underError: "",
          officeTypeError: "",
          stateError: "",
        },
      };
    });
  };

  // open modal
  const handleOpenModal = (stationDetails, modalTitle) => {
    const {
      name,
      address,
      city,
      taluka,
      pinCode,
      under,
      officeType,
      state,
      pstCode,
    } = stationDetails;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        stationModal: {
          ...prevValue.stationModal,
          open: true,
          modalTitle,
          stationDetails: {
            ...prevValue.stationModal.stationDetails,
            pstCode,
            name,
            address,
            city,
            taluka,
            pinCode,
            under,
            officeType,
            state,
          },
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      //add option type
      Object.assign(stationDetails, { optionType: modalTitle, userId });

      const result =
        modalTitle === "Add"
          ? await axios.post("/storeStationDetails", stationDetails)
          : await axios.patch("/updateStationDetails", stationDetails);

      // on success
      if (result.data.success) {
        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record saved successfully`,
          timer: "3000",
          buttons: false,
        }).then(() => {
          // close modal
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              stationModal: {
                ...prevValue.stationModal,
                open: false,
              },
            };
          });

          // get station details
          getStationDetails();
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const { nameError, underError, officeTypeError, stateError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              nameError,
              underError,
              officeTypeError,
              stateError,
            },
          };
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    }
  };

  // handle delete
  const handleDelete = ({ pstCode, officeType, name }) => {
    swal({
      title: `Are You Sure ?`,
      icon: "warning",
      text: "You won't be able to revert this!",
      buttons: [true, "confirm"],
    }).then(async (value) => {
      try {
        if (value) {
          // enable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: true,
            };
          });

          const result = await axios.delete("/deleteStation", {
            params: { pstCode, officeType, name, userId },
          });

          // on success
          if (result.data.success) {
            // show success messaage
            swal({
              icon: "success",
              title: "success",
              text: `Your record deleted successfully`,
              timer: "3000",
              buttons: false,
            }).then(() => {
              // get station details
              getStationDetails();
            });
          }
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    });
  };

  // handle input Event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        stationModal: {
          ...prevValue.stationModal,
          stationDetails: {
            ...prevValue.stationModal.stationDetails,
            [name]: value,
          },
        },
      };
    });
  };

  // handle reset error
  const handleResetError = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  // handle autoInput Event
  const handleAutoInputEvent = ({ type, value }) => {
    if (value === "Add New") {
      const newValue = prompt("Enter New Value");

      if (newValue) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            stationModal: {
              ...prevValue.stationModal,
              stationDetails: {
                ...prevValue.stationModal.stationDetails,
                [type]: newValue,
              },
            },

            errors: {
              ...prevValue.errors,
              underError: "",
            },
          };
        });
      }
    } else {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          stationModal: {
            ...prevValue.stationModal,
            stationDetails: {
              ...prevValue.stationModal.stationDetails,
              [type]: value,
            },
          },

          errors: {
            ...prevValue.errors,
            underError: "",
          },
        };
      });
    }
  };

  useEffect(() => {
    getStationDetails();
  }, [getStationDetails]);

  return (
    <>
      <section className="designation-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    {/* Profarma */}
                    <div className="col-xl-12 mt-4">
                      <MaterialTable
                        id="stationList"
                        title={
                          <h5 className="fw-bold mt-1">Police Stations</h5>
                        }
                        columns={stationColumns}
                        data={poliseStations}
                        options={{
                          paging: false,
                          headerStyle: {
                            backgroundColor: "var(--glass-border)",
                            color: "black",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            position: "sticky",
                            zIndex: 100,
                            backdropFilter: "blur(100px)",
                          },
                          maxBodyHeight: 450,
                          rowStyle: {
                            whiteSpace: "nowrap",
                          },

                          exportMenu: [
                            {
                              label: "Export PDF",
                              exportFunc: (cols, datas) =>
                                ExportPdf(cols, datas, "PoliceStation-List"),
                            },
                            {
                              label: "Export CSV",
                              exportFunc: (cols, datas) =>
                                ExportCsv(cols, datas, "PoliceStation-List"),
                            },
                          ],
                        }}
                        localization={{
                          header: {
                            actions: "Edit / Delete",
                          },
                        }}
                        actions={[
                          //Add Designation
                          {
                            icon: () => (
                              <MdLibraryAdd
                                fontSize={25}
                                color="var(--success-color)"
                              />
                            ),
                            tooltip: "Add",
                            isFreeAction: true,
                            onClick: () => {
                              handleOpenModal(
                                {
                                  name: "",
                                  address: "",
                                  city: "",
                                  taluka: "",
                                  pinCode: "",
                                  under: "",
                                  officeType: "",
                                  state: "",
                                  pstCode: maxPstCode + 1,
                                },
                                "Add"
                              );
                            },
                          },

                          //Edit Designation
                          (rowData) => ({
                            disabled: rowData.editDelete ? true : false,
                            icon: () => (
                              <MdEdit
                                fontSize={25}
                                color={
                                  rowData.editDelete
                                    ? "var(--reset-color)"
                                    : "var(--primary-color)"
                                }
                              />
                            ),
                            tooltip: rowData.editDelete ? "" : "Edit",
                            onClick: (event, rowData) => {
                              const {
                                pstCode,
                                Name,
                                Addr,
                                City,
                                Taluka,
                                PinCode,
                                Under,
                                OfficeType,
                                State,
                              } = rowData;

                              handleOpenModal(
                                {
                                  name: Name,
                                  address: Addr,
                                  city: City,
                                  taluka: Taluka,
                                  pinCode: PinCode,
                                  under: Under,
                                  officeType: OfficeType,
                                  state: State,
                                  pstCode,
                                },
                                "Edit"
                              );
                            },
                          }),

                          //Delete Designation
                          (rowData) => ({
                            disabled: rowData.editDelete ? true : false,
                            icon: () => (
                              <MdDelete
                                fontSize={25}
                                color={
                                  rowData.editDelete
                                    ? "var(--reset-color)"
                                    : "var(--red-color)"
                                }
                              />
                            ),
                            tooltip: rowData.editDelete ? "" : "Delete",

                            onClick: (event, rowData) =>
                              handleDelete({
                                pstCode: rowData.pstCode,
                                officeType: rowData.OfficeType,
                                name: rowData.Name,
                              }),
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Station Dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className=" d-flex align-items-center justify-content-between"
        >
          <span className="fs-5 fw-bold ">{modalTitle} Polcie Station</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-1">
              {/* Name */}
              <div className="col-xl-12">
                <TextField
                  disabled={modalTitle === "Edit" ? true : false}
                  variant="standard"
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  required
                  value={name}
                  error={nameError ? true : false}
                  helperText={nameError}
                  onChange={(event) => {
                    if (modalTitle !== "Edit") {
                      handleInputEvent(event);
                      handleResetError("nameError");
                    }
                  }}
                />
              </div>

              {/* Address */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="address"
                  name="address"
                  label="Address"
                  fullWidth
                  value={address}
                  onChange={(event) => {
                    handleInputEvent(event);
                  }}
                />
              </div>

              {/* City */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  value={city}
                  onChange={(event) => {
                    handleInputEvent(event);
                  }}
                />
              </div>

              {/* Taluka */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="taluka"
                  name="taluka"
                  label="Taluka"
                  fullWidth
                  value={taluka}
                  onChange={(event) => {
                    handleInputEvent(event);
                  }}
                />
              </div>

              {/* PinCode */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="pinCode"
                  name="pinCode"
                  label="PinCode"
                  fullWidth
                  value={pinCode}
                  onChange={(event) => {
                    handleInputEvent(event);
                  }}
                />
              </div>

              {/* Office Type */}
              <div className="col-xl-12">
                <Autocomplete
                  disableClearable
                  popupIcon={false}
                  options={["Police Station", "ACP", "Division"]}
                  onChange={(event, value) => {
                    handleAutoInputEvent({ type: "officeType", value });
                  }}
                  value={officeType}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Office Type"
                      variant="standard"
                      id="officeType"
                      name="officeType"
                      error={officeTypeError ? true : false}
                      helperText={officeTypeError}
                    />
                  )}
                />
              </div>

              {/* UNDER */}
              <div className="col-xl-12">
                <Autocomplete
                  disableClearable
                  popupIcon={false}
                  options={
                    officeType === "Division"
                      ? ["Add New", ...underDetails.map((value) => value.Under)]
                      : [...underDetails.map((value) => value.Under)]
                  }
                  onChange={(event, value) => {
                    handleAutoInputEvent({ type: "under", value });
                  }}
                  value={under}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Under the"
                      variant="standard"
                      id="under"
                      name="under"
                      error={underError ? true : false}
                      helperText={underError}
                    />
                  )}
                />
              </div>

              {/* State */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="state"
                  name="state"
                  label="State"
                  fullWidth
                  required
                  value={state}
                  error={stateError ? true : false}
                  helperText={stateError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetError("stateError");
                  }}
                />
              </div>

              {/* submit button */}
              <div className="col-xl-12 mt-3">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={loading}
                >
                  {modalTitle === "Add" ? modalTitle : "update"}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PoliceStation;
