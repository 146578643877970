/***************************************** seniroity vacancy list  **************************************************************/

import {
  React,
  swal,
  useEffect,
  useState,
  axios,
  lodash,
  Button,
  LoadingButton,
  ExportPdf,
  ExportCsv,
  MaterialTable,
  moment,
  Dialog,
  DialogContent,
  BeatLoader,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  NativeSelect,
  useCallback,
} from "./commonFiles/Import";

const SeniorityList = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    transferYears: [],
    designations: [],

    searchDetails: {
      transferYear: "",
      designation: "",
      typeOfData: "",
    },

    loading: false,

    seniorityListData: [],
  });

  /********************* destructering objects start ********************/
  const {
    searchDetails,
    designations,
    transferYears,
    loading,
    seniorityListData,
  } = usedStates;

  const { transferYear, designation, typeOfData } = searchDetails;

  /********************* destructering objects start ********************/

  // get transferYears
  const getTransferYears = useCallback(
    async ({ transferYear, typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getUserDetailsSR", {
          params: { transferYear, typeOfData, userId },
        });

        // on success
        if (result.data) {
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              transferYears: lodash.uniqBy(result.data, "transferYear"),
              loading: false,
            };
          });
        }
      } catch (err) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // handle input event
  const handleInputEvent = ({ type, value }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          [type]: value,
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSeniorityList", {
        params: Object.assign(searchDetails, { userId }),
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((element) => {
          //add count field
          Object.assign(element, { Sr_No: ++count });
        });

        // disable laoding
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
            seniorityListData: result.data,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // columns
  const seniorityListColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
    },

    {
      title: typeOfData === "P" ? "Gradation No" : "Senioriry List No",
      field: typeOfData === "P" ? "gradationNo" : "SnList",
    },

    {
      title: "KGID No.",
      field: "empcode",
    },

    {
      title: "Metal No",
      field: "metalno",
    },

    {
      title: "Full Name",
      field: "full_name",
    },

    {
      title: "Present Working Place",
      field: "pres_working_place",
    },

    {
      title: "Date into Service",
      field: "date_join",
      render: (rowData) => {
        return moment(rowData.date_join).format("DD/MM/YYYY");
      },
    },

    {
      title: "Date of Birth",
      field: "date_birth",
      render: (rowData) => {
        return moment(rowData.date_birth).format("DD/MM/YYYY");
      },
    },
  ];

  // reset states
  const handleResetStates = ({ typeOfData }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          typeOfData,
        },

        loading: false,

        seniorityListData: [],
      };
    });

    if (typeOfData === "All") {
      getSettingData();
    }
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        let { settingStatusDetails } = result.data;

        // get data of setting status
        const { transferYear, designation, actionType } = settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            searchDetails: {
              ...prevValue.searchDetails,
              transferYear,
              designation,
              typeOfData: actionType,
            },

            loading: false,
          };
        });

        getTransferYears({ typeOfData: actionType, transferYear: "" });
      }
    } catch (err) {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, getTransferYears]);

  useEffect(() => {
    getDesignationDetails();
    getSettingData();
  }, [getSettingData, getDesignationDetails]);

  return (
    <>
      <section className="seniority-list-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="card border-0 mb-4">
            <div className="card-header">
              <div className="row">
                <div className="col-xl-2">
                  <h1 className="fs-4 fw-bold">Seniority List</h1>
                </div>

                {/* Type of data */}
                <div className="col-xl d-flex align-items-center">
                  <RadioGroup row value={typeOfData}>
                    {/* General Transfer */}
                    <FormControlLabel
                      value="G"
                      control={<Radio size="small" />}
                      label={<span className="ms-2">General Transfer</span>}
                      name="typeOfData"
                      id="typeOfData"
                      onChange={(event) => {
                        handleInputEvent({
                          type: "typeOfData",
                          value: event.target.value,
                        });

                        getTransferYears({
                          typeOfData: event.target.value,
                          transferYear: "",
                        });

                        handleResetStates({ typeOfData: event.target.value });
                      }}
                    />

                    {/* Request Transfer */}
                    <FormControlLabel
                      className="ms-1"
                      value="T"
                      control={<Radio size="small" />}
                      label={<span className="ms-2"> Request Transfer</span>}
                      name="typeOfData"
                      id="typeOfData"
                      onChange={(event) => {
                        handleInputEvent({
                          type: "typeOfData",
                          value: event.target.value,
                        });

                        getTransferYears({
                          typeOfData: event.target.value,
                          transferYear: "",
                        });

                        handleResetStates({ typeOfData: event.target.value });
                      }}
                    />

                    {/* Promotion */}
                    <FormControlLabel
                      className="ms-1"
                      value="P"
                      control={<Radio size="small" />}
                      label={<span className="ms-2">Promotion</span>}
                      name="typeOfData"
                      id="typeOfData"
                      onChange={(event) => {
                        handleInputEvent({
                          type: "typeOfData",
                          value: event.target.value,
                        });

                        getTransferYears({
                          typeOfData: event.target.value,
                          transferYear: "",
                        });

                        handleResetStates({ typeOfData: event.target.value });
                      }}
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form method="POST" autoComplete="Off" onSubmit={handleSubmit}>
                <div className="row mb-3 g-3 mt-1 ">
                  {/* select transferYear */}
                  <div className="col-xl-3">
                    <InputLabel>
                      {typeOfData !== "P"
                        ? "Select transfer year*"
                        : "Select promotion year*"}
                    </InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      variant="standard"
                      inputProps={{
                        name: "transferYear",
                        id: "transferYear",
                      }}
                      onChange={(event) => {
                        handleInputEvent({
                          type: "transferYear",
                          value: event.target.value,
                        });
                      }}
                      value={transferYear}
                    >
                      {transferYears.map((data, index) => {
                        return (
                          <option key={index} value={data.transferYear}>
                            {data.transferYear}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>

                  {/* select Designation */}
                  <div className="col-xl-3">
                    <InputLabel>Select Designation*</InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      variant="standard"
                      inputProps={{
                        name: "designation",
                        id: "designation",
                      }}
                      onChange={(event) => {
                        handleInputEvent({
                          type: "designation",
                          value: event.target.value,
                        });
                      }}
                      value={designation}
                    >
                      {designations.map((data, index) => {
                        return (
                          <option key={index} value={data.Cadre}>
                            {data.Cadre}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>

                  <div className="buttons">
                    {/* submit button  */}
                    <LoadingButton
                      variant="outlined"
                      className="primary-button  shadow"
                      type="submit"
                    >
                      generate
                    </LoadingButton>

                    {/* reset button */}
                    <Button
                      type="button"
                      variant="outlined"
                      className="reset-button shadow ms-2"
                      onClick={() => handleResetStates({ typeOfData: "All" })}
                    >
                      reset
                    </Button>
                  </div>
                </div>
              </form>

              {/* Vacancy List */}
              <div className="row mt-5">
                <div className="col-xl-12">
                  <MaterialTable
                    title={<h5 className="fw-bold">Seniority List</h5>}
                    columns={seniorityListColumns}
                    data={seniorityListData}
                    options={{
                      exportAllData: true,
                      paging: false,
                      headerStyle: {
                        backgroundColor: "var(--glass-border)",
                        color: "var(--default-color)",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 600,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },

                      exportMenu: [
                        {
                          label: "Export PDF",
                          exportFunc: (cols, datas) =>
                            ExportPdf(cols, datas, "Seniority-list"),
                        },
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "Seniority-list"),
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SeniorityList;
