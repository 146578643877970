/***************************************** potential vacancy list  **************************************************************/

import {
  React,
  useState,
  useEffect,
  swal,
  axios,
  lodash,
  Button,
  ExportCsv,
  ExportPdf,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  NativeSelect,
  useCallback,
} from "./commonFiles/Import";

const PotentialVacanacyList = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    transferYears: [],
    designations: [],

    searchDetails: {
      transferYear: "",
      designation: "",
      typeOfData: "",
    },

    loading: false,

    vacancyListData: [],

    vacancyDatatoUpdate: [],

    totalVacancies: "",
    totalExistingVacancies: "",
  });

  /****************************  destrucerting objects start *********************/
  const {
    transferYears,
    designations,
    searchDetails,
    loading,
    vacancyListData,
    vacancyDatatoUpdate,
    totalVacancies,
    totalExistingVacancies,
  } = usedStates;

  const { transferYear, designation, typeOfData } = searchDetails;

  /****************************  destrucerting objects end *********************/

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get transferYears
  const getTransferYears = useCallback(
    async ({ transferYear, typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getUserDetailsSR", {
          params: { transferYear, typeOfData, userId },
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            transferYears: lodash.uniqBy(result.data, "transferYear"),
            loading: false,
          };
        });
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  // handle input event
  const handleInputEvent = ({ type, value }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          [type]: value,
        },
      };
    });
  };

  // calcaulate vacancies
  const calcaulateVacancies = ({ vacancyData }) => {
    // initaliaze variable
    let noOfvacancy = 0,
      existingVacancy = 0;

    vacancyData.forEach((data) => {
      noOfvacancy += data.Nvac;
      existingVacancy += data.exist_vac;
    });

    // update data
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        totalVacancies: noOfvacancy,
        totalExistingVacancies: existingVacancy,
      };
    });
  };

  // get potential vacancy list data
  const getPotentialVacancyListData = async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getVacancyList", {
        params: Object.assign(searchDetails, { userId }),
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        // calcaulate  vacancies
        calcaulateVacancies({ vacancyData: result.data });

        result.data.forEach((data) => {
          // update exist vacancy
          data["exist_vac"] = data["exist_vac"] ? data["exist_vac"] : 0;

          //add count field
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,

            // disable loading
            loading: false,

            vacancyListData: result.data,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        let { settingStatusDetails } = result.data;

        // get data of setting status
        const { transferYear, designation, actionType, designationTo } =
          settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            searchDetails: {
              ...prevValue.searchDetails,
              transferYear,
              designation: actionType === "P" ? designationTo : designation,
              typeOfData: actionType,
            },

            loading: false,
          };
        });

        getTransferYears({ typeOfData: actionType, transferYear: "" });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, getTransferYears]);

  // vacanacyList Columns
  const vacanacyListColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
      editable: "never",
    },

    {
      title: "Police Stations",
      field: "Name",
      editable: "never",
    },

    {
      title: "Division",
      field: "Division",
      editable: "never",
    },

    {
      title: "Potential Vacancies",
      field: "Nvac",
      editable: "never",
    },

    {
      title: "Existing Vacancies",
      field: "exist_vac",
      type: "numeric",
    },
  ];

  // reset all States
  const handleResetStates = ({ typeOfData }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          typeOfData,
        },
        loading: false,
        vacancyListData: [],
        vacancyDatatoUpdate: [],
        totalVacancies: "",
        totalExistingVacancies: "",
      };
    });

    if (typeOfData === "All") {
      getSettingData();
    }
  };

  // handle  Existing List
  const handleExistingList = async () => {
    try {
      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.patch("/updateVacancyList", {
        vacancyDatatoUpdate,
        userId,
      });

      //on success
      if (result.data.success) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record updated  successfully `,
          timer: "3000",
          buttons: false,
        }).then(() => {
          // gt vacancy list data
          getPotentialVacancyListData();
        });
      }
    } catch (err) {
      const { customError } = err.response.data;

      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      if (customError) {
        swal({
          title: `Error`,
          text: `${customError}`,
          icon: "error",
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  useEffect(() => {
    getDesignationDetails();
    getSettingData();
  }, [getSettingData, getDesignationDetails]);

  return (
    <>
      <section className="vacancy-list-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="card border-0 mb-4">
            <div className="card-header">
              <div className="row">
                <div className="col-xl-3">
                  <h1 className="fs-4 fw-bold">Potential Vacancy List</h1>
                </div>

                {/* Type of data */}
                <div className="col-xl d-flex align-items-center">
                  <RadioGroup row value={typeOfData}>
                    {/* general transfer */}
                    <FormControlLabel
                      value="G"
                      control={<Radio size="small" />}
                      label={<span className="ms-2">General Transfer</span>}
                      name="typeOfData"
                      id="typeOfData"
                      onChange={(event) => {
                        handleInputEvent({
                          type: "typeOfData",
                          value: event.target.value,
                        });

                        getTransferYears({
                          typeOfData: event.target.value,
                          transferYear: "",
                        });

                        handleResetStates({ typeOfData: event.target.value });
                      }}
                    />

                    {/* Request Transfer */}
                    <FormControlLabel
                      className="ms-1"
                      value="T"
                      control={<Radio size="small" />}
                      label={<span className="ms-2">Request Transfer</span>}
                      name="typeOfData"
                      id="typeOfData"
                      onChange={(event) => {
                        handleInputEvent({
                          type: "typeOfData",
                          value: event.target.value,
                        });

                        getTransferYears({
                          typeOfData: event.target.value,
                          transferYear: "",
                        });

                        handleResetStates({ typeOfData: event.target.value });
                      }}
                    />

                    {/* promotion */}
                    <FormControlLabel
                      className="ms-1"
                      value="P"
                      control={<Radio size="small" />}
                      label={<span className="ms-2">Promotion</span>}
                      name="typeOfData"
                      id="typeOfData"
                      onChange={(event) => {
                        handleInputEvent({
                          type: "typeOfData",
                          value: event.target.value,
                        });

                        getTransferYears({
                          typeOfData: event.target.value,
                          transferYear: "",
                        });

                        handleResetStates({ typeOfData: event.target.value });
                      }}
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form
                method="POST"
                autoComplete="Off"
                onSubmit={(event) => {
                  event.preventDefault();
                  getPotentialVacancyListData();
                }}
              >
                <div className="row mb-3 g-3 mt-1 ">
                  {/* select transferYear */}
                  <div className="col-xl-3">
                    <InputLabel>
                      {typeOfData !== "P"
                        ? "Select transfer year*"
                        : "Select promotion year*"}
                    </InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      variant="standard"
                      inputProps={{
                        name: "transferYear",
                        id: "transferYear",
                      }}
                      onChange={(event) => {
                        handleInputEvent({
                          type: "transferYear",
                          value: event.target.value,
                        });
                      }}
                      value={transferYear}
                    >
                      {transferYears.map((data, index) => {
                        return (
                          <option key={index} value={data.transferYear}>
                            {data.transferYear}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>

                  {/* select Designation */}
                  <div className="col-xl-3">
                    <InputLabel>Select Designation*</InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      variant="standard"
                      inputProps={{
                        name: "designation",
                        id: "designation",
                      }}
                      onChange={(event) => {
                        handleInputEvent({
                          type: "designation",
                          value: event.target.value,
                        });
                      }}
                      value={designation}
                    >
                      {designations.map((data, index) => {
                        return (
                          <option key={index} value={data.Cadre}>
                            {data.Cadre}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>
                </div>

                <div className="buttons">
                  {/* submit button  */}
                  <Button
                    variant="outlined"
                    className="primary-button  shadow"
                    type="submit"
                  >
                    generate
                  </Button>

                  {/* reset button */}
                  <Button
                    type="button"
                    variant="outlined"
                    className="reset-button shadow ms-2"
                    onClick={() => handleResetStates({ typeOfData: "All" })}
                  >
                    reset
                  </Button>
                </div>
              </form>

              {/* Vacancy List */}
              <div className="row mt-5">
                <div className="col-xl-12">
                  <MaterialTable
                    id="vacancyList"
                    title={<h5 className="fw-bold">Potential Vacancy List</h5>}
                    columns={vacanacyListColumns}
                    data={vacancyListData}
                    options={{
                      exportAllData: true,
                      paging: false,
                      headerStyle: {
                        backgroundColor: "var(--glass-border)",
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 350,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },

                      exportMenu: [
                        {
                          label: "Export PDF",
                          exportFunc: (cols, datas) =>
                            ExportPdf(cols, datas, "Potential-vacancyList"),
                        },
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "Potential-vacancyList"),
                        },
                      ],
                    }}
                    editable={{
                      onBulkUpdate: (newChanges) =>
                        new Promise((resolve, reject) => {
                          const updatedRows = Object.values(newChanges);

                          // update data
                          updatedRows.forEach((data) => {
                            const { pstCode, exist_vac, Nvac } = data.newData;

                            // push  data into array
                            vacancyDatatoUpdate.push({
                              transferYear,
                              postCode: pstCode,
                              existingVacancy: exist_vac,
                              designation,
                              noOfvacancy: Nvac,
                              typeOfData,
                            });
                          });

                          // to maintain loading added timeout of 4sec
                          setTimeout(() => {
                            handleExistingList();

                            resolve();
                          }, 4000);
                        }),
                    }}
                  />
                </div>
              </div>

              {/* save existing List */}
              <div className="row mt-4 d-flex justify-content-end">
                {/* Number of vacancy & Existing vacancies */}
                <div className="col-xl-4 ">
                  <table className="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td> Potential Vacancies : {totalVacancies}</td>
                        <td> Existing Vacancies : {totalExistingVacancies}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="fw-bold">
                          {" "}
                          Total : {totalVacancies + totalExistingVacancies}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PotentialVacanacyList;
