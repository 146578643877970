/********************* Designation page ****************************************/

import {
  React,
  useState,
  swal,
  useEffect,
  axios,
  lodash,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  DialogTitle,
  TextField,
  Button,
  useCallback,
} from "../commonFiles/Import";

import { CgClose, MdLibraryAdd, MdEdit, MdDelete } from "../commonFiles/Icons";

const Designation = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,
    designations: [],

    designationModal: {
      open: false,
      modalTitle: "",
      designationDetails: {
        id: "",
        designation: "",
      },
    },

    errors: {
      designationError: "",
    },
  });

  /******************* Destructering objects start *****************************/

  const { loading, designations, designationModal, errors } = usedStates;

  const { open, designationDetails, modalTitle } = designationModal;

  const { designation, id } = designationDetails;

  const { designationError } = errors;

  /******************* Destructering objects end *****************************/

  // get designation Details by editDelete option
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { editDeleteOpt: true, userId },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((element) => {
          //add count field
          Object.assign(element, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // columns
  const designationColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
      width: "10px",
    },

    {
      title: "Designation",
      field: "Cadre",
    },
  ];

  // handle ResetModal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        designationModal: {
          ...prevValue.designationModal,
          open: false,
          modalTitle: "",
          designationDetails: {
            ...prevValue.designationModal.designationDetails,
            id: "",
            designation: "",
          },
        },
      };
    });
  };

  // open modal
  const handleOpenModal = ({ title, designationId, designation }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        designationModal: {
          ...prevValue.designationModal,
          open: true,
          modalTitle: title,
          designationDetails: {
            ...prevValue.designationModal.designationDetails,
            id: designationId,
            designation,
          },
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // add userId key value in designationDetails
      Object.assign(designationDetails, { userId });

      const result = id
        ? await axios.patch("/updateDesignationDetails", designationDetails)
        : await axios.post("/addDesignationDetails", designationDetails);

      // on success
      if (result.data.success) {
        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record saved successfully`,
          timer: "3000",
          buttons: false,
        }).then(() => {
          // close modal
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              designationModal: {
                ...prevValue.designationModal,
                open: false,
              },
            };
          });

          // get designation details
          getDesignationDetails();
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const { designationError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              designationError,
            },
          };
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    }
  };

  // handle delete
  const handleDelete = ({ id }) => {
    swal({
      title: `Are You Sure ?`,
      icon: "warning",
      text: "You won't be able to revert this!",
      buttons: [true, "confirm"],
    }).then(async (value) => {
      try {
        if (value) {
          // enable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: true,
            };
          });

          const result = await axios.delete("/deleteDesignation", {
            params: { id, userId },
          });

          // on success
          if (result.data.success) {
            // show success messaage
            swal({
              icon: "success",
              title: "success",
              text: `Your record deleted successfully`,
              timer: "3000",
              buttons: false,
            }).then(() => {
              // get designation details
              getDesignationDetails();
            });
          }
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    });
  };

  useEffect(() => {
    getDesignationDetails();
  }, [getDesignationDetails]);

  return (
    <>
      <section className="designation-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    {/* Profarma */}
                    <div className="col-xl-12 mt-4">
                      <MaterialTable
                        id="designationList"
                        title={<h5 className="fw-bold mt-1">Designations</h5>}
                        columns={designationColumns}
                        data={designations}
                        options={{
                          paging: false,
                          headerStyle: {
                            backgroundColor: "var(--glass-border)",
                            color: "black",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            position: "sticky",
                            zIndex: 100,
                            backdropFilter: "blur(100px)",
                          },
                          maxBodyHeight: 450,
                          rowStyle: {
                            whiteSpace: "nowrap",
                          },
                        }}
                        localization={{
                          header: {
                            actions: "Edit / Delete",
                          },
                        }}
                        actions={[
                          //Add Designation
                          {
                            icon: () => (
                              <MdLibraryAdd
                                fontSize={25}
                                color="var(--success-color)"
                              />
                            ),
                            tooltip: "Add",
                            isFreeAction: true,
                            onClick: () =>
                              handleOpenModal({
                                title: "Add",
                                designationId: "",
                                designation: "",
                              }),
                          },

                          //Edit Designation
                          (rowData) => ({
                            disabled: rowData.editDelete ? true : false,
                            icon: () => (
                              <MdEdit
                                fontSize={25}
                                color={
                                  rowData.editDelete
                                    ? "var(--reset-color)"
                                    : "var(--primary-color)"
                                }
                              />
                            ),
                            tooltip: rowData.editDelete ? "" : "Edit",
                            onClick: (event, rowData) =>
                              handleOpenModal({
                                title: "Edit",
                                designationId: rowData.Id,
                                designation: rowData.Cadre,
                              }),
                          }),

                          //Delete Designation
                          (rowData) => ({
                            disabled: rowData.editDelete ? true : false,
                            icon: () => (
                              <MdDelete
                                fontSize={25}
                                color={
                                  rowData.editDelete
                                    ? "var(--reset-color)"
                                    : "var(--red-color)"
                                }
                              />
                            ),
                            tooltip: rowData.editDelete ? "" : "Delete",

                            onClick: (event, rowData) =>
                              handleDelete({ id: rowData.Id }),
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Assign Dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className=" d-flex align-items-center justify-content-between"
        >
          <span className="fs-5 fw-bold ">{modalTitle} Designation</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-3">
              {/* Designation */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="designation"
                  name="designation"
                  label="Designation"
                  fullWidth
                  type="text"
                  required
                  value={designation}
                  error={designationError ? true : false}
                  helperText={designationError}
                  onChange={(event) =>
                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        designationModal: {
                          ...prevValue.designationModal,
                          designationDetails: {
                            ...prevValue.designationModal.designationDetails,
                            designation: event.target.value,
                          },
                        },

                        errors: {
                          ...prevValue.errors,
                          designationError: "",
                        },
                      };
                    })
                  }
                />
              </div>

              {/* submit button */}
              <div className="col-xl-12">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={loading}
                >
                  {modalTitle === "Add" ? modalTitle : "update"}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Designation;
