/*************************************** Header page *****************************************/

import { CgMenu } from "../commonFiles/Icons";
import { React, useEffect, useState, SideBar, Drawer } from "./Import";

const Header = () => {
  //drawer
  const [open, setOpen] = useState(false);

  const userName = sessionStorage.getItem("userName");

  const assignModules = window.sessionStorage
    .getItem("assignedTabs")
    .split(",")
    .reduce((acc, moduleName) => {
      acc[moduleName] = true;
      return acc;
    }, {});

  // toggle drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  //open drawer when path is welcome
  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      {/* header section */}
      <header className={userName ? "header-section fixed-top" : "d-none"}>
        <div className="container-fluid">
          <div className="row">
            <div className="icon d-flex  justify-content-between">
              {/* hamburger icon */}
              <div className="hamburger-icon">
                <CgMenu
                  size={25}
                  onClick={() => setOpen(true)}
                  color="inherit"
                  cursor="pointer"
                />
              </div>

              {/* prosoft logo */}
              <div className="hamburger-icon">
                <img
                  src={require("../../dist/img/Prosoft_Logo.png")}
                  alt="Prosoft_Logo.png"
                  className="img-fluid"
                  width={65}
                  height={35}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* sidebar */}
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <SideBar closeDrawer={closeDrawer} assignModules={assignModules} />
      </Drawer>
    </>
  );
};

export default Header;
