/********************* Admin User page ****************************************/

import {
  React,
  useState,
  swal,
  useEffect,
  axios,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  DialogTitle,
  TextField,
  Button,
  moment,
  Autocomplete,
  InputAdornment,
} from "../commonFiles/Import";

import {
  CgClose,
  MdLibraryAdd,
  MdEdit,
  IoInformationCircleSharp,
} from "../commonFiles/Icons";

const AdminUser = () => {
  const [usedStates, setUsedStates] = useState({
    loading: false,
    allAdminUsers: [],

    additionalModal: {
      show: false,
      content: "",
    },

    adminUserModal: {
      open: false,
      modalTitle: "",
      adminUserDetials: {
        userId: "",
        userName: "",
        contactNo: "",
        email: "",
        city: "",
        password: "",
        isActive: "",
      },
    },

    errors: {
      userNameError: "",
      contactNoError: "",
      emailError: "",
      cityError: "",
      passwordError: "",
    },
  });

  /******************* Destructering objects start *****************************/

  const { loading, allAdminUsers, adminUserModal, errors, additionalModal } =
    usedStates;

  const { open, modalTitle, adminUserDetials } = adminUserModal;

  const { userName, contactNo, email, city, password, isActive } =
    adminUserDetials;

  const {
    userNameError,
    contactNoError,
    emailError,
    cityError,
    passwordError,
  } = errors;

  const { show, content } = additionalModal;

  /******************* Destructering objects end *****************************/

  // get All admin Users
  const getAllAdminUsers = async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAdminLoginDetails", {
        params: { optionType: "All" },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((element) => {
          //add count field
          Object.assign(element, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allAdminUsers: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // adminUser columns
  const adminUserColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
      width: "10px",
    },

    {
      title: "User Name",
      field: "userName",
    },

    {
      title: "Contact No",
      field: "contactNo",
    },

    {
      title: "Email",
      field: "email",
    },

    {
      title: "City",
      field: "city",
    },

    {
      title: "Created Date",
      field: "created_dt",
      render: (rowData) => {
        return moment(rowData.created_dt).format("DD/MM/YYYY");
      },
    },

    {
      title: "Assigned DB",
      field: "assigned_db",
    },

    {
      title: "Is Active",
      field: "isActive",
      render: (rowData) => {
        return (
          <span className={rowData.isActive ? "text-success" : "text-danger"}>
            {rowData.isActive ? "YES" : "NO"}
          </span>
        );
      },
    },
  ];

  // reset modal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        adminUserModal: {
          ...prevValue.adminUserModal,
          open: false,
          modalTitle: "",
          adminUserDetials: {
            ...prevValue.adminUserModal.adminUserDetials,
            userId: "",
            userName: "",
            contactNo: "",
            email: "",
            city: "",
            isActive: "",
          },
        },

        errors: {
          ...prevValue.errors,
          userNameError: "",
          contactNoError: "",
          emailError: "",
          cityError: "",
          passwordError: "",
        },
      };
    });
  };

  //open modal
  const handleOpenModal = (userDetails) => {
    const {
      city,
      contactNo,
      email,
      modalTitle,
      password,
      userId,
      userName,
      isActive,
    } = userDetails;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        adminUserModal: {
          ...prevValue.adminUserModal,
          open: true,
          modalTitle,
          adminUserDetials: {
            ...prevValue.adminUserModal.adminUserDetials,
            userName,
            password,
            city,
            contactNo,
            email,
            userId,
            isActive,
          },
        },
      };
    });
  };

  //submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // add option type to adminUserDetails
      Object.assign(adminUserDetials, { optionType: modalTitle });

      const result =
        modalTitle === "Add"
          ? await axios.post("/storeAdminUserDetails", adminUserDetials)
          : await axios.patch("/updateAdminUserDetails", adminUserDetials);

      // on success
      if (result.data.success) {
        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record saved successfully`,
          timer: "3000",
          buttons: false,
        }).then(() => {
          // close modal
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              adminUserModal: {
                ...prevValue.adminUserModal,
                open: false,
              },
            };
          });

          // get all admin  details
          getAllAdminUsers();
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const {
          cityError,
          contactNoError,
          emailError,
          passwordError,
          userNameError,
        } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              cityError,
              contactNoError,
              emailError,
              passwordError,
              userNameError,
            },
          };
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    }
  };

  // handle inputs
  const handleInputEvent = (event) => {
    const { name, value } = event.target;
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        adminUserModal: {
          ...prevValue.adminUserModal,
          adminUserDetials: {
            ...prevValue.adminUserModal.adminUserDetials,
            [name]: value,
          },
        },
      };
    });
  };

  // reset Errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  useEffect(() => {
    getAllAdminUsers();
  }, []);

  return (
    <>
      <section className="designation-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    {/* admin Users */}
                    <div className="col-xl-12 mt-4">
                      <MaterialTable
                        id="designationList"
                        title={
                          <h5 className="fw-bold mt-1">Product Clients</h5>
                        }
                        columns={adminUserColumns}
                        data={allAdminUsers}
                        options={{
                          paging: false,
                          headerStyle: {
                            backgroundColor: "var(--glass-border)",
                            color: "black",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            position: "sticky",
                            zIndex: 100,
                            backdropFilter: "blur(100px)",
                          },
                          maxBodyHeight: 450,
                          rowStyle: {
                            whiteSpace: "nowrap",
                          },
                        }}
                        localization={{
                          header: {
                            actions: "Edit",
                          },
                        }}
                        actions={[
                          //Add User
                          {
                            icon: () => (
                              <MdLibraryAdd
                                fontSize={25}
                                color="var(--success-color)"
                              />
                            ),
                            tooltip: "Add",
                            isFreeAction: true,
                            onClick: () =>
                              handleOpenModal({
                                modalTitle: "Add",
                                userId: "",
                                userName: "",
                                contactNo: "",
                                email: "",
                                city: "",
                                password: "",
                              }),
                          },

                          //Edit User
                          (rowData) => ({
                            icon: () => (
                              <MdEdit
                                fontSize={25}
                                color="var(--primary-color)"
                              />
                            ),
                            tooltip: "Edit",
                            onClick: (event, rowData) => {
                              // get data
                              const {
                                userId,
                                userName,
                                contactNo,
                                email,
                                city,
                                isActive,
                              } = rowData;

                              handleOpenModal({
                                modalTitle: "Edit",
                                userId,
                                userName,
                                contactNo,
                                email,
                                city,
                                isActive: isActive ? "YES" : "NO",
                              });
                            },
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* User Dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className=" d-flex align-items-center justify-content-between"
        >
          <span className="fs-5 fw-bold ">{modalTitle} Client</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-2">
              {/* UserName */}
              <div className="col-xl-12">
                <TextField
                  disabled={modalTitle === "Edit" ? true : false}
                  variant="standard"
                  id="userName"
                  name="userName"
                  label="User Name"
                  fullWidth
                  required
                  value={userName}
                  error={userNameError ? true : false}
                  helperText={userNameError}
                  onChange={(event) => {
                    if (modalTitle !== "Edit") {
                      handleInputEvent(event);
                      handleResetErrors("userNameError");
                    }
                  }}
                />
              </div>

              {/* Contact No */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="contactNo"
                  name="contactNo"
                  label="Contact No"
                  fullWidth
                  required
                  type="tel"
                  value={contactNo}
                  error={contactNoError ? true : false}
                  helperText={contactNoError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("contactNoError");
                  }}
                />
              </div>

              {/* Email Address */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="email"
                  name="email"
                  label="Email ID"
                  fullWidth
                  required
                  type="email"
                  value={email}
                  error={emailError ? true : false}
                  helperText={emailError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("emailError");
                  }}
                />
              </div>

              {/* password */}
              {modalTitle === "Add" ? (
                <div className="col-xl-12">
                  <TextField
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IoInformationCircleSharp
                            aria-describedby="simple-popover"
                            size={20}
                            color="black"
                            cursor="pointer"
                            onMouseOver={() => {
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  additionalModal: {
                                    ...prevValue.additionalModal,
                                    show: true,
                                    content: `
                                  <ul>
                                    <li>length should be minimum 8 characters.</li>
                                    <li>atleast one numeric character.</li>
                                    <li>atleast one Alphabetic letter.</li>
                                    <li>atleast one capital letter.</li>
                                    <li>one special character.</li>
                                    <li>should not contain white space.</li>
                                  </ul>`,
                                  },
                                };
                              });
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    required
                    value={password}
                    error={passwordError ? true : false}
                    helperText={passwordError}
                    onChange={(event) => {
                      handleInputEvent(event);
                      handleResetErrors("passwordError");
                    }}
                  />
                </div>
              ) : null}

              {/* City */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  required
                  value={city}
                  error={cityError ? true : false}
                  helperText={cityError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("cityError");
                  }}
                />
              </div>

              {/* isActive */}
              {modalTitle === "Edit" ? (
                <div className="col-xl-12">
                  <Autocomplete
                    disableClearable
                    popupIcon={false}
                    options={["YES", "NO"]}
                    onChange={(event, value) => {
                      setUsedStates((prevValue) => {
                        return {
                          ...prevValue,
                          adminUserModal: {
                            ...prevValue.adminUserModal,
                            adminUserDetials: {
                              ...prevValue.adminUserModal.adminUserDetials,
                              isActive: value,
                            },
                          },
                        };
                      });
                    }}
                    value={isActive}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Is Active"
                        variant="standard"
                        id="isActive"
                        name="isActive"
                      />
                    )}
                  />
                </div>
              ) : null}

              {/* submit button */}
              <div className="col-xl-12 mt-3">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={loading}
                >
                  {modalTitle === "Add" ? modalTitle : "update"}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* password Modal */}
      <Dialog className="password-modal" open={show} fullWidth maxWidth="xs">
        <DialogTitle
          height={50}
          className=" d-flex align-items-center justify-content-between"
        >
          <span className="fs-5 fw-bold ">The password must include</span>
          <CgClose
            size={25}
            cursor="pointer"
            onClick={() => {
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  additionalModal: {
                    ...prevValue.additionalModal,
                    show: false,
                  },
                };
              });
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminUser;
