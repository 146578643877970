/*******************************  Profarma link  Page ********************************************* */

import {
  React,
  useState,
  useEffect,
  axios,
  swal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  BeatLoader,
  MaterialTable,
  RadioGroup,
  FormControlLabel,
  Radio,
  useCallback,
} from "./commonFiles/Import";
import { AiOutlineClose } from "react-icons/ai";

const Acknowledgement = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,

    ACK_modal: {
      open: false,
      imgSrc: "",
    },

    proFarmaLinkDetails: [],

    typeOfData: "",
  });

  /************* Destructering Objects start ***************************************/
  const { loading, ACK_modal, proFarmaLinkDetails, typeOfData } = usedStates;

  const { open, imgSrc } = ACK_modal;

  /************* Destructering Objects end **************************************************/

  // get Profarma link details
  const getProFarmaLinkDetails = useCallback(
    async ({ typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getProFarmaLinkDetails", {
          params: { typeOfData, userId },
        });

        // on success
        if (result.data) {
          //intialize variable
          let count = 0;

          // add SR.No column
          result.data.forEach((data) => {
            Object.assign(data, { Sr_No: ++count });
          });

          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              proFarmaLinkDetails: result.data,
              loading: false,
            };
          });
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  // profarma link Columns
  const proFarmaLinkColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
    },

    {
      title: "Employee Status",
      field: "employeeStatus",
      render: (rowData) => {
        return (
          <span
            style={{
              color:
                rowData.employeeStatus === "pending"
                  ? "var(--red-color)"
                  : "var(--success-color)",
            }}
          >
            {rowData.employeeStatus}
          </span>
        );
      },
    },

    {
      title: "Acknowledged Profile",
      field: "ACK_profile",
      render: (rowData) => {
        return rowData.ACK_profile ? (
          <Button
            type="button"
            size="small"
            className="primary-button"
            onClick={() =>
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  ACK_modal: {
                    ...prevValue.ACK_modal,
                    open: true,
                    imgSrc: rowData.ACK_profile,
                  },
                };
              })
            }
          >
            view
          </Button>
        ) : (
          "-"
        );
      },
    },

    {
      title: typeOfData !== "P" ? "Transfer Year" : "Promotion Year",
      field: "trnYear",
    },

    {
      title: "KGID No.",
      field: "empCode",
    },

    {
      title: "Full Name",
      field: "fullName",
    },

    {
      title: "contact No",
      field: "mobileNo",
    },

    {
      title: "Email Address",
      field: "email",
    },
  ];

  // handleInput event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  //get setting data
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        const { settingStatusDetails } = result.data;

        //get data
        const { actionType } = settingStatusDetails;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            typeOfData: actionType,
          };
        });

        getProFarmaLinkDetails({ typeOfData: actionType });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [getProFarmaLinkDetails, userId]);

  useEffect(() => {
    getSettingData();
  }, [getSettingData]);

  return (
    <>
      <section className="profarma-link-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card  border-0">
                <div className="card-body">
                  <div className="row">
                    {/* Profarma */}
                    <div className="col-xl-12 mt-4">
                      <MaterialTable
                        id="vacancyList"
                        title={
                          <>
                            <div className="d-flex flex-row">
                              <h5 className="fw-bold mt-1">
                                {" "}
                                Acknowledgement List
                              </h5>

                              {/* type Of Data */}
                              <RadioGroup
                                className="ms-5 d-flex align-items-center"
                                row
                                value={typeOfData}
                              >
                                {/* General  Transfer */}
                                <FormControlLabel
                                  value="G"
                                  control={<Radio size="small" />}
                                  label={
                                    <span className="ms-2">
                                      General Transfer
                                    </span>
                                  }
                                  name="typeOfData"
                                  id="typeOfData"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                    getProFarmaLinkDetails({
                                      typeOfData: event.target.value,
                                    });
                                  }}
                                />

                                {/* Request Transfer */}
                                <FormControlLabel
                                  className="ms-1"
                                  value="T"
                                  control={<Radio size="small" />}
                                  label={
                                    <span className="ms-2">
                                      Request Transfer
                                    </span>
                                  }
                                  name="typeOfData"
                                  id="typeOfData"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                    getProFarmaLinkDetails({
                                      typeOfData: event.target.value,
                                    });
                                  }}
                                />

                                {/* Promotion  */}
                                <FormControlLabel
                                  className="ms-1"
                                  value="P"
                                  control={<Radio size="small" />}
                                  label={
                                    <span className="ms-2">Promotion</span>
                                  }
                                  name="typeOfData"
                                  id="typeOfData"
                                  onChange={(event) => {
                                    handleInputEvent(event);
                                    getProFarmaLinkDetails({
                                      typeOfData: event.target.value,
                                    });
                                  }}
                                />
                              </RadioGroup>
                            </div>
                          </>
                        }
                        columns={proFarmaLinkColumns}
                        data={proFarmaLinkDetails}
                        options={{
                          paging: false,
                          headerStyle: {
                            backgroundColor: "var(--glass-border)",
                            color: "black",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            position: "sticky",
                            zIndex: 100,
                            backdropFilter: "blur(100px)",
                          },
                          maxBodyHeight: 350,
                          rowStyle: {
                            whiteSpace: "nowrap",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* show ACk profile */}
      <Dialog open={open}>
        <DialogTitle className="fw-bold d-flex flex-row justify-content-between">
          <span>Acknowledged Profile</span>
          <AiOutlineClose
            fontSize={25}
            style={{ cursor: "pointer" }}
            onClick={() =>
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  ACK_modal: {
                    ...prevValue.ACK_modal,
                    open: false,
                  },
                };
              })
            }
          />
        </DialogTitle>
        <DialogContent>
          <img src={imgSrc} alt="profile.jpeg" className="img-fluid" />
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Acknowledgement;
