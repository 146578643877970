/********************* User page ****************************************/

import {
  React,
  useState,
  swal,
  useEffect,
  axios,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  DialogTitle,
  TextField,
  Button,
  moment,
  Autocomplete,
  InputAdornment,
  useCallback,
} from "../commonFiles/Import";

import {
  CgClose,
  MdLibraryAdd,
  MdEdit,
  MdDelete,
  FaEye,
  IoInformationCircleSharp,
} from "../commonFiles/Icons";

const User = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,
    allUsers: [],
    allModules: [],

    additionalModal: {
      show: false,
      content: "",
      dialogTitle: "",
    },

    userModal: {
      open: false,
      modalTitle: "",
      userDetials: {
        id: "",
        userName: "",
        password: "",
        role: "",
        isActive: "",
        assignTabs: [],
      },
    },

    errors: {
      userNameError: "",
      passwordError: "",
      roleError: "",
      assignTabsError: "",
    },
  });

  /******************* Destructering objects start *****************************/

  const { loading, allUsers, userModal, errors, allModules, additionalModal } =
    usedStates;

  const { open, userDetials, modalTitle } = userModal;

  const { userName, password, role, isActive, assignTabs } = userDetials;

  const { userNameError, passwordError, roleError, assignTabsError } = errors;

  const { show, content, dialogTitle } = additionalModal;

  /******************* Destructering objects end *****************************/

  // get all Users
  const getAllUserDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getUserDetails", { params: { userId } });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((element) => {
          //add count field
          Object.assign(element, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allUsers: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get all modules
  const getAllModuleDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getModuleDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allModules: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // userColumns
  const userColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
      width: "10px",
    },

    {
      title: "User Name",
      field: "userName",
      width: "10px",
    },

    {
      title: "Password",
      field: "userPassword",
      width: "10px",
      render: (rowData) => {
        return (
          <FaEye
            fontSize={20}
            cursor="pointer"
            onClick={() => {
              swal({ text: atob(rowData.userPassword) });
            }}
          />
        );
      },
    },

    {
      title: "Created Date",
      field: "createdDate",
      render: (rowData) => {
        return moment(rowData.createdDate).format("DD/MM/YYYY");
      },
      width: "10px",
    },

    {
      title: "Role",
      field: "role",
      width: "10px",
    },

    {
      title: "isActive",
      field: "isActive",
      width: "10px",
      render: (rowData) => {
        return (
          <span className={rowData.isActive ? "text-success" : "text-danger"}>
            {rowData.isActive ? "YES" : "NO"}
          </span>
        );
      },
    },

    {
      title: "Assigned Tabs",
      field: "assignedTabs",
      render: (rowData) => {
        return rowData.assignedTabs ? (
          <Button
            className="success-button shadow"
            style={{ lineHeight: "15px" }}
            variant="contained"
            size="small"
            onClick={() =>
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  additionalModal: {
                    ...prevValue.additionalModal,
                    dialogTitle: "Assigned Tabs",
                    show: true,
                    content: `<ul> 
                                  ${rowData.assignedTabs
                                    .split(",")
                                    .map(
                                      (data, index) =>
                                        `<li key=${index} >${data}</li>`
                                    )
                                    .join("")}                            
                              </ul>`,
                  },
                };
              })
            }
          >
            view
          </Button>
        ) : (
          "-"
        );
      },
    },
  ];

  // handle ResetModal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        userModal: {
          ...prevValue.userModal,
          open: false,
          modalTitle: "",
          userDetials: {
            ...prevValue.userModal.userDetials,
            id: "",
            userName: "",
            password: "",
            role: "",
            isActive: "",
            assignTabs: [],
          },
        },

        errors: {
          ...prevValue.errors,
          userNameError: "",
          passwordError: "",
          roleError: "",
          assignTabsError: "",
        },
      };
    });
  };

  // open modal
  const handleOpenModal = ({
    title,
    id,
    userName,
    password,
    role,
    isActive,
    assignTabs,
  }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        userModal: {
          ...prevValue.userModal,
          open: true,
          modalTitle: title,
          userDetials: {
            ...prevValue.userModal.userDetials,
            id,
            userName,
            password,
            role,
            isActive,
            assignTabs: assignTabs.map((moduleName) => ({ moduleName })),
          },
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // add optionType to differentiate operations
      Object.assign(userDetials, { optionType: modalTitle, userId });

      const result =
        modalTitle === "Add"
          ? await axios.post("/storeUserDetails", userDetials)
          : await axios.patch("/updateUserDetails", userDetials);

      // on success
      if (result.data.success) {
        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record saved successfully`,
          timer: "3000",
          buttons: false,
        }).then(() => {
          // close modal
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              userModal: {
                ...prevValue.userModal,
                open: false,
              },
            };
          });

          // get all user details
          getAllUserDetails();
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const { userNameError, passwordError, roleError, assignTabsError } =
          errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              userNameError,
              passwordError,
              roleError,
              assignTabsError,
            },
          };
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    }
  };

  // handle delete
  const handleDelete = ({ id }) => {
    swal({
      title: `Are You Sure ?`,
      icon: "warning",
      text: "You won't be able to revert this!",
      buttons: [true, "confirm"],
    }).then(async (value) => {
      try {
        if (value) {
          // enable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: true,
            };
          });

          const result = await axios.delete("/deleteUser", {
            params: { id, userId },
          });

          // on success
          if (result.data.success) {
            // show success messaage
            swal({
              icon: "success",
              title: "success",
              text: `Your record deleted successfully`,
              timer: "3000",
              buttons: false,
            }).then(() => {
              // get All users details
              getAllUserDetails();
            });
          }
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    });
  };

  // handle Input event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        userModal: {
          ...prevValue.userModal,
          userDetials: {
            ...prevValue.userModal.userDetials,
            [name]: value,
          },
        },
      };
    });
  };

  // reset errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  // handle autoInput Event
  const handleAutoInputEvent = ({ value, name }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        userModal: {
          ...prevValue.userModal,
          userDetials: {
            ...prevValue.userModal.userDetials,
            [name]: value,
          },
        },
      };
    });
  };

  useEffect(() => {
    getAllUserDetails();
    getAllModuleDetails();
  }, [getAllUserDetails, getAllModuleDetails]);

  return (
    <>
      <section className="designation-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    {/* Profarma */}
                    <div className="col-xl-12 mt-4">
                      <MaterialTable
                        id="designationList"
                        title={<h5 className="fw-bold mt-1">Users</h5>}
                        columns={userColumns}
                        data={allUsers}
                        options={{
                          paging: false,
                          headerStyle: {
                            backgroundColor: "var(--glass-border)",
                            color: "black",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            position: "sticky",
                            zIndex: 100,
                            backdropFilter: "blur(100px)",
                          },
                          maxBodyHeight: 450,
                          rowStyle: {
                            whiteSpace: "nowrap",
                          },
                        }}
                        localization={{
                          header: {
                            actions: "Edit / Delete",
                          },
                        }}
                        actions={[
                          //Add User
                          {
                            icon: () => (
                              <MdLibraryAdd
                                fontSize={25}
                                color="var(--success-color)"
                              />
                            ),
                            tooltip: "Add",
                            isFreeAction: true,
                            onClick: () =>
                              handleOpenModal({
                                title: "Add",
                                id: "",
                                userName: "",
                                password: "",
                                role: "",
                                isActive: "YES",
                                assignTabs: [],
                              }),
                          },

                          //Edit User
                          (rowData) => ({
                            icon: () => (
                              <MdEdit
                                fontSize={25}
                                color="var(--primary-color)"
                              />
                            ),
                            tooltip: "Edit",
                            onClick: (event, rowData) => {
                              const {
                                assignedTabs,
                                isActive,
                                role,
                                userId,
                                userName,
                                userPassword,
                              } = rowData;

                              handleOpenModal({
                                title: "Edit",
                                id: userId,
                                userName,
                                password: atob(userPassword),
                                role,
                                isActive: isActive ? "YES" : "NO",
                                assignTabs: assignedTabs.split(","),
                              });
                            },
                          }),

                          //Delete User
                          (rowData) => ({
                            icon: () => (
                              <MdDelete
                                fontSize={25}
                                color="var(--red-color)"
                              />
                            ),
                            tooltip: "Delete",

                            onClick: (event, rowData) =>
                              handleDelete({ id: rowData.userId }),
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* User Dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className=" d-flex align-items-center justify-content-between"
        >
          <span className="fs-5 fw-bold ">{modalTitle} User</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-2">
              {/* UserName */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  disabled={modalTitle === "Edit" ? true : false}
                  id="userName"
                  name="userName"
                  label="User Name"
                  fullWidth
                  required
                  value={userName}
                  error={userNameError ? true : false}
                  helperText={userNameError}
                  onChange={(event) => {
                    if (modalTitle !== "Edit") {
                      handleInputEvent(event);
                      handleResetErrors("userNameError");
                    }
                  }}
                />
              </div>

              {/* password */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IoInformationCircleSharp
                          aria-describedby="simple-popover"
                          size={20}
                          color="black"
                          cursor="pointer"
                          onMouseOver={() => {
                            setUsedStates((prevValue) => {
                              return {
                                ...prevValue,
                                additionalModal: {
                                  ...prevValue.additionalModal,
                                  show: true,
                                  dialogTitle: "The password must include",
                                  content: `
                                  <ul>
                                    <li>length should be minimum 8 characters.</li>
                                    <li>atleast one numeric character.</li>
                                    <li>atleast one Alphabetic letter.</li>
                                    <li>atleast one capital letter.</li>
                                    <li>one special character.</li>
                                    <li>should not contain white space.</li>
                                  </ul>`,
                                },
                              };
                            });
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  value={password}
                  error={passwordError ? true : false}
                  helperText={passwordError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("passwordError");
                  }}
                />
              </div>

              {/* Role */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="role"
                  name="role"
                  label="Role"
                  fullWidth
                  required
                  value={role}
                  error={roleError ? true : false}
                  helperText={roleError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("roleError");
                  }}
                />
              </div>

              {/* Assign Tabs */}
              <div className="col-xl-12">
                <Autocomplete
                  multiple
                  fullWidth
                  disableClearable
                  onChange={(event, values) => {
                    handleAutoInputEvent({ value: values, name: "assignTabs" });

                    handleResetErrors("assignTabsError");
                  }}
                  value={assignTabs}
                  options={allModules}
                  getOptionLabel={(options) => options.moduleName}
                  renderInput={(params) => (
                    <TextField
                      label="Assign Tabs*"
                      variant="standard"
                      error={assignTabsError ? true : false}
                      helperText={assignTabsError}
                      {...params}
                    />
                  )}
                />
              </div>

              {/* isActive */}
              {modalTitle === "Edit" ? (
                <div className="col-xl-12">
                  <Autocomplete
                    disableClearable
                    popupIcon={false}
                    options={["YES", "NO"]}
                    onChange={(event, value) => {
                      handleAutoInputEvent({ value, name: "isActive" });
                    }}
                    value={isActive}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Is Active"
                        variant="standard"
                        id="isActive"
                        name="isActive"
                      />
                    )}
                  />
                </div>
              ) : null}

              {/* submit button */}
              <div className="col-xl-12 mt-3">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={loading}
                >
                  {modalTitle === "Add" ? modalTitle : "update"}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* password Modal */}
      <Dialog className="password-modal" open={show} fullWidth maxWidth="xs">
        <DialogTitle
          height={50}
          className=" d-flex align-items-center justify-content-between"
        >
          <span className="fs-5 fw-bold ">{dialogTitle}</span>
          <CgClose
            size={25}
            cursor="pointer"
            onClick={() => {
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  additionalModal: {
                    ...prevValue.additionalModal,
                    show: false,
                  },
                };
              });
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default User;
