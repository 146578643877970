/***************************************** transfer search page  **************************************************************/

import {
  React,
  swal,
  useEffect,
  useState,
  axios,
  lodash,
  Button,
  useHistory,
  Dialog,
  DialogContent,
  BeatLoader,
  NativeSelect,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useCallback,
  DialogTitle,
  TextField,
  Autocomplete,
  clientSocket,
} from "./commonFiles/Import";

import { AiOutlineClose } from "./commonFiles/Icons";

const SearchTransfer = () => {
  // get value from session
  const userId = window.sessionStorage.getItem("adminUserId");
  const adminUserName = window.sessionStorage.getItem("adminUserName");

  const history = useHistory();

  const [usedStates, setUsedStates] = useState({
    transferYears: [],
    designations: [],

    searchDetails: {
      transferYear: "",
      designation: "",
      designationTo: "", // only for promotion
      typeOfData: "",
      NKK_KK: "",
    },

    undoModal: {
      open: false,
      searchOption: "empCode", // by default it' empCode
      empCodes: [],
      metalNos: [],
      undoDetails: {
        transferYear: "",
        empCode: "",
        designation: "",
        fullName: "",
        postCode: "",
        transferTo: "",
        transferFrom: "",
        metalNo: "",
      },
    },

    NKK_KK_Status: false,
    showBothVacancies: false,
    operationType: "",

    loading: false,
  });

  /********************* destructering objects start ********************/
  const {
    searchDetails,
    designations,
    transferYears,
    loading,
    undoModal,
    NKK_KK_Status,
    showBothVacancies,
    operationType,
  } = usedStates;

  const { transferYear, designation, typeOfData, NKK_KK, designationTo } =
    searchDetails;

  const { undoDetails, open, searchOption, empCodes, metalNos } = undoModal;

  const { empCode, fullName, transferTo, transferFrom, metalNo } = undoDetails;

  /********************* destructering objects start ********************/

  // get transferYears
  const getTransferYears = useCallback(
    async ({ transferYear, typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getUserDetailsSR", {
          params: { transferYear, typeOfData, userId },
        });

        // on success
        if (result.data) {
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              transferYears: lodash.uniqBy(result.data, "transferYear"),
              loading: false,
            };
          });
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });
      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // handle input event
  const handleInputEvent = ({ type, value }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          [type]: value,
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // Add userId
      Object.assign(searchDetails, { userId });

      const result = await axios.get("/getTransferListData", {
        params: searchDetails,
      });

      // on success
      if (result.data) {
        const { transferListData, totalTransfers } = result.data;

        if (transferListData.length > 0) {
          //store values in session
          window.sessionStorage.setItem("typeOfData", typeOfData);
          window.sessionStorage.setItem("transferYear", transferYear);
          window.sessionStorage.setItem("designation", designation);
          window.sessionStorage.setItem("showBothVacancies", showBothVacancies);
          window.sessionStorage.setItem("NKK_KK", NKK_KK);
          window.sessionStorage.setItem("designationTo", designationTo);

          const listOfTransfers = lodash.uniqBy(transferListData, "EmpCode");

          // send trasfer details  to socket
          if (operationType !== "Administrative") {
            clientSocket.emit("transferDetails", {
              typeOfData,
              listOfTransfers,
              totalTransfers,
            });
          }

          // redirect to transfer form
          history.push({
            pathname:
              typeOfData === "G"
                ? `/${adminUserName}/general-transfer`
                : typeOfData === "T"
                ? `/${adminUserName}/request-transfer`
                : `/${adminUserName}/promotion`,
            state: {
              listOfTransfers,
              totalTransfers,
            },
          });
        } else {
          swal({
            title: "Error",
            icon: "error",
            text: "No Records Found.",
          });
        }
      }

      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      // show errors to user
      if (errors) {
        const { transferYearError, designationError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              transferYearError,
              designationError,
            },
          };
        });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  // undo all transfers
  const undoAllTransfers = () => {
    if (transferYear && designation && typeOfData) {
      swal({
        title: "Are you sure ?",
        text: `You want to undo all recent ${
          typeOfData !== "P" ? `transfers` : `promotions`
        }.`,
        icon: "warning",
        buttons: true,
      }).then(async (value) => {
        if (value) {
          // enable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: true,
            };
          });

          // assign vairables
          const undoDetails = {
            designation,
            empCode: "",
            transferTo: "",
            postCode: "",
            transferYear,
            typeOfData,
            undoAll: "ALL",
            userId,
          };

          const result = await axios.patch("/updateUndoDetails", undoDetails);

          // on success
          if (result.data.success) {
            //disable loading
            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                loading: false,
              };
            });

            swal({
              title: "success",
              icon: "success",
              text: `All ${
                typeOfData !== "P" ? `transfers` : `promotions`
              } are reverted successfully.`,
            });
          }
        }
      });
    } else {
      swal({
        title: "Error",
        icon: "error",
        text: "Please select transferYear and designation.",
      });
    }
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        const { settingStatusDetails } = result.data;

        // get data of setting status
        const {
          transferYear,
          designation,
          NKK_KK_Status,
          actionType,
          vacancyListType,
          operation_Type,
          designationTo,
        } = settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            searchDetails: {
              ...prevValue.searchDetails,
              transferYear,
              designation,
              typeOfData: actionType,
              designationTo,
            },

            NKK_KK_Status: NKK_KK_Status === "Y" ? true : false,
            showBothVacancies: vacancyListType.includes("Potential")
              ? true
              : false,

            operationType: operation_Type,

            undoModal: {
              ...prevValue.undoModal,
              undoDetails: {
                ...prevValue.undoModal.undoDetails,
                transferYear,
                designation,
              },
            },

            loading: false,
          };
        });

        getTransferYears({ typeOfData: actionType, transferYear: "" });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, getTransferYears]);

  // get undo Details
  const getUndoDetails = async ({
    transferYear,
    designation,
    empCode,
    metalNo,
    optionType,
  }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getUndoDetails", {
        params: {
          transferYear,
          designation,
          empCode,
          typeOfData,
          metalNo,
          userId,
        },
      });

      // on success
      if (result.data) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        if (result.data.length > 0) {
          // get all empCodes / metalNos
          if (optionType === "getAllData") {
            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                undoModal: {
                  ...prevValue.undoModal,
                  empCodes: result.data,
                  metalNos: result.data,
                },
              };
            });
          }

          // update undo details for specific empCode/metalNo
          if (optionType === "specificData") {
            const { Full_name, pstCode, Trs_To, Trs_From, empCode, MetalNo } =
              result.data[0];

            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                undoModal: {
                  ...prevValue.undoModal,
                  undoDetails: {
                    ...prevValue.undoModal.undoDetails,
                    fullName: Full_name,
                    postCode: pstCode,
                    transferTo: Trs_To,
                    transferFrom: Trs_From,
                    empCode,
                    metalNo: MetalNo,
                  },
                },
              };
            });
          }
        }

        // display message if no records found
        else {
          //close modal
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              undoModal: {
                ...prevValue.undoModal,
                open: false,
              },
            };
          });

          swal({ title: "Error", icon: "error", text: "No, record found. " });
        }
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle undo Input
  const handleUndoInput = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        undoModal: {
          ...prevValue.undoModal,
          searchOption: "empCode", // by default it's empCode
          open: true,
          undoDetails: {
            ...prevValue.undoModal.undoDetails,
            transferYear,
            designation,
          },
        },
      };
    });

    getUndoDetails({
      transferYear,
      designation,
      empCode: "",
      metalNo: "",
      optionType: "getAllData",
    });
  };

  // handle undo / update undo Details
  const handleUndo = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // add one more column typeOf Data in undoDetails
      Object.assign(undoDetails, { typeOfData, undoAll: "", userId });

      const result = await axios.patch("/updateUndoDetails", undoDetails);

      // on success
      if (result.data.success) {
        swal({
          title: "success",
          icon: "success",
          text: `The  ${
            typeOfData !== "P" ? `transfer` : `promotion`
          } is successfully reverted`,
          timer: "3000",
          buttons: false,
        }).then(() => {
          //disable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: false,
            };
          });

          handleResetUndoDetails({ optionType: "All" });
        });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  //reset undoDetails
  const handleResetUndoDetails = ({ optionType }) => {
    if (optionType === "All") {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          undoModal: {
            ...prevValue.undoModal,
            searchOption: "empCode", // by default it's empCode
            open: false,
            empCodes: [],
            undoDetails: {
              ...prevValue.undoModal.undoDetails,
              transferYear: "",
              empCode: "",
              designation: "",
              fullName: "",
              postCode: "",
              transferFrom: "",
              metalNo: "",
              transferTo: "",
            },
          },
        };
      });
    } else {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          undoModal: {
            ...prevValue.undoModal,
            undoDetails: {
              ...prevValue.undoModal.undoDetails,
              transferYear,
              designation,
              empCode: "",
              fullName: "",
              transferFrom: "",
              transferTo: "",
            },
          },
        };
      });
    }
  };

  useEffect(() => {
    getDesignationDetails();
    getSettingData();
  }, [getSettingData, getDesignationDetails]);

  return (
    <>
      <section
        className="search-transfer-section"
        style={{ marginTop: "5rem" }}
      >
        <div className="container-fluid">
          <div className="card border-0 mb-4">
            <div className="card-header">
              <div className="row">
                <div className="col-xl-2">
                  <h1 className="fs-4 fw-bold">
                    Start {typeOfData !== "P" ? "Transfer" : "Promotion"}{" "}
                  </h1>
                </div>

                {/* Type of data */}
                <div className="col-xl">
                  <RadioGroup row value={typeOfData}>
                    {/* General Transfer */}
                    <FormControlLabel
                      value="G"
                      control={<Radio size="small" />}
                      label="General Transfer"
                      name="typeOfData"
                      id="typeOfData"
                      disabled={typeOfData !== "G"}
                    />

                    {/* Request Transfer */}
                    <FormControlLabel
                      value="T"
                      control={<Radio size="small" />}
                      label="Request Transfer"
                      name="typeOfData"
                      id="typeOfData"
                      disabled={typeOfData !== "T"}
                    />

                    {/* Promotion */}
                    <FormControlLabel
                      value="P"
                      control={<Radio size="small" />}
                      label="Promotion"
                      name="typeOfData"
                      id="typeOfData"
                      disabled={typeOfData !== "P"}
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form method="POST" autoComplete="Off" onSubmit={handleSubmit}>
                <div className="row mb-3 g-3 mt-1 ">
                  {/* select transferYear */}
                  <div className="col-xl-3">
                    <InputLabel>
                      Select{" "}
                      {typeOfData !== "P"
                        ? "transfer year*"
                        : "promotion year*"}
                    </InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      variant="standard"
                      inputProps={{
                        name: "transferYear",
                        id: "transferYear",
                      }}
                      onChange={(event) => {
                        handleInputEvent({
                          type: "transferYear",
                          value: event.target.value,
                        });
                      }}
                      value={transferYear}
                    >
                      {transferYears.map((data, index) => {
                        return (
                          <option key={index} value={data.transferYear}>
                            {data.transferYear}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>

                  {/* select Designation */}
                  <div className="col-xl-3">
                    <InputLabel>Select Designation*</InputLabel>
                    <NativeSelect
                      required
                      fullWidth
                      variant="standard"
                      inputProps={{
                        name: "designation",
                        id: "designation",
                      }}
                      onChange={(event) => {
                        handleInputEvent({
                          type: "designation",
                          value: event.target.value,
                        });
                      }}
                      value={designation}
                    >
                      {designations.map((data, index) => {
                        return (
                          <option key={index} value={data.Cadre}>
                            {data.Cadre}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>

                  {/*NKK_KK */}
                  {NKK_KK_Status ? (
                    <div className="col-xl-3 d-flex align-items-center">
                      <RadioGroup row value={NKK_KK}>
                        <FormControlLabel
                          value="NKK"
                          control={<Radio size="small" />}
                          label="NKK"
                          name="NKK_KK"
                          id="NKK_KK"
                          onChange={(event) => {
                            handleInputEvent({
                              type: "NKK_KK",
                              value: event.target.value,
                            });
                          }}
                        />
                        <FormControlLabel
                          value="KK"
                          control={<Radio size="small" />}
                          label="KK"
                          name="NKK_KK"
                          id="NKK_KK"
                          onChange={(event) => {
                            handleInputEvent({
                              type: "NKK_KK",
                              value: event.target.value,
                            });
                          }}
                        />
                      </RadioGroup>
                    </div>
                  ) : null}

                  <div className="buttons">
                    {/* submit button  */}
                    <Button
                      variant="outlined"
                      className="primary-button  shadow"
                      type="submit"
                    >
                      start
                    </Button>

                    {/* auto transfer  */}
                    {typeOfData === "T" ? (
                      <Button
                        variant="outlined"
                        className="success-button shadow ms-2 d-none"
                        onClick={() => alert("Comming Soon")}
                      >
                        auto transfer
                      </Button>
                    ) : null}

                    {/* undo all  */}
                    <Button
                      variant="outlined"
                      className="undo-button  shadow ms-2"
                      onClick={() => undoAllTransfers()}
                    >
                      undo all
                    </Button>

                    {/* specific undo */}
                    <Button
                      variant="outlined"
                      className="reset-button  shadow ms-2"
                      onClick={handleUndoInput}
                    >
                      undo
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Dialog for undo operation */}
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle className="fw-bold">
          <div className="row">
            <div className="col-sm-3">
              <span>Undo Details</span>
            </div>
            <div className="col-sm">
              <RadioGroup row value={searchOption}>
                <FormControlLabel
                  value="empCode"
                  control={<Radio size="small" />}
                  label="KGIDNo"
                  name="undoOption"
                  id="undoOption"
                  onChange={(event) => {
                    handleResetUndoDetails({ optionType: "specific" });

                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        undoModal: {
                          ...prevValue.undoModal,
                          searchOption: event.target.value,
                        },
                      };
                    });
                  }}
                />
                <FormControlLabel
                  value="metalNo"
                  control={<Radio size="small" />}
                  label="MetalNo"
                  name="undoOption"
                  id="undoOption"
                  onChange={(event) => {
                    handleResetUndoDetails({ optionType: "specific" });

                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        undoModal: {
                          ...prevValue.undoModal,
                          searchOption: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </RadioGroup>
            </div>
            <div className="col-sm-1">
              <AiOutlineClose
                fontSize={25}
                style={{ cursor: "pointer" }}
                onClick={() => handleResetUndoDetails({ optionType: "All" })}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleUndo}>
            <div className="row g-2">
              {/* Transfer Year */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Transfer Year"
                  variant="standard"
                  value={transferYear}
                  className="pe-none"
                  required
                />
              </div>

              {/* Designation */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Designation"
                  variant="standard"
                  value={designation}
                  className="pe-none"
                  required
                />
              </div>

              {/* KGID No / metal No  */}
              <div className="col-xl-12">
                {undoModal.searchOption === "empCode" ? (
                  <Autocomplete
                    size="small"
                    disableClearable
                    forcePopupIcon={false}
                    onChange={(event, value) => {
                      setUsedStates((prevValue) => {
                        return {
                          ...prevValue,
                          undoModal: {
                            ...prevValue.undoModal,
                            undoDetails: {
                              ...prevValue.undoModal.undoDetails,
                              empCode: value.empCode,
                            },
                          },
                        };
                      });

                      getUndoDetails({
                        transferYear: undoDetails.transferYear,
                        designation: undoDetails.designation,
                        empCode: value.empCode,
                        optionType: "specificData",
                        metalNo: "",
                      });
                    }}
                    inputValue={empCode.toString()}
                    options={empCodes}
                    getOptionLabel={(options) => options.empCode.toString()}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        {...params}
                        label="Select KGID No."
                        onChange={(event) => {
                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              undoModal: {
                                ...prevValue.undoModal,
                                undoDetails: {
                                  ...prevValue.undoModal.undoDetails,
                                  empCode: event.target.value,
                                },
                              },
                            };
                          });
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    disableClearable
                    forcePopupIcon={false}
                    onChange={(event, value) => {
                      setUsedStates((prevValue) => {
                        return {
                          ...prevValue,
                          undoModal: {
                            ...prevValue.undoModal,
                            undoDetails: {
                              ...prevValue.undoModal.undoDetails,
                              metalNo: value.MetalNo,
                            },
                          },
                        };
                      });

                      getUndoDetails({
                        transferYear: undoDetails.transferYear,
                        designation: undoDetails.designation,
                        metalNo: value.MetalNo,
                        optionType: "specificData",
                        empCode: "",
                      });
                    }}
                    inputValue={metalNo}
                    options={metalNos}
                    getOptionLabel={(options) => options.MetalNo}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        {...params}
                        label="Select Metal No."
                        onChange={(event) => {
                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              undoModal: {
                                ...prevValue.undoModal,
                                undoDetails: {
                                  ...prevValue.undoModal.undoDetails,
                                  metalNo: event.target.value,
                                },
                              },
                            };
                          });
                        }}
                      />
                    )}
                  />
                )}
              </div>

              {/* Full Name */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Full Name"
                  variant="standard"
                  value={fullName}
                  className="pe-none"
                  required
                />
              </div>

              {/* Transfer From */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Transfer From"
                  variant="standard"
                  value={transferFrom}
                  className="pe-none"
                />
              </div>

              {/* Transfer to  */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Transfer To"
                  variant="standard"
                  value={transferTo}
                  className="pe-none"
                />
              </div>
            </div>

            {/* submit button */}
            <Button
              variant="outlined"
              className="success-button mt-3"
              type="submit"
            >
              undo
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchTransfer;
