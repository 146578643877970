/*************************************** sidebar page *****************************************/

import {
  React,
  useHistory,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useState,
  Collapse,
  swal,
  axios,
} from "./Import";
import {
  IoMdAdd,
  IoMdCreate,
  FaUserTie,
  MdExpandLess,
  MdExpandMore,
  AiOutlinePoweroff,
  BiTransfer,
  HiClipboardDocumentList,
  HiMiniClipboardDocumentList,
  AiFillSetting,
  PiListChecksBold,
  FaEye,
  FaClipboardUser,
  MdOutlineDragIndicator,
  AiFillControl,
  MdLocalPolice,
  HiMiniBuildingOffice2,
  FaUsersCog,
  FaUsers,
} from "../commonFiles/Icons";

const SideBar = (props) => {
  const history = useHistory();

  //get userName
  const userName = window.sessionStorage.getItem("userName");

  // get adminRole
  const adminRole = window.sessionStorage.getItem("adminRole");

  //get adminUserId
  const userId = window.sessionStorage.getItem("adminUserId");

  // get adminUserName
  const adminUserName = window.sessionStorage.getItem("adminUserName");

  const [usedStates, setUsedStates] = useState({
    proForma: false,
    controlPanel: false,
  });

  /******************************** destructering objects start ********************************/
  const { proForma, controlPanel } = usedStates;

  const {
    acknowledgment,
    createProforma,
    designation,
    editProforma,
    policeStation,
    potVacancyList,
    seniorityList,
    setting,
    transfer,
    transferLIst,
    users,
    vacancyIndicator,
    viewProforma,
  } = props.assignModules;

  /******************************** destructering objects end ********************************/

  // logout
  const logout = async () => {
    try {
      if (adminRole !== "superAdmin") {
        await axios.get("/getTransferStatus", {
          params: {
            userName,
            transferStarted: false,
            userId,
            userRole: adminRole,
          },
        });
      }

      // on success
      swal({
        text: "You have been logged out successfully.",
        timer: 4000,
        buttons: false,
        icon: "success",
      }).then(() => {
        const userId = window.sessionStorage.getItem("adminUserId");
        const adminRole = window.sessionStorage.getItem("adminRole");
        const adminUserName = window.sessionStorage.getItem("adminUserName");

        // clear session
        sessionStorage.clear();

        // keep session of user
        if (userId && adminRole && adminUserName) {
          window.sessionStorage.setItem("adminUserId", userId);
          window.sessionStorage.setItem("adminRole", adminRole);
          window.sessionStorage.setItem("adminUserName", adminUserName);
        }
      });

      // redirect to home page
      history.push(`/${adminUserName}`);
    } catch (err) {
      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  return (
    <>
      {/* logo */}
      <section className="sr-logo text-center mt-2">
        <img
          src={require("../../dist/img/profile.png")}
          alt="profile.png"
          className="img-fluid"
          width="75"
          height="75"
        />
        <h1 className="mt-2 mb-2 fw-bold fs-5">{userName}</h1>
        <hr style={{ borderTop: "1px solid black" }} />
      </section>

      {/* sidebar section */}
      <section className="sidebar-section">
        {adminRole === "superAdmin" ? (
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                history.push({
                  pathname: `/${adminUserName}/product-clients`,
                });

                // close sidebar
                props.closeDrawer();
              }}
            >
              <ListItemIcon>
                <FaUsers size={18} />
              </ListItemIcon>
              <ListItemText primary="Product Clients" />
            </ListItem>
          </List>
        ) : null}

        {/* Acknowledgement */}
        {acknowledgment ? (
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: `/${adminUserName}/acknowledgement`,
              });
            }}
          >
            <ListItemIcon>
              <FaClipboardUser size={18} />
            </ListItemIcon>
            <ListItemText primary="Acknowledgement" />
          </ListItem>
        ) : null}

        {/*********************  proforma menu start ******************************/}
        {createProforma || editProforma || viewProforma ? (
          <>
            <ListItem
              button
              onClick={() => {
                setUsedStates((prevValue) => {
                  return {
                    ...prevValue,
                    proForma: !proForma,
                  };
                });
              }}
            >
              <ListItemIcon>
                <FaUserTie size={18} />
              </ListItemIcon>
              <ListItemText primary="Proforma" />
              {proForma ? <MdExpandLess /> : <MdExpandMore />}
            </ListItem>

            <Collapse in={proForma} timeout="auto" unmountOnExit>
              {/* create profarma */}
              {createProforma ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/create-profarma`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <IoMdAdd size={18} />
                    </ListItemIcon>
                    <ListItemText primary="Create Proforma" />
                  </ListItem>
                </List>
              ) : null}

              {/* edit profarma */}
              {editProforma ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/edit-profarma`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <IoMdCreate size={18} />
                    </ListItemIcon>
                    <ListItemText primary="Edit Proforma" />
                  </ListItem>
                </List>
              ) : null}

              {/* View profarma */}
              {viewProforma ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/view-profarma`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <FaEye size={18} />
                    </ListItemIcon>
                    <ListItemText primary="View Proforma" />
                  </ListItem>
                </List>
              ) : null}
            </Collapse>
          </>
        ) : null}

        {/*********************** proforma menu end ********************************/}

        {/* Potential vacancy List */}
        {potVacancyList ? (
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: `/${adminUserName}/potential-vacancy-list`,
              });
            }}
          >
            <ListItemIcon>
              <HiClipboardDocumentList size={18} />
            </ListItemIcon>
            <ListItemText primary="Potential Vacancy List" />
          </ListItem>
        ) : null}

        {/* Seniority list */}
        {seniorityList ? (
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: `/${adminUserName}/seniority-list`,
              });
            }}
          >
            <ListItemIcon>
              <HiMiniClipboardDocumentList size={18} />
            </ListItemIcon>
            <ListItemText primary="Seniority List" />
          </ListItem>
        ) : null}

        {/* Transfers */}
        {transfer ? (
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: `/${adminUserName}/online-transfer`,
              });
            }}
          >
            <ListItemIcon>
              <BiTransfer size={18} />
            </ListItemIcon>
            <ListItemText primary="Transfer" />
          </ListItem>
        ) : null}

        {/* Transfer list */}
        {transferLIst ? (
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: `/${adminUserName}/transfer-list`,
              });
            }}
          >
            <ListItemIcon>
              <PiListChecksBold size={18} />
            </ListItemIcon>
            <ListItemText primary="Transfer List" />
          </ListItem>
        ) : null}

        {/* Vacancy Indicator */}
        {vacancyIndicator ? (
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: `/${adminUserName}/vacancy-indicator`,
              });
            }}
          >
            <ListItemIcon>
              <MdOutlineDragIndicator size={18} />
            </ListItemIcon>
            <ListItemText primary="Vacancy Indicator" />
          </ListItem>
        ) : null}

        {/* Control Panel */}
        {setting || designation || policeStation || users ? (
          <>
            <ListItem
              button
              onClick={() => {
                setUsedStates((prevValue) => {
                  return {
                    ...prevValue,
                    controlPanel: !controlPanel,
                  };
                });
              }}
            >
              <ListItemIcon>
                <AiFillControl size={18} />
              </ListItemIcon>
              <ListItemText primary="Control Panel" />
              {controlPanel ? <MdExpandLess /> : <MdExpandMore />}
            </ListItem>

            <Collapse in={controlPanel} timeout="auto" unmountOnExit>
              {/* Setting */}
              {setting ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/setting`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <AiFillSetting size={18} />
                    </ListItemIcon>
                    <ListItemText primary="Setting" />
                  </ListItem>
                </List>
              ) : null}

              {/* Designation */}
              {designation ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/designations`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <MdLocalPolice size={18} />
                    </ListItemIcon>
                    <ListItemText primary="Designation" />
                  </ListItem>
                </List>
              ) : null}

              {/* police Station */}
              {policeStation ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/police-stations`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <HiMiniBuildingOffice2 size={18} />
                    </ListItemIcon>
                    <ListItemText primary="Police Station" />
                  </ListItem>
                </List>
              ) : null}

              {/* Users */}
              {users ? (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: `/${adminUserName}/users`,
                      });

                      // close sidebar
                      props.closeDrawer();
                    }}
                  >
                    <ListItemIcon>
                      <FaUsersCog size={18} />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                </List>
              ) : null}
            </Collapse>
          </>
        ) : null}

        {/* Logout */}
        <ListItem
          button
          onClick={() => {
            logout();

            // close sidebar
            props.closeDrawer();
          }}
        >
          <ListItemIcon>
            <AiOutlinePoweroff size={18} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </section>
    </>
  );
};

export default SideBar;
