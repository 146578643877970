/*******************************  View  Proforma Page ********************************************* */
import {
  React,
  useEffect,
  useState,
  swal,
  axios,
  MaterialTable,
  Dialog,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  lodash,
  Button,
  InputLabel,
  NativeSelect,
  useCallback,
  XLSX,
  BeatLoader,
} from "../commonFiles/Import";

const ViewProforma = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,
    viewProfarmaDetails: [],

    transferYears: [],
    designations: [],

    searchDetails: {
      typeOfData: "",
      transferYear: "",
      designation: "",
      NKK_KK: "",
    },

    NKK_KK_Status: false,
  });

  /********************* Destructring Objects start ************************************/
  const {
    loading,
    viewProfarmaDetails,
    transferYears,
    designations,
    searchDetails,
    NKK_KK_Status,
  } = usedStates;

  const { typeOfData, transferYear, designation, NKK_KK } = searchDetails;

  /********************* Destructring Objects end ************************************/

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get transferYears
  const getTransferYears = useCallback(
    async ({ transferYear, typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getUserDetailsSR", {
          params: { transferYear, typeOfData, userId },
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            transferYears: lodash.uniqBy(result.data, "transferYear"),
            loading: false,
          };
        });
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  //get view Profarma Details
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // add user Id
      Object.assign(searchDetails, { userId });

      const result = await axios.get("/getViewProfarmaDetails", {
        params: searchDetails,
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            viewProfarmaDetails: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });
      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // view Profarma Columns
  const viewProfarmaColumns = [
    {
      title: typeOfData !== "P" ? "Seniority List No" : "Gradation No",
      field: typeOfData !== "P" ? "SnList" : "gradationNo",
    },

    {
      title: `${typeOfData !== "P" ? "Transfer Year" : "Promotion Year"}`,
      field: "Trn_No",
    },

    {
      title: "KGID No.",
      field: "EmpCode",
    },

    {
      title: "Full Name",
      field: "Full_Name",
    },

    {
      title: "Designation",
      field: "Cadre",
    },

    {
      title: "Present working place",
      field: "pres_Working_place",
    },

    {
      title: "Division",
      field: "Division",
    },

    {
      title: "NKK/KK",
      field: "NKK_KK",
    },
  ];

  // export all Data
  const exportAllData = async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });
      const result = await axios.get("/getProFormaDetails", {
        params: { transferYear, designation, typeOfData, userId },
      });

      // on success
      if (result.data) {
        //initialize variable
        let count = 0;

        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        const {
          proFormaDetails,
          postPerticularData,
          ACRGradingData,
          ExamData,
        } = result.data;

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert data to worksheet
        if (typeOfData === "P") {
          const proFormaWorkSheet = XLSX.utils.json_to_sheet(proFormaDetails);
          const postPerticularWorkSheet =
            XLSX.utils.json_to_sheet(postPerticularData);
          const ACRGradingWorkSheet = XLSX.utils.json_to_sheet(ACRGradingData);
          const ExamWorkSheet = XLSX.utils.json_to_sheet(ExamData);

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(
            workbook,
            proFormaWorkSheet,
            "ProformaDetails"
          );

          XLSX.utils.book_append_sheet(
            workbook,
            postPerticularWorkSheet,
            "PostPerticularData"
          );

          XLSX.utils.book_append_sheet(
            workbook,
            ACRGradingWorkSheet,
            "ACRGradingData"
          );

          XLSX.utils.book_append_sheet(
            workbook,
            ExamWorkSheet,
            "DepartmentExamData"
          );
        }

        // export only proforma detail for typeofData T
        else {
          // add one more column SR_No
          const newProformaDetails = proFormaDetails.map((data) => {
            return {
              SR_No: ++count,
              ...data,
            };
          });

          const proFormaWorkSheet =
            XLSX.utils.json_to_sheet(newProformaDetails);

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(
            workbook,
            proFormaWorkSheet,
            "ProformaDetails"
          );
        }

        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, `Proforma_List.xlsx`);
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });
      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle  input event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          [name]: value,
        },
      };
    });
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        let { settingStatusDetails } = result.data;

        // get data of setting status
        const { transferYear, designation, NKK_KK_Status, actionType } =
          settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            searchDetails: {
              ...prevValue.searchDetails,
              transferYear,
              designation,
              typeOfData: actionType,
            },
            NKK_KK_Status: NKK_KK_Status === "Y" ? true : false,

            loading: false,
          };
        });

        getTransferYears({ typeOfData: actionType, transferYear: "" });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, getTransferYears]);

  // handle Reset states
  const handleResetStates = ({ typeOfData }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loading: false,
        viewProfarmaDetails: [],
        searchDetails: {
          ...prevValue.searchDetails,
          typeOfData,
          NKK_KK: typeOfData === "All" ? "" : NKK_KK,
        },
      };
    });

    if (typeOfData === "All") {
      getSettingData();
    }
  };

  useEffect(() => {
    getDesignationDetails();
    getSettingData();
  }, [getSettingData, getDesignationDetails]);

  return (
    <>
      <section className="form-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="card border-0 mb-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-2">
                    <h1 className="fs-4 fw-bold">View Proforma</h1>
                  </div>

                  {/* Type of data */}
                  <div className="col-xl d-flex align-items-center">
                    <RadioGroup row value={typeOfData}>
                      {/* General Transfer */}
                      <FormControlLabel
                        value="G"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">General Transfer</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />

                      {/* Request Transfer */}
                      <FormControlLabel
                        className="ms-1"
                        value="T"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">Request Transfer</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />

                      {/* Promotion */}
                      <FormControlLabel
                        className="ms-1"
                        value="P"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">Promotion</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* generate Form */}
                  <div className="col-xl-12">
                    <form
                      method="POST"
                      autoComplete="Off"
                      onSubmit={handleSubmit}
                    >
                      <div className="row mb-3 g-3 mt-1 ">
                        {/* select transferYear */}
                        <div className="col-xl-3">
                          <InputLabel>
                            {typeOfData !== "P"
                              ? "Select transfer year*"
                              : "Select promotion year*"}
                          </InputLabel>
                          <NativeSelect
                            required
                            fullWidth
                            variant="standard"
                            inputProps={{
                              name: "transferYear",
                              id: "transferYear",
                            }}
                            onChange={(event) => {
                              handleInputEvent(event);
                            }}
                            value={transferYear}
                          >
                            {transferYears.map((data, index) => {
                              return (
                                <option key={index} value={data.transferYear}>
                                  {data.transferYear}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>

                        {/* select Designation */}
                        <div className="col-xl-3">
                          <InputLabel>Select Designation*</InputLabel>
                          <NativeSelect
                            required
                            fullWidth
                            variant="standard"
                            inputProps={{
                              name: "designation",
                              id: "designation",
                            }}
                            onChange={(event) => {
                              handleInputEvent(event);
                            }}
                            value={designation}
                          >
                            {designations.map((data, index) => {
                              return (
                                <option key={index} value={data.Cadre}>
                                  {data.Cadre}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>

                        {/*NKK_KK */}
                        {NKK_KK_Status ? (
                          <div className="col-xl-3 d-flex align-items-center ms-2">
                            <RadioGroup row value={NKK_KK}>
                              <FormControlLabel
                                value="NKK"
                                control={<Radio size="small" />}
                                label={<span className="ms-2">NKK</span>}
                                name="NKK_KK"
                                id="NKK_KK"
                                onChange={(event) => {
                                  handleInputEvent(event);
                                }}
                              />
                              <FormControlLabel
                                className="ms-1"
                                value="KK"
                                control={<Radio size="small" />}
                                label={<span className="ms-2">KK</span>}
                                name="NKK_KK"
                                id="NKK_KK"
                                onChange={(event) => {
                                  handleInputEvent(event);
                                }}
                              />
                            </RadioGroup>
                          </div>
                        ) : null}
                      </div>

                      <div className="buttons">
                        {/* submit button  */}
                        <Button
                          variant="outlined"
                          className="primary-button  shadow"
                          type="submit"
                        >
                          generate
                        </Button>

                        {/* reset button */}
                        <Button
                          type="button"
                          variant="outlined"
                          className="reset-button shadow ms-2"
                          onClick={() =>
                            handleResetStates({ typeOfData: "All" })
                          }
                        >
                          reset
                        </Button>
                      </div>
                    </form>
                  </div>
                  <div className="col-xl-12 mt-4">
                    <MaterialTable
                      id="profarmaList"
                      title={<h5 className="fw-bold">Proforma List</h5>}
                      columns={viewProfarmaColumns}
                      data={viewProfarmaDetails}
                      options={{
                        paging: false,
                        headerStyle: {
                          backgroundColor: "var(--glass-border)",
                          color: "black",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          position: "sticky",
                          zIndex: 100,
                          backdropFilter: "blur(100px)",
                        },
                        maxBodyHeight: 600,
                        rowStyle: {
                          whiteSpace: "nowrap",
                        },

                        exportMenu: [
                          {
                            label: "Export XLSX",
                            exportFunc: () => exportAllData(),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewProforma;
