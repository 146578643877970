/***************************************   inlcude all import files ********************************************************/
// inbuilt css
import "bootstrap/dist/css/bootstrap.min.css";

//custom css
import "../../dist/css/style.css";

//font-family
import "@fontsource/assistant";

import React, { useState, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  useHistory,
  useLocation,
  Redirect,
  Link,
} from "react-router-dom";
import { Switch, Route } from "react-router";
import {
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Autocomplete,
  InputAdornment,
  MenuItem,
  Select,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  NativeSelect,
  ListItemButton,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import swal from "sweetalert";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import lodash from "lodash";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Drawer } from "@mui/material";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import MaterialTable from "@material-table/core";
import { BeatLoader, FadeLoader } from "react-spinners";
import moment from "moment";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import Webcam from "react-webcam";
import * as XLSX from "xlsx";

//custom components
import App from "./App";
import Router from "../../config/Router";
import Login from "../Login";
import PageNotFound from "../PageNotFound";
import Welcome from "../Welcome";
import Header from "./Header";
import SideBar from "./SideBar";
import DashBoard from "./DashBoard";
import CommingSoon from "../CommingSoon";
import PotentialVacanacyList from "../PotentialVacanacyList";
import SeniorityList from "../SeniorityList";
import SearchTransfer from "../SearchTransfer";
import Setting from "../ControlPanel/Setting";
import SpecificTransfer from "../TransferType/SpecificTransfer";
import TransferList from "../TransferList";
import Acknowledgement from "../Acknowledgement";
import VacancyIndicator from "../VacancyIndicator";
import AdminTransfer from "../TransferType/AdminTransfer";
import CreateProforma from "../Proforma/CreateProforma";
import EditProforma from "../Proforma/EditProforma";
import ViewProforma from "../Proforma/ViewProforma";
import DivisionTransfer from "../TransferType/DivisionTransfer";
import Designation from "../ControlPanel/Designation";
import PoliceStation from "../ControlPanel/PoliceStation";
import User from "../ControlPanel/User";
import AdminUser from "../ControlPanel/AdminUser";

const clientSocket = null;

export {
  React,
  ReactDOM,
  BrowserRouter,
  App,
  Route,
  Router,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Radio,
  RadioGroup,
  LocalizationProvider,
  CreateProforma,
  Login,
  swal,
  LoadingButton,
  axios,
  useHistory,
  useState,
  useLocation,
  Redirect,
  AdapterDateFns,
  Autocomplete,
  InputAdornment,
  MenuItem,
  Select,
  useCallback,
  useEffect,
  FormLabel,
  lodash,
  DesktopDatePicker,
  Link,
  PageNotFound,
  Welcome,
  Header,
  Drawer,
  SideBar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  DashBoard,
  CommingSoon,
  Collapse,
  PotentialVacanacyList,
  ExportCsv,
  ExportPdf,
  SeniorityList,
  FormControl,
  InputLabel,
  MaterialTable,
  Dialog,
  DialogContent,
  BeatLoader,
  SearchTransfer,
  Setting,
  SpecificTransfer,
  moment,
  DialogTitle,
  Checkbox,
  TransferList,
  Acknowledgement,
  NativeSelect,
  Cookies,
  jwtDecode,
  clientSocket,
  FadeLoader,
  DivisionTransfer,
  VacancyIndicator,
  EditProforma,
  ViewProforma,
  AdminTransfer,
  Webcam,
  XLSX,
  Designation,
  PoliceStation,
  User,
  AdminUser,
};
