/******************************* transfers for Adminstartion  **********************************************/

import {
  React,
  TextField,
  Autocomplete,
  useState,
  swal,
  axios,
  useCallback,
  useEffect,
  Button,
  Dialog,
  DialogContent,
  BeatLoader,
  moment,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  DialogTitle,
  InputLabel,
  lodash,
  useHistory,
  useLocation,
} from "../commonFiles/Import";

import {
  GrLinkPrevious,
  GrLinkNext,
  AiOutlineClose,
} from "../commonFiles/Icons";

const AdminTransfer = () => {
  //get values from session
  const userId = window.sessionStorage.getItem("adminUserId");
  const NKK_session = window.sessionStorage.getItem("NKK_KK");
  const adminUserName = window.sessionStorage.getItem("adminUserName");
  const designationTo = window.sessionStorage.getItem("designationTo");

  const history = useHistory();

  // get current list of transfers and total transfers from websocket
  const location = useLocation();

  const [usedStates, setUsedStates] = useState({
    profarmaDetails: {
      loginProfile: "",
      typeOfData: window.sessionStorage.getItem("typeOfData"),
      noOfListIndex: 0,
      transferYear: window.sessionStorage.getItem("transferYear"),
      metalNo: "",
      empCode: "",
      snListNo: "",
      fullName: "",
      dateOfBirth: "",
      dateOfJoin: "",
      dateOfProbation: "",
      dateOfPromotion: "",
      dateOfPresentPlaceWork: "",
      gradationNo: "",
      anBn: "",
      designation: window.sessionStorage.getItem("designation"),
      workinPlace: "",
      natureOfDuty: "",
      selfNativeAddress: "",
      selfPresentAddress: "",
      selfNPS: "",
      spouseNativeAddress: "",
      spousePresentAddress: "",
      spouseNPS: "",
      placeWilling: [],
      postPerticularData: [],
      multiplePendingDESData: [],
      multipleEnquiryData: [],
      RecomFromName: "",
      RecomFromDesignation: "",
      RecomMade: "",
      dateRecieved: "",
      RecomPlaceRecomended: "",
      DateOfIDE: "",
      briefIDE: "",
      statusOfCase: "",
      enquiryFromOtheragency: "",
      punishmentAwards: "",
      typeOfpunishmentAwards: "awards",
      division: "",
      entryBy: "",
      checkedBy: "",
      remark: "",
      reasons: "",
      otherReason: "",
      ACRGradings: Array.from({ length: 5 }, (_, index) => ({
        passoutYear: "",
        remarks: "",
      })),
      departmentExamDetails: {
        isPassed: false,
        examsDetails: [
          {
            typeOfExam: "Kannada Language Exam",
            regNo: "",
            session: "",
            date: "",
            type: "", // it will be pass / Exemption
            sslcRegNo: "",
            sslcDate: "",
            agencyName: "",
            agencyRegNo: "",
            agencyDate: "",
            certificationNo: "",
            certificationDate: "",
          },
          { typeOfExam: "CLT Exam", regNo: "", session: "", date: "" },
          {
            typeOfExam: "General Law -1 & 2",
            regNo: "",
            session: "",
            date: "",
          },
          { typeOfExam: "Accounts Higher", regNo: "", session: "", date: "" },
        ],
      },
      KK_NON_KK: "",
      caste: "",
      FIT_UN_FIT: "",
    },

    eligibleListData: [],

    redFlagData: [],

    eligibleListoptions: {
      showByPotentialVacancy:
        window.sessionStorage.getItem("showBothVacancies") === "true"
          ? true
          : false,
      showByExistingVacancy: true,
    },

    transferDetails: {
      transferYear: window.sessionStorage.getItem("transferYear"),
      empCode: "",
      designation: window.sessionStorage.getItem("designation"),
      trasferFrom: "",
      fullName: "",
    },

    searchDetails: {
      empCode: "",
      stationName: "",
      divisionName: "",
      seniorityNo: "",
    },

    // list of transfers
    listOfTransfers: location.state.listOfTransfers,

    //totalTrasfers
    totalTransfers: location.state.totalTransfers,

    loading: false,

    notIntersted: false,

    undoModal: {
      open: false,
      searchOption: "empCode", // by default it' empCode
      empCodes: [],
      metalNos: [],
      undoDetails: {
        transferYear: "",
        empCode: "",
        designation: "",
        fullName: "",
        postCode: "",
        transferTo: "",
        transferFrom: "",
        metalNo: "",
      },
    },
  });

  /********************* Destructering objects start ********************************/
  const {
    profarmaDetails,
    eligibleListData,
    transferDetails,
    loading,
    listOfTransfers,
    searchDetails,
    eligibleListoptions,
    redFlagData,
    notIntersted,
    undoModal,
    totalTransfers,
  } = usedStates;

  const {
    typeOfData,
    noOfListIndex,
    transferYear,
    metalNo,
    empCode,
    snListNo,
    fullName,
    dateOfBirth,
    dateOfJoin,
    designation,
    workinPlace,
    natureOfDuty,
    selfNativeAddress,
    selfPresentAddress,
    selfNPS,
    spouseNativeAddress,
    spousePresentAddress,
    spouseNPS,
    placeWilling,
    postPerticularData,
    reasons,
    RecomFromName,
    RecomFromDesignation,
    RecomMade,
    dateRecieved,
    RecomPlaceRecomended,
    DateOfIDE,
    briefIDE,
    statusOfCase,
    punishmentAwards,
    entryBy,
    checkedBy,
    remark,
    anBn,
    dateOfProbation,
    dateOfPromotion,
    dateOfPresentPlaceWork,
    gradationNo,
    typeOfpunishmentAwards,
    enquiryFromOtheragency,
    division,
    ACRGradings,
    departmentExamDetails,
    KK_NON_KK,
    caste,
    FIT_UN_FIT,
    multipleEnquiryData,
    multiplePendingDESData,
  } = profarmaDetails;

  const { isPassed, examsDetails } = departmentExamDetails;

  const { showByExistingVacancy, showByPotentialVacancy } = eligibleListoptions;

  const { undoDetails } = undoModal;

  const { stationName, divisionName, seniorityNo } = searchDetails;

  /********************* Destructering objects end ********************************/

  // update Eligible Data
  const updateEligibleData = useCallback(
    ({ eligibleListData, redFlagData }) => {
      // add one more key value pair to differentiate vacancies is available or not
      if (eligibleListData.length > 0) {
        for (let i = 0; i < eligibleListData.length; i++) {
          //let intialize variable
          let potStatus = [],
            existStatus = [];
          const data = eligibleListData[i];

          // No of vacancies
          for (let index = 0; index < data.Nvac; index++) {
            potStatus.push({ status: "open" });
          }

          // posting vacancies
          for (let index = 0; index < data.exist_vac; index++) {
            existStatus.push({ status: "open" });
          }
          Object.assign(data, { potStatus, existStatus });
        }
      }

      // update data
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          eligibleListData,
        };
      });

      // update status of vacancy status based on match of redFlagdata and eligible list data
      if (redFlagData.length > 0) {
        //  intialize variable
        let remainingRedFlag = 0;

        eligibleListData.forEach((eligibleData) => {
          const matchedData = redFlagData.filter(
            (item) =>
              item.name.toLowerCase() === eligibleData.Name.toLowerCase()
          );

          if (matchedData.length > 0) {
            // first update pot status based on red flag
            if (showByPotentialVacancy) {
              if (eligibleData.potStatus.length > 0) {
                for (let index = 0; index < matchedData.length; index++) {
                  if (index >= eligibleData.potStatus.length) {
                    remainingRedFlag =
                      matchedData.length - eligibleData.potStatus.length;
                    break;
                  }
                  eligibleData.potStatus[index].status = "close";
                }
              }
            }

            // // update existing status
            if (showByExistingVacancy) {
              if (eligibleData.existStatus.length > 0) {
                if (remainingRedFlag > 0) {
                  for (let index = 0; index < remainingRedFlag; index++) {
                    eligibleData.existStatus[index].status = "close";
                  }

                  remainingRedFlag = 0;
                }

                if (eligibleData.potStatus.length === 0) {
                  for (let index = 0; index < matchedData.length; index++) {
                    if (index > eligibleData.existStatus.length - 1) {
                      break;
                    }

                    eligibleData.existStatus[index].status = "close";
                  }
                }
              }
            }
          }
        });
      }

      // update data
      setTimeout(() => {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            eligibleListData,
            redFlagData,
          };
        });
      }, 1000);
    },
    [showByExistingVacancy, showByPotentialVacancy]
  );

  // update profarma details
  const updateProfarmaDetails = useCallback(
    async (responseData) => {
      try {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        const { proFarmaDetails, eligibleListData, redFlagData } = responseData;

        const {
          FIT_UNFIT,
          ExamData,
          ACRGradingData,
          enquiryDesc,
          gradationNo,
          CAST,
          AnBn,
          BriefRecomendation1,
          Cadre,
          DateReceived1,
          Date_birth,
          Date_join,
          EmpCode,
          FromDesig1,
          FromName1,
          Full_Name,
          MetalNo,
          Nature_desi,
          PlacesRecomended1,
          Self_NP,
          Self_NPS,
          Self_Taluka,
          SnList,
          Spouse_NP,
          Spouse_NPS,
          Spouse_Taluka,
          checkBy,
          enteredBy,
          postPerticularData,
          multipleEnquiryData,
          multiplePendingDESData,
          pres_Working_place,
          remark,
          Punish_Awards,
          dateOfProbation,
          dateOfPromotion,
          dateOfPresentWork,
          NKK_KK,
          proFormaTRNData,
          Reasons,
          Division,
        } = proFarmaDetails;

        // update value to differentiate whether it's pass / exemption
        if (ExamData.length > 0 && typeOfData === "P") {
          ExamData[0]["type"] =
            ExamData[0].isPassed === "true"
              ? ExamData[0]?.regNo
                ? "Pass"
                : "Exemption"
              : "";

          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              profarmaDetails: {
                ...prevValue.profarmaDetails,
                departmentExamDetails: {
                  ...prevValue.profarmaDetails.departmentExamDetails,
                  isPassed: ExamData[0]
                    ? ExamData[0].isPassed === "false"
                      ? false
                      : true
                    : false,
                  examsDetails: ExamData,
                },
              },
            };
          });
        }

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            profarmaDetails: {
              ...prevValue.profarmaDetails,
              division: Division,
              placeWilling: proFormaTRNData,
              reasons: Reasons,
              DateOfIDE: multiplePendingDESData[0]?.DateofDE
                ? moment(multiplePendingDESData[0].DateofDE).format(
                    "DD/MM/YYYY"
                  )
                : "",
              briefIDE: multiplePendingDESData[0]?.briefIDE,
              statusOfCase: multiplePendingDESData[0]?.statusOfCase,
              ACRGradings: ACRGradingData,
              caste: CAST,
              enquiryFromOtheragency: enquiryDesc ? enquiryDesc : "",
              gradationNo: gradationNo.toString(),
              transferYear,
              metalNo: MetalNo,
              KK_NON_KK: NKK_KK,
              snListNo: SnList,
              fullName: Full_Name,
              anBn: AnBn,
              dateOfBirth: moment(Date_birth).format("DD/MM/YYYY"),
              dateOfJoin: moment(Date_join).format("DD/MM/YYYY"),
              dateOfProbation: moment(dateOfProbation).format("DD/MM/YYYY"),
              dateOfPromotion: dateOfPromotion
                ? moment(dateOfPromotion).format("DD/MM/YYYY")
                : "",
              designation: Cadre,
              workinPlace: pres_Working_place,
              dateOfPresentPlaceWork:
                moment(dateOfPresentWork).format("DD/MM/YYYY"),
              natureOfDuty: Nature_desi,
              selfPresentAddress: Self_NP,
              selfNativeAddress: Self_Taluka,
              selfNPS: Self_NPS,
              spousePresentAddress: Spouse_NP,
              spouseNativeAddress: Spouse_Taluka,
              spouseNPS: Spouse_NPS,
              postPerticularData,
              multipleEnquiryData,
              multiplePendingDESData,
              RecomFromName: FromName1,
              RecomFromDesignation: FromDesig1,
              RecomMade: BriefRecomendation1,
              dateRecieved: DateReceived1
                ? moment(DateReceived1).format("DD/MM/YYYY")
                : "",
              RecomPlaceRecomended: PlacesRecomended1,
              punishmentAwards: Punish_Awards,
              empCode: EmpCode.toString(),
              entryBy: enteredBy,
              checkedBy: checkBy,
              remark,
              typeOfData,
              FIT_UN_FIT: FIT_UNFIT,
            },

            searchDetails: {
              ...prevValue.searchDetails,
              empCode: EmpCode.toString(),
              seniorityNo: typeOfData !== "P" ? snListNo : gradationNo,
            },

            transferDetails: {
              ...prevValue.transferDetails,
              empCode: EmpCode.toString(),
              trasferFrom: pres_Working_place,
              fullName: Full_Name,
            },
          };
        });

        // update eligible data
        updateEligibleData({ eligibleListData, redFlagData });
      } catch (err) {
        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [snListNo, transferYear, typeOfData, updateEligibleData]
  );

  // get eligible List data based on option
  const getEligibleDataByOption = async ({
    empCode,
    transferYear,
    designation,
    currentWorkingPlace,
    selfNPS,
    spouseNPS,
    getByPotential,
    getByExisting,
    typeOfData,
  }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getEligibleDataByOption", {
        params: {
          empCode,
          transferYear,
          designation,
          currentWorkingPlace,
          selfNPS,
          spouseNPS,
          getByPotential,
          getByExisting,
          typeOfData,
          userId,
        },
      });

      // on success
      if (result.data) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        updateEligibleData({ eligibleListData: result.data, redFlagData });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle eligible event (checkbox)
  const handleEligibleEvent = (event) => {
    const { name, checked } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        eligibleListoptions: {
          ...prevValue.eligibleListoptions,
          [name]: checked,
        },
      };
    });
  };

  // handle transfer process
  const handleSubmit = async ({ placeName }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // update transfer To data
      Object.assign(transferDetails, {
        transferTo: placeName,
        typeOfData,
        userId,
      });

      const result = await axios.post(
        "/updateTransferDetails",
        transferDetails
      );

      // on success
      if (result.data.success) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // success message
        const successMessage =
          placeName === "Not interested" || placeName === "FOR GO"
            ? `${fullName} is not interested in the transfers.`
            : `${fullName} has been transfered from '${workinPlace}' to '${placeName}'`;

        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: successMessage,
        }).then(async (value) => {
          if (value) {
            // disable loading
            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                loading: false,
              };
            });

            // display message all transfer/promotion successfully done
            if (noOfListIndex === listOfTransfers.length - 1) {
              swal({
                title: "success",
                icon: "success",
                text: `All ${
                  typeOfData !== "P" ? `transfers` : `promotions`
                } are completed successfully.`,
              }).then(() => {
                history.replace(`/${adminUserName}/online-transfer`);
              });
            } else {
              const result = await axios.get("/getTransferListData", {
                params: {
                  transferYear,
                  designation,
                  typeOfData,
                  NKK_KK: NKK_session,
                  userId,
                },
              });

              // on success
              if (result.data) {
                const { transferListData } = result.data;

                setUsedStates((prevValue) => {
                  return {
                    ...prevValue,
                    listOfTransfers: lodash.uniqBy(transferListData, "EmpCode"),
                    loading: false,
                  };
                });

                const { EmpCode } = transferListData[noOfListIndex];

                // update data
                getProformaDetails({
                  transferYear,
                  empCode: EmpCode,
                });
              }
            }
          }
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // get undo Details
  const getUndoDetails = async ({
    transferYear,
    designation,
    empCode,
    metalNo,
    optionType,
  }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getUndoDetails", {
        params: {
          transferYear,
          designation,
          empCode,
          typeOfData,
          metalNo,
          userId,
        },
      });

      // on success
      if (result.data) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        if (result.data.length > 0) {
          // get all empCodes / metalNos
          if (optionType === "getAllData") {
            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                undoModal: {
                  ...prevValue.undoModal,
                  empCodes: result.data,
                  metalNos: result.data,
                },
              };
            });
          }

          // update undo details for specific empCode/metalNo
          if (optionType === "specificData") {
            const { Full_name, pstCode, Trs_To, Trs_From, empCode, MetalNo } =
              result.data[0];

            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                undoModal: {
                  ...prevValue.undoModal,
                  undoDetails: {
                    ...prevValue.undoModal.undoDetails,
                    fullName: Full_name,
                    postCode: pstCode,
                    transferTo: Trs_To,
                    transferFrom: Trs_From,
                    empCode,
                    metalNo: MetalNo,
                  },
                },
              };
            });
          }
        }

        // display message if no records found
        else {
          //close modal
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              undoModal: {
                ...prevValue.undoModal,
                open: false,
              },
            };
          });

          swal({ title: "Error", icon: "error", text: "No, record found. " });
        }
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle undo Input
  const handleUndoInput = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        undoModal: {
          ...prevValue.undoModal,
          searchOption: "empCode", // by default it's empCode
          open: true,
          undoDetails: {
            ...prevValue.undoModal.undoDetails,
            transferYear,
            designation,
          },
        },
      };
    });

    getUndoDetails({
      transferYear,
      designation,
      empCode: "",
      metalNo: "",
      optionType: "getAllData",
    });
  };

  // handle undo / update undo Details
  const handleUndo = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // add one more column typeOf Data in undoDetails
      Object.assign(undoDetails, { typeOfData, undoAll: "", userId });

      const result = await axios.patch("/updateUndoDetails", undoDetails);

      // on success
      if (result.data.success) {
        swal({
          title: "success",
          icon: "success",
          text: `The  ${
            typeOfData !== "P" ? `transfer` : `promotion`
          } is successfully reverted`,
          timer: "3000",
          buttons: false,
        }).then(async () => {
          const result = await axios.get("/getTransferListData", {
            params: {
              transferYear: transferYear,
              designation: designation,
              typeOfData,
              NKK_KK: NKK_session,
              userId,
            },
          });

          // on success
          if (result.data) {
            const { transferListData } = result.data;

            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                listOfTransfers: lodash.uniqBy(transferListData, "EmpCode"),
              };
            });

            const { Trn_No, EmpCode } = transferListData[noOfListIndex];

            // update data
            getProformaDetails({
              transferYear: Trn_No,
              empCode: EmpCode,
            });
          }

          handleResetUndoDetails({ optionType: "All" });
        });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  //reset undoDetails
  const handleResetUndoDetails = ({ optionType }) => {
    if (optionType === "All") {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          undoModal: {
            ...prevValue.undoModal,
            searchOption: "empCode", // by default it's empCode
            open: false,
            empCodes: [],
            undoDetails: {
              ...prevValue.undoModal.undoDetails,
              transferYear: "",
              empCode: "",
              designation: "",
              fullName: "",
              postCode: "",
              transferFrom: "",
              metalNo: "",
              transferTo: "",
            },
          },
        };
      });
    } else {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          undoModal: {
            ...prevValue.undoModal,
            undoDetails: {
              ...prevValue.undoModal.undoDetails,
              transferYear,
              designation,
              empCode: "",
              fullName: "",
              transferFrom: "",
              transferTo: "",
            },
          },
        };
      });
    }
  };

  // get data from scket
  const getProformaDetails = useCallback(
    async ({ transferYear, empCode }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getDataToTransfer", {
          params: {
            userId,
            empCode,
            transferYear,
            typeOfData,
            userRole: window.sessionStorage.getItem("adminRole"),
            showByPotentialVacancy,
            showByExistingVacancy,
          },
        });

        //on success
        if (result.data) {
          updateProfarmaDetails(result.data);
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [
      showByExistingVacancy,
      showByPotentialVacancy,
      typeOfData,
      updateProfarmaDetails,
      userId,
    ]
  );

  // get data of first employee
  useEffect(() => {
    const { EmpCode } = listOfTransfers[noOfListIndex];

    getProformaDetails({
      transferYear: window.sessionStorage.getItem("transferYear"),
      empCode: EmpCode,
    });
  }, [listOfTransfers, noOfListIndex, getProformaDetails]);

  return (
    <>
      {/* current Employee's info */}
      <section
        className="transfer-mini-info-section mb-4"
        style={{
          marginTop: "5rem",
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-end">
            <div className="col-xl">
              <div className="card border-0">
                <div className="card-header shadow fw-bold">
                  {" "}
                  Current Employee Info
                </div>
                <div className="card-body">
                  <div className="d-flex flex-row justify-content-between">
                    {/* transferYear */}
                    <div className="transferYear">
                      {typeOfData !== "P" ? "Transfer" : "Promotion"} Year :{" "}
                      <b>{transferYear}</b>{" "}
                    </div>

                    {/* emp Code */}
                    <div className="empCode ms-3">
                      {" "}
                      KGID No : <b>{empCode}</b>{" "}
                    </div>

                    {/* Full Name */}
                    <div className="fullName ms-3 fs-5">
                      {" "}
                      Full Name :{" "}
                      <b
                        style={{
                          color: "red",
                          fontWeight: "bolder",
                        }}
                      >
                        {fullName}
                      </b>{" "}
                    </div>

                    {/* designation */}
                    <div className="designation ms-3">
                      {" "}
                      Designation : <b>{designation}</b>
                    </div>

                    {/* working place */}
                    <div className="workingPlace ms-3">
                      {" "}
                      WorkingPlace : <b>{workinPlace}</b>
                    </div>

                    {/* Division */}
                    <div className="division ms-3">
                      {" "}
                      Division : <b>{division}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* transfer section */}
      <section className="transfer-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0 mb-4">
                <div className="card-body">
                  <div className="row">
                    {/* options */}
                    <div className="col-xl-9">
                      <div className="row g-2">
                        {/* buttons */}
                        <div className="col-xl-2 d-flex flex-row">
                          {/* previous button */}
                          <Button
                            disabled={noOfListIndex === 0 ? true : false}
                            variant="outlined"
                            onClick={() => {
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  profarmaDetails: {
                                    ...prevValue.profarmaDetails,
                                    noOfListIndex: noOfListIndex - 1,
                                  },
                                };
                              });

                              // get empCode / transferYear
                              const { Trn_No, EmpCode } =
                                listOfTransfers[noOfListIndex - 1];

                              getProformaDetails({
                                transferYear: Trn_No,
                                empCode: EmpCode,
                              });
                            }}
                          >
                            <GrLinkPrevious fontSize={20} />
                          </Button>

                          {/* next button */}
                          <Button
                            disabled={
                              noOfListIndex === listOfTransfers.length - 1
                                ? true
                                : false
                            }
                            className="ms-3"
                            variant="outlined"
                            onClick={() => {
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  profarmaDetails: {
                                    ...prevValue.profarmaDetails,
                                    noOfListIndex: noOfListIndex + 1,
                                  },
                                };
                              });

                              // get empCode / transferYear
                              const { Trn_No, EmpCode } =
                                listOfTransfers[noOfListIndex + 1];

                              getProformaDetails({
                                transferYear: Trn_No,
                                empCode: EmpCode,
                              });
                            }}
                          >
                            <GrLinkNext fontSize={20} />
                          </Button>
                        </div>

                        {/* search for transferDetails based on empCode */}
                        <div className="col-xl-2">
                          <Autocomplete
                            size="small"
                            disableClearable
                            forcePopupIcon={false}
                            onChange={(event, value) => {
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  searchDetails: {
                                    ...prevValue.searchDetails,
                                    empCode: value.EmpCode,
                                  },
                                  profarmaDetails: {
                                    ...prevValue.profarmaDetails,
                                    noOfListIndex: listOfTransfers.findIndex(
                                      (item) => item.EmpCode === value.EmpCode
                                    ),
                                  },
                                };
                              });

                              // get empCode and transferYear
                              const { Trn_No, EmpCode } =
                                listOfTransfers[
                                  listOfTransfers.findIndex(
                                    (item) => item.EmpCode === value.EmpCode
                                  )
                                ];

                              getProformaDetails({
                                transferYear: Trn_No,
                                empCode: EmpCode,
                              });
                            }}
                            inputValue={searchDetails.empCode.toString()}
                            options={listOfTransfers}
                            getOptionLabel={(options) =>
                              options.EmpCode.toString()
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label="Select KGID No."
                                onChange={(event) => {
                                  setUsedStates((prevValue) => {
                                    return {
                                      ...prevValue,
                                      searchDetails: {
                                        ...prevValue.searchDetails,
                                        empCode: event.target.value,
                                      },
                                    };
                                  });
                                }}
                              />
                            )}
                          />
                        </div>

                        {/* search for transferDetails based on seniorityNo */}
                        <div className="col-xl-3">
                          <Autocomplete
                            size="small"
                            disableClearable
                            forcePopupIcon={false}
                            onChange={(event, value) => {
                              setUsedStates((prevValue) => {
                                return {
                                  ...prevValue,
                                  searchDetails: {
                                    ...prevValue.searchDetails,
                                    empCode: value.EmpCode,
                                    seniorityNo:
                                      typeOfData !== "P"
                                        ? value.SnList
                                        : value.gradationNo,
                                  },
                                  profarmaDetails: {
                                    ...prevValue.profarmaDetails,
                                    noOfListIndex: listOfTransfers.findIndex(
                                      (item) => item.EmpCode === value.EmpCode
                                    ),
                                  },
                                };
                              });

                              // get empCode and transferYear
                              const { Trn_No, EmpCode } =
                                listOfTransfers[
                                  listOfTransfers.findIndex(
                                    (item) => item.EmpCode === value.EmpCode
                                  )
                                ];

                              getProformaDetails({
                                transferYear: Trn_No,
                                empCode: EmpCode,
                              });
                            }}
                            inputValue={seniorityNo.toString()}
                            options={listOfTransfers}
                            getOptionLabel={(options) =>
                              typeOfData !== "P"
                                ? options.SnList.toString()
                                : options.gradationNo.toString()
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label="Select SnList/Gradation No."
                                onChange={(event) => {
                                  setUsedStates((prevValue) => {
                                    return {
                                      ...prevValue,
                                      searchDetails: {
                                        ...prevValue.searchDetails,
                                        seniorityNo: event.target.value,
                                      },
                                    };
                                  });
                                }}
                              />
                            )}
                          />
                        </div>

                        {/* Not intersted */}
                        {typeOfData !== "G" ? (
                          <div className="col-xl-2 d-flex align-items-end">
                            {" "}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="notIntersted"
                                  id="notIntersted"
                                  onChange={(event) => {
                                    // update not intersted Value
                                    setUsedStates((prevValue) => {
                                      return {
                                        ...prevValue,
                                        notIntersted: event.target.checked,
                                      };
                                    });

                                    swal({
                                      title: "Are You Sure?",
                                      icon: "info",

                                      buttons: [true, "confirm"],
                                    }).then((value) => {
                                      if (value) {
                                        //update not intersted Value
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            notIntersted: false,
                                          };
                                        });

                                        handleSubmit({
                                          placeName:
                                            typeOfData === "T"
                                              ? "Not interested"
                                              : "FOR GO",
                                        });
                                      }

                                      // update not intersted Value
                                      else {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            notIntersted: false,
                                          };
                                        });
                                      }
                                    });
                                  }}
                                  value={notIntersted}
                                  checked={notIntersted}
                                />
                              }
                              label={
                                <span className="fs-bold">
                                  {" "}
                                  {typeOfData === "T"
                                    ? "Not interested"
                                    : "FOR GO"}
                                </span>
                              }
                            />
                          </div>
                        ) : null}

                        {/* Undo */}
                        <div className="col-xl d-flex align-items-center">
                          <Button
                            variant="outlined"
                            className="primary-button"
                            onClick={() => handleUndoInput()}
                          >
                            Undo
                          </Button>
                        </div>
                      </div>
                    </div>

                    {/* Total transfers */}
                    <div className="col-xl shadow d-flex align-items-center">
                      <h5 className="fw-bold">
                        Total {typeOfData !== "P" ? "Transfers" : "Promotions"}{" "}
                        : {redFlagData.length}/{totalTransfers.length}
                      </h5>
                    </div>

                    <div className="row g-2">
                      {/* transfer Details */}
                      <div className="col-xl-6">
                        <div className="card h-100">
                          <div className="card-header shadow">
                            <div className="fs-5 fw-bold">
                              Employee Proforma
                            </div>
                          </div>
                          <div className="card-body">
                            <div
                              className="row g-2"
                              style={{
                                overflowY: "scroll",
                                maxHeight: "500px",
                              }}
                            >
                              <div className="row g-1">
                                {/* Name and metal Number */}
                                {typeOfData !== "P" ? (
                                  <div className="col-md-12">
                                    <div className="card h-100 ">
                                      <div className="card-header fw-bold ">
                                        Name and Metal No
                                      </div>
                                      <div className="card-body pe-none">
                                        <div className="row g-2">
                                          {/* Transfer Year */}
                                          <div className="col-sm-2 pe-none">
                                            <TextField
                                              label="Transfer year"
                                              id="transferYear"
                                              name="transferYear"
                                              variant="standard"
                                              fullWidth
                                              value={transferYear}
                                            />
                                          </div>

                                          {/* Metal Number */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="Metal number(Only for CHC and CPC)"
                                              id="metalNo"
                                              name="metalNo"
                                              variant="standard"
                                              fullWidth
                                              value={metalNo}
                                            />
                                          </div>

                                          {/* NKK OR KK */}
                                          <div className="col-sm-4 d-flex flex-column justfy-content-start">
                                            <RadioGroup
                                              row
                                              value={KK_NON_KK}
                                              className="ms-3"
                                            >
                                              <FormControlLabel
                                                value="NKK"
                                                control={<Radio size="small" />}
                                                label="NKK"
                                                name="KK_NON_KK"
                                                id="KK_NON_KK"
                                              />
                                              <FormControlLabel
                                                value="KK"
                                                control={<Radio size="small" />}
                                                label="KK"
                                                name="KK_NON_KK"
                                                id="KK_NON_KK"
                                              />
                                            </RadioGroup>
                                          </div>

                                          {/* Employee Code */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="KGID No"
                                              id="empCode"
                                              name="empCode"
                                              variant="standard"
                                              fullWidth
                                              value={empCode}
                                            />
                                          </div>

                                          {/* SNList No */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="Sn.List No"
                                              id="snListNo"
                                              name="snListNo"
                                              variant="standard"
                                              fullWidth
                                              value={snListNo}
                                            />
                                          </div>

                                          {/* Full Name */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="Full Name( as per SR)"
                                              id="fullName"
                                              name="fullName"
                                              variant="standard"
                                              fullWidth
                                              value={fullName}
                                            />
                                          </div>

                                          {/* Date of birth */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="DOB"
                                              id="dateOfBirth"
                                              name="dateOfBirth"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfBirth}
                                            />
                                          </div>

                                          {/* Date of entry into service */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="DOA"
                                              id="dateOfJoin"
                                              name="dateOfJoin"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfJoin}
                                            />
                                          </div>

                                          {/* AN and BN */}
                                          <div className="col-sm-6 d-flex flex-column">
                                            <RadioGroup
                                              row
                                              className="ms-3"
                                              value={anBn}
                                            >
                                              <FormControlLabel
                                                value="An"
                                                control={<Radio size="small" />}
                                                label="An"
                                                name="anBn"
                                                id="anBn"
                                              />
                                              <FormControlLabel
                                                value="Bn"
                                                control={<Radio size="small" />}
                                                label="Bn"
                                                name="anBn"
                                                id="anBn"
                                              />
                                            </RadioGroup>
                                          </div>

                                          {/* Date of Probationary declared  */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="Date of Probationary declared"
                                              id="dateOfProbation"
                                              name="dateOfProbation"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfProbation}
                                            />
                                          </div>

                                          {/* Date of Promotion as CHC/ASI/PSI  */}
                                          <div className="col-sm-6">
                                            <TextField
                                              label="Date of Promotion as CHC/ASI/PSI"
                                              id="dateOfPromotion"
                                              name="dateOfPromotion"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfPromotion}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-xl-12">
                                    <div className="card h-100 ">
                                      <div className="card-header fw-bold ">
                                        Name and Metal No
                                      </div>
                                      <div className="card-body">
                                        <div className="row g-2">
                                          {/* Promotion year*/}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="Promotion year"
                                              id="transferYear"
                                              name="transferYear"
                                              variant="standard"
                                              fullWidth
                                              value={transferYear}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* Employee Code */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="KGID No"
                                              id="empCode"
                                              name="empCode"
                                              variant="standard"
                                              fullWidth
                                              value={empCode}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* NKK OR KK */}
                                          <div className="col-xl-6 d-flex flex-column justfy-content-start">
                                            <RadioGroup row value={KK_NON_KK}>
                                              <FormControlLabel
                                                value="NKK"
                                                control={<Radio size="small" />}
                                                label="NKK"
                                                name="KK_NON_KK"
                                                id="KK_NON_KK"
                                                className="pe-none"
                                              />
                                              <FormControlLabel
                                                value="KK"
                                                control={<Radio size="small" />}
                                                label="KK"
                                                name="KK_NON_KK"
                                                id="KK_NON_KK"
                                                className="pe-none"
                                              />
                                            </RadioGroup>
                                          </div>

                                          {/* Full Name */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="Full Name( as per SR)"
                                              id="fullName"
                                              name="fullName"
                                              variant="standard"
                                              fullWidth
                                              value={fullName}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* Metal Number */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="Metal number(Only for CHC and CPC)"
                                              id="metalNo"
                                              name="metalNo"
                                              variant="standard"
                                              fullWidth
                                              value={metalNo}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* Date of birth */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="DOB"
                                              id="dateOfBirth"
                                              name="dateOfBirth"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfBirth}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* Date of entry into service */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="DOA"
                                              id="dateOfJoin"
                                              name="dateOfJoin"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfJoin}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* AN and BN */}
                                          <div className="col-xl-6 d-flex  flex-column">
                                            <RadioGroup row value={anBn}>
                                              <FormControlLabel
                                                value="An"
                                                control={<Radio size="small" />}
                                                label="An"
                                                name="anBn"
                                                id="anBn"
                                                className="pe-none"
                                              />
                                              <FormControlLabel
                                                value="Bn"
                                                control={<Radio size="small" />}
                                                label="Bn"
                                                name="anBn"
                                                id="anBn"
                                                className="pe-none"
                                              />
                                            </RadioGroup>
                                          </div>

                                          {/* Date of Probationary declared  */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="Date of Probationary declared"
                                              id="dateOfProbation"
                                              name="dateOfProbation"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfProbation}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* Date of Promotion as CHC/ASI/PSI  */}
                                          <div className="col-xl-6">
                                            <TextField
                                              label="Date of Promotion as CHC/ASI/PSI"
                                              id="dateOfPromotion"
                                              name="dateOfPromotion"
                                              variant="standard"
                                              fullWidth
                                              value={dateOfPromotion}
                                              className="pe-none"
                                            />
                                          </div>

                                          {/* caste */}
                                          <div className="col-xl-12">
                                            <TextField
                                              label="Caste"
                                              id="caste"
                                              name="caste"
                                              variant="standard"
                                              fullWidth
                                              value={caste}
                                              className="pe-none"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {/* Present Designation and working place */}
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Present Designation and working place
                                    </div>
                                    <div className="card-body pe-none">
                                      <div className="row g-2">
                                        {/* Rank */}
                                        <div className="col-xl-12">
                                          <TextField
                                            label="Designation"
                                            id="designation"
                                            name="designation"
                                            variant="standard"
                                            fullWidth
                                            value={designation}
                                          />
                                        </div>

                                        {/* Present Place of working */}
                                        <div className="col-xl-6">
                                          <TextField
                                            label="Present Place of working"
                                            id="workinPlace"
                                            name="workinPlace"
                                            variant="standard"
                                            fullWidth
                                            value={workinPlace}
                                          />
                                        </div>

                                        {/* Division */}
                                        <div className="col-xl-6">
                                          <TextField
                                            variant="standard"
                                            fullWidth
                                            label="Division"
                                            id="division"
                                            name="division"
                                            className="mt-2"
                                            value={division}
                                          />
                                        </div>

                                        {/* Present place of working date  */}
                                        <div
                                          className={
                                            typeOfData !== "P"
                                              ? "col-sm-12"
                                              : "col-sm-6"
                                          }
                                        >
                                          <TextField
                                            label="Present place of working date"
                                            id="dateOfPresentPlaceWork"
                                            name="dateOfPresentPlaceWork"
                                            variant="standard"
                                            fullWidth
                                            value={dateOfPresentPlaceWork}
                                          />
                                        </div>

                                        {/*Gradation Number  */}
                                        <div
                                          className={
                                            typeOfData !== "P"
                                              ? "d-none"
                                              : "col-sm-6"
                                          }
                                        >
                                          <TextField
                                            label="Gradation Number"
                                            id="gradationNo"
                                            name="gradationNo"
                                            variant="standard"
                                            fullWidth
                                            value={gradationNo}
                                          />
                                        </div>

                                        {/* Nature of Duty */}
                                        <div className="col-sm-12">
                                          <TextField
                                            label="Nature of Duty"
                                            id="natureOfDuty"
                                            name="natureOfDuty"
                                            variant="standard"
                                            fullWidth
                                            value={natureOfDuty}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row g-1 mt-1">
                                {/* self native place Details */}
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Self native place details
                                    </div>
                                    <div className="card-body">
                                      <div className="row g-2">
                                        {/* Present Address */}
                                        <div className="col-xl-12">
                                          <TextField
                                            name="selfPresentAddress"
                                            id="selfPresentAddress"
                                            label="Present Address"
                                            fullWidth
                                            variant="standard"
                                            value={selfPresentAddress}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Native Address */}
                                        <div className="col-xl-12">
                                          <TextField
                                            name="selfNativeAddress"
                                            id="selfNativeAddress"
                                            label="Native Address"
                                            fullWidth
                                            variant="standard"
                                            value={selfNativeAddress}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Jurisdiction Police station */}
                                        <div className="col-xl-12">
                                          <TextField
                                            label="Jurisdiction Police station"
                                            id="selfNPS"
                                            name="selfNPS"
                                            variant="standard"
                                            fullWidth
                                            value={selfNPS}
                                            className="pe-none"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* spouse native place details */}
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Spouse native place details
                                    </div>
                                    <div className="card-body">
                                      <div className="row g-2">
                                        {/* Present Address  */}
                                        <div className="col-xl-12">
                                          <TextField
                                            name="spousePresentAddress"
                                            id="spousePresentAddress"
                                            label="Present Address "
                                            fullWidth
                                            variant="standard"
                                            value={spousePresentAddress}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Native address */}
                                        <div className="col-xl-12">
                                          <TextField
                                            name="spouseNativeAddress"
                                            id="spouseNativeAddress"
                                            label="Native Address"
                                            fullWidth
                                            variant="standard"
                                            value={spouseNativeAddress}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Jurisdiction Police station */}
                                        <div className="col-xl-12">
                                          <TextField
                                            label="Jurisdiction Police station"
                                            id="spouseNPS"
                                            name="spouseNPS"
                                            variant="standard"
                                            fullWidth
                                            value={spouseNPS}
                                            className="pe-none"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Places willing to go */}
                                {typeOfData !== "P" ? (
                                  <div className="col-xl-12">
                                    <div className="card h-100">
                                      <div className="card-header fw-bold">
                                        Places willing to go (Maximum 3 options)
                                      </div>
                                      <div className="card-body">
                                        <div className="row g-2">
                                          {/* Police station */}
                                          <div className="col-xl-12 mt-3">
                                            {placeWilling.map((data, index) => {
                                              return (
                                                <ul key={index}>
                                                  <li>{data.Name}</li>
                                                </ul>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>

                              {/* Previous Place of working with date/ tenure */}
                              <div className="row g-1 mt-1 ">
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Previous Place of working with date/
                                      tenure
                                    </div>
                                    <div className="card-body">
                                      <div className="row g-2">
                                        <div className="col-xl-12 postPerticularData">
                                          <table className="table table-bordered table-sm">
                                            <thead className="table-dark">
                                              <tr className="fw-bold">
                                                <th
                                                  scope="col"
                                                  className="text-start"
                                                >
                                                  Police Station
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="text-start"
                                                >
                                                  From
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="text-start"
                                                >
                                                  To
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="text-start"
                                                >
                                                  OOD or Trans or Deputation
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="text-start"
                                                >
                                                  Executive or Non-Executive
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {postPerticularData.map(
                                                (data, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      {/* police station */}
                                                      <td className="text-start">
                                                        <TextField
                                                          id="workedPlace"
                                                          name="workedPlace"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            postPerticularData[
                                                              index
                                                            ].workedPlace
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </td>

                                                      {/* From */}
                                                      <td className="text-start">
                                                        <TextField
                                                          id="fromDate"
                                                          name="fromDate"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            postPerticularData[
                                                              index
                                                            ].fromDate
                                                              ? moment(
                                                                  postPerticularData[
                                                                    index
                                                                  ].fromDate
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                              : ""
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </td>

                                                      {/* To */}
                                                      <td className="text-start">
                                                        <TextField
                                                          id="toDate"
                                                          name="toDate"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            postPerticularData[
                                                              index
                                                            ].toDate
                                                              ? moment(
                                                                  postPerticularData[
                                                                    index
                                                                  ].toDate
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                              : ""
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </td>

                                                      {/* OOD or Trans or Deputation */}
                                                      <td className="text-start">
                                                        <TextField
                                                          id="ODTransfers"
                                                          name="ODTransfers"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            postPerticularData[
                                                              index
                                                            ].ODTransfers
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </td>

                                                      {/*	Executive or Non-Executive */}
                                                      <td className="text-start">
                                                        <TextField
                                                          id="execNoExec"
                                                          name="execNoExec"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            postPerticularData[
                                                              index
                                                            ].execNoExec
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Reasons for transfer request */}
                              {typeOfData !== "P" ? (
                                <div className="row g-1 mt-1">
                                  <div className="col-xl-12">
                                    <div className="card h-100">
                                      <div className="card-header fw-bold">
                                        Reasons for transfer request
                                      </div>
                                      <div className="card-body">
                                        <div className="row g-2">
                                          {/* Reasons */}
                                          <div className="col-xl-12">
                                            <TextField
                                              label="Reason"
                                              id="reasons"
                                              name="reasons"
                                              variant="standard"
                                              fullWidth
                                              value={reasons}
                                              className="pe-none"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="row g-1 mt-1">
                                {/* DE Details of applicant (from the date of Appointment ) */}
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      DE Details of applicant (from the date of
                                      Appointment)
                                    </div>
                                    <div className="card-body pe-nong">
                                      {typeOfData === "P" ? (
                                        <div className="row g-2">
                                          {/* Date of IDE */}
                                          <div className="col-xl-12">
                                            <TextField
                                              label="Date of DE"
                                              id="DateOfIDE"
                                              name="DateOfIDE"
                                              variant="standard"
                                              fullWidth
                                              value={DateOfIDE}
                                            />
                                          </div>

                                          {/* Brief Facts of the DE */}
                                          <div className="col-xl-12">
                                            <TextField
                                              fullWidth
                                              multiline
                                              rows={4}
                                              variant="standard"
                                              label="Brief Facts of the DE"
                                              name="briefIDE"
                                              id="briefIDE"
                                              value={briefIDE}
                                            />
                                          </div>

                                          {/* Present Status of the case */}
                                          <div className="col-xl-12">
                                            <TextField
                                              fullWidth
                                              variant="standard"
                                              label="Present Status of the case"
                                              id="statusOfCase"
                                              name="statusOfCase"
                                              value={statusOfCase}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="row g-2">
                                          <div className="col-xl-12 postPerticularData">
                                            <table className="table table-bordered table-sm">
                                              <thead className="table-dark">
                                                <tr className="fw-bold">
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    DE Running (Yes/No)*
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    DE Punishment (Yes/No)*
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Date of DE
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Brief Facts of the DE
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Present Status of the case
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    DE Punishment Details
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {multiplePendingDESData.map(
                                                  (data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        {/* Running DE */}
                                                        <td className="text-start">
                                                          <TextField
                                                            id="runningDE"
                                                            name="runningDE"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multiplePendingDESData[
                                                                index
                                                              ].runningDE
                                                            }
                                                          />
                                                        </td>

                                                        {/* Punishment DE */}
                                                        <td className="text-start">
                                                          <TextField
                                                            id="punishmentDE"
                                                            name="punishmentDE"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multiplePendingDESData[
                                                                index
                                                              ].punishmentDE
                                                            }
                                                          />
                                                        </td>
                                                        {/* Date of IDE */}
                                                        <td className="text-start w-25">
                                                          <TextField
                                                            id="DateofDE"
                                                            name="DateofDE"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multiplePendingDESData[
                                                                index
                                                              ].DateofDE
                                                            }
                                                          />
                                                        </td>

                                                        {/* Brief Facts of the DE */}
                                                        <td className="text-start">
                                                          <TextField
                                                            id="briefIDE"
                                                            name="briefIDE"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multiplePendingDESData[
                                                                index
                                                              ].briefIDE
                                                            }
                                                          />
                                                        </td>

                                                        {/* Present Status of the case */}
                                                        <td className="text-start">
                                                          <TextField
                                                            id="statusOfCase"
                                                            name="statusOfCase"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multiplePendingDESData[
                                                                index
                                                              ].statusOfCase
                                                            }
                                                          />
                                                        </td>

                                                        {/* DE Punishment Details */}
                                                        <td className="text-start">
                                                          {" "}
                                                          <TextField
                                                            id="punishmentData"
                                                            name="punishmentData"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multiplePendingDESData[
                                                                index
                                                              ].punishmentData
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* Enquiry from other Agencies (Lokayukta/ Court ) */}
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Enquiry from other Agencies (Lokayukta/
                                      Court )
                                    </div>
                                    <div className="card-body pe-none">
                                      {typeOfData === "P" ? (
                                        <div className="row g-2">
                                          {/* Enquiry from other Agencies (Lokayukta/ Court ) */}
                                          <div className="col-xl-12">
                                            <TextField
                                              fullWidth
                                              variant="standard"
                                              label="Enquiry from other Agencies (Lokayukta/ Court )"
                                              id="enquiryFromOtheragency"
                                              name="enquiryFromOtheragency"
                                              value={enquiryFromOtheragency}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="row g-2">
                                          <div className="col-xl-12 postPerticularData">
                                            <table className="table table-bordered table-sm">
                                              <thead className="table-dark">
                                                <tr className="fw-bold">
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Date of Enquiry
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Brief Facts of Enquiry
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Present Status of the case
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Enquiry Punishment Details
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {multipleEnquiryData.map(
                                                  (data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        {/* Date of Enquiry */}
                                                        <td className="text-start w-25">
                                                          <TextField
                                                            id="DateOfEnquiry"
                                                            name="DateOfEnquiry"
                                                            variant="standard"
                                                            fullWidth
                                                            value={moment(
                                                              multipleEnquiryData[
                                                                index
                                                              ].DateOfEnquiry
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          />
                                                        </td>

                                                        {/* Brief Facts of the Enquiry */}
                                                        <td className="text-start">
                                                          <TextField
                                                            id="briefEnquiry"
                                                            name="briefEnquiry"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multipleEnquiryData[
                                                                index
                                                              ].briefEnquiry
                                                            }
                                                          />
                                                        </td>

                                                        {/* Present Status of the case */}
                                                        <td className="text-start">
                                                          <TextField
                                                            id="statusOfCase"
                                                            name="statusOfCase"
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              multipleEnquiryData[
                                                                index
                                                              ].statusOfCase
                                                            }
                                                          />
                                                        </td>

                                                        {/* DE Punishment Details */}
                                                        <td className="text-start">
                                                          <TextField
                                                            fullWidth
                                                            variant="standard"
                                                            id="punishmentData"
                                                            name="punishmentData"
                                                            value={
                                                              multipleEnquiryData[
                                                                index
                                                              ].punishmentData
                                                            }
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* Punishments/Awards */}
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      {typeOfData !== "P"
                                        ? "Awards"
                                        : " Punishments/Awards"}
                                    </div>
                                    <div className="card-body pe-none">
                                      <div className="row g-2">
                                        {/* Punishments/Awards */}
                                        <div className="col-xl-12">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            label={
                                              typeOfData !== "P"
                                                ? "Awards"
                                                : "Punishments/Awards"
                                            }
                                            id="punishmentAwards"
                                            name="punishmentAwards"
                                            value={punishmentAwards}
                                          />
                                        </div>

                                        {/* Punishment/Awards */}
                                        {typeOfData === "P" ? (
                                          <div className="col-xl d-flex flex-column">
                                            <RadioGroup
                                              row
                                              className="ms-3"
                                              value={typeOfpunishmentAwards}
                                            >
                                              <FormControlLabel
                                                value="awards"
                                                control={<Radio size="small" />}
                                                label="Awards"
                                                name="typeOfpunishmentAwards"
                                                id="typeOfpunishmentAwards"
                                              />

                                              <FormControlLabel
                                                value="punishment"
                                                control={<Radio size="small" />}
                                                label="Punishments"
                                                name="typeOfpunishmentAwards"
                                                id="typeOfpunishmentAwards"
                                              />
                                            </RadioGroup>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {typeOfData === "P" ? (
                                  <div className="col-xl-12">
                                    <div className="card h-100">
                                      <div className="card-header fw-bold">
                                        ACR Gradings (Last 5 years)
                                      </div>
                                      <div className="card-body">
                                        <div className="row g-2">
                                          <div className="col-xl-12 postPerticularData">
                                            <table className="table table-bordered table-sm">
                                              <thead className="table-dark">
                                                <tr className="fw-bold">
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Pass out - year
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start"
                                                  >
                                                    Remarks
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {ACRGradings.map(
                                                  (data, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        {/* pass out  Year */}
                                                        <td className="text-start">
                                                          <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              ACRGradings[index]
                                                                .passoutYear
                                                            }
                                                            className="pe-none"
                                                          />
                                                        </td>

                                                        {/* Remarks */}
                                                        <td className="text-start">
                                                          <TextField
                                                            variant="standard"
                                                            fullWidth
                                                            value={
                                                              ACRGradings[index]
                                                                .remarks
                                                            }
                                                            className="pe-none"
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {typeOfData === "P" ? (
                                  <>
                                    <div className="col-xl-12">
                                      <div className="card h-100">
                                        <div className="card-header fw-bold">
                                          Department Exam Details
                                        </div>
                                        <div className="card-body">
                                          <div className="row g-1">
                                            <div className="col-xl-12 d-flex flex-row align-items-center ">
                                              <InputLabel className="fw-bold">
                                                {" "}
                                                Departmental Exam Passed ?{" "}
                                              </InputLabel>
                                              <RadioGroup
                                                row
                                                value={isPassed}
                                                className=" ms-3 pe-none"
                                              >
                                                <FormControlLabel
                                                  className="pe-none"
                                                  value={true}
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  label="Yes"
                                                  name="isPassed"
                                                  id="isPassed"
                                                />

                                                <FormControlLabel
                                                  className="pe-none"
                                                  value={false}
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  label="No"
                                                  name="isPassed"
                                                  id="isPassed"
                                                />
                                              </RadioGroup>
                                            </div>

                                            {/* enter exam Details */}
                                            {isPassed ? (
                                              <>
                                                <div className="row g-4 mt-2">
                                                  {/* Kannada Language Exam */}
                                                  <div className="col-xl-12">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        {" "}
                                                        Kannada Language Exam
                                                      </div>
                                                      <div className="card-body">
                                                        <div className="row">
                                                          {/* options */}
                                                          <div className="col-xl-12">
                                                            <RadioGroup
                                                              className="pe-none"
                                                              row
                                                              value={
                                                                examsDetails[0]
                                                                  .type
                                                              }
                                                            >
                                                              <FormControlLabel
                                                                className="pe-none"
                                                                value="Pass"
                                                                control={
                                                                  <Radio size="small" />
                                                                }
                                                                label="Pass"
                                                                name="type"
                                                                id="type"
                                                              />
                                                              <FormControlLabel
                                                                className="pe-none"
                                                                value="Exemption"
                                                                control={
                                                                  <Radio size="small" />
                                                                }
                                                                label="Exemption"
                                                                name="type"
                                                                id="type"
                                                              />
                                                            </RadioGroup>
                                                          </div>

                                                          {/* kannada langauge exam details */}
                                                          {examsDetails[0]
                                                            .type === "Pass" ? (
                                                            <>
                                                              {/* Exam Details */}
                                                              <div className="col-xl-12 mt-2">
                                                                <div className="card">
                                                                  <div className="card-header fw-bold ">
                                                                    Exam Details
                                                                  </div>
                                                                  <div className="card-body">
                                                                    <div className="row g-2">
                                                                      {/* Register No */}
                                                                      <div className="col-xl-12">
                                                                        <TextField
                                                                          className="pe-none"
                                                                          label="Reg No."
                                                                          fullWidth
                                                                          variant="standard"
                                                                          value={
                                                                            examsDetails[0]
                                                                              .regNo
                                                                          }
                                                                        />
                                                                      </div>

                                                                      {/* Session */}
                                                                      <div className="col-xl-12">
                                                                        <TextField
                                                                          className="pe-none"
                                                                          label="Session"
                                                                          fullWidth
                                                                          variant="standard"
                                                                          value={
                                                                            examsDetails[0]
                                                                              .session
                                                                          }
                                                                        />
                                                                      </div>

                                                                      {/* Date */}
                                                                      <div className="col-xl=12">
                                                                        <TextField
                                                                          className="pe-none"
                                                                          label="Date"
                                                                          id="date"
                                                                          name="date"
                                                                          variant="standard"
                                                                          fullWidth
                                                                          value={
                                                                            examsDetails[0]
                                                                              .date
                                                                              ? moment(
                                                                                  examsDetails[0]
                                                                                    .date
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )
                                                                              : ""
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              {/* Certificate Details */}
                                                              <div className="col-xl-12 mt-2">
                                                                <div className="card">
                                                                  <div className="card-header fw-bold">
                                                                    Certificate
                                                                    Details
                                                                  </div>
                                                                  <div className="card-body">
                                                                    <div className="row g-2">
                                                                      {/* Certificate No */}
                                                                      <div className="col-xl-12">
                                                                        <TextField
                                                                          label="Certificate No."
                                                                          fullWidth
                                                                          variant="standard"
                                                                          value={
                                                                            examsDetails[0]
                                                                              .certificationNo
                                                                          }
                                                                          className="pe-none"
                                                                        />
                                                                      </div>

                                                                      {/* Certification Date */}
                                                                      <div className="col-xl-12">
                                                                        <TextField
                                                                          label="Certification Date"
                                                                          id="certificationDate"
                                                                          name="certificationDate"
                                                                          variant="standard"
                                                                          fullWidth
                                                                          value={
                                                                            examsDetails[0]
                                                                              .certificationDate
                                                                              ? moment(
                                                                                  examsDetails[0]
                                                                                    .certificationDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )
                                                                              : ""
                                                                          }
                                                                          className="pe-none"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : null}

                                                          {/* exemption Details */}
                                                          {examsDetails[0]
                                                            .type ===
                                                          "Exemption" ? (
                                                            <div className="col-xl-12 mt-2">
                                                              <div className="row g-1">
                                                                {/* SSLC Details */}
                                                                <div className="col-xl-12">
                                                                  <div className="card">
                                                                    <div className="card-header fw-bold">
                                                                      SSLC
                                                                      Details
                                                                    </div>
                                                                    <div className="card-body">
                                                                      <div className="row g-2">
                                                                        {/* Register No */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Reg No."
                                                                            fullWidth
                                                                            variant="standard"
                                                                            value={
                                                                              examsDetails[0]
                                                                                .sslcRegNo
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>

                                                                        {/* Date */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Date"
                                                                            id="sslcDate"
                                                                            name="sslcDate"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            value={
                                                                              examsDetails[0]
                                                                                .sslcDate
                                                                                ? moment(
                                                                                    examsDetails[0]
                                                                                      .sslcDate
                                                                                  ).format(
                                                                                    "DD/MM/YYYY"
                                                                                  )
                                                                                : ""
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                                {/* Agency Details */}
                                                                <div className="col-xl-12">
                                                                  <div className="card">
                                                                    <div className="card-header fw-bold">
                                                                      Other
                                                                      Agencies
                                                                    </div>
                                                                    <div className="card-body">
                                                                      <div className="row g-2">
                                                                        {/* Agency Name */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Agency Name"
                                                                            fullWidth
                                                                            variant="standard"
                                                                            value={
                                                                              examsDetails[0]
                                                                                .agencyName
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>

                                                                        {/* Register No */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Reg No."
                                                                            fullWidth
                                                                            variant="standard"
                                                                            value={
                                                                              examsDetails[0]
                                                                                .agencyRegNo
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>

                                                                        {/* Date */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Date"
                                                                            id="agencyDate"
                                                                            name="agencyDate"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            value={
                                                                              examsDetails[0]
                                                                                .agencyDate
                                                                                ? moment(
                                                                                    examsDetails[0]
                                                                                      .agencyDate
                                                                                  ).format(
                                                                                    "DD/MM/YYYY"
                                                                                  )
                                                                                : ""
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                                {/* Certificate Details */}
                                                                <div className="col-xl-12">
                                                                  <div className="card">
                                                                    <div className="card-header fw-bold">
                                                                      Exemption
                                                                      Certificate
                                                                      Details
                                                                    </div>
                                                                    <div className="card-body">
                                                                      <div className="row g-2">
                                                                        {/* Certificate No */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Certificate No."
                                                                            fullWidth
                                                                            variant="standard"
                                                                            value={
                                                                              examsDetails[0]
                                                                                .certificationNo
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>

                                                                        {/* Certification Date */}
                                                                        <div className="col-xl-12">
                                                                          <TextField
                                                                            label="Certification Date"
                                                                            id="certificationDate"
                                                                            name="certificationDate"
                                                                            variant="standard"
                                                                            fullWidth
                                                                            value={
                                                                              examsDetails[0]
                                                                                .certificationDate
                                                                                ? moment(
                                                                                    examsDetails[0]
                                                                                      .certificationDate
                                                                                  ).format(
                                                                                    "DD/MM/YYYY"
                                                                                  )
                                                                                : ""
                                                                            }
                                                                            className="pe-none"
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* CLT Exam */}
                                                  <div className="col-xl-12">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        {" "}
                                                        CLT Exam
                                                      </div>
                                                      <div className="card-body">
                                                        {/* Register No */}
                                                        <TextField
                                                          label="Reg No."
                                                          fullWidth
                                                          variant="standard"
                                                          value={
                                                            examsDetails[1]
                                                              .regNo
                                                          }
                                                          className="pe-none"
                                                        />

                                                        {/* Session */}
                                                        <TextField
                                                          label="Session"
                                                          fullWidth
                                                          variant="standard"
                                                          value={
                                                            examsDetails[1]
                                                              .session
                                                          }
                                                          className="pe-none"
                                                        />

                                                        {/* Date */}
                                                        <TextField
                                                          label="Date"
                                                          id="date"
                                                          name="date"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            examsDetails[1].date
                                                              ? moment(
                                                                  examsDetails[1]
                                                                    .date
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                              : ""
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* General Law - 1 & 2  */}
                                                  <div className="col-xl-12">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        General Law - 1 & 2
                                                      </div>
                                                      <div className="card-body">
                                                        {/* Register No */}
                                                        <TextField
                                                          label="Reg No."
                                                          fullWidth
                                                          variant="standard"
                                                          value={
                                                            examsDetails[2]
                                                              ?.regNo
                                                          }
                                                          className="pe-none"
                                                        />

                                                        {/* Session */}
                                                        <TextField
                                                          label="Session"
                                                          fullWidth
                                                          variant="standard"
                                                          value={
                                                            examsDetails[2]
                                                              ?.session
                                                          }
                                                          className="pe-none"
                                                        />
                                                        {/* Date */}
                                                        <TextField
                                                          label="Date"
                                                          id="date"
                                                          name="date"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            examsDetails[2]
                                                              ?.date
                                                              ? moment(
                                                                  examsDetails[2]
                                                                    .date
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                              : ""
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* Accounts Higher */}
                                                  <div className="col-xl-12">
                                                    <div className="card">
                                                      <div className="card-header fw-bold">
                                                        Accounts Higher
                                                      </div>
                                                      <div className="card-body">
                                                        {/* Register No */}
                                                        <TextField
                                                          label="Reg No."
                                                          fullWidth
                                                          variant="standard"
                                                          value={
                                                            examsDetails[3]
                                                              ?.regNo
                                                          }
                                                          className="pe-none"
                                                        />

                                                        {/* Session */}
                                                        <TextField
                                                          label="Session"
                                                          fullWidth
                                                          variant="standard"
                                                          value={
                                                            examsDetails[3]
                                                              ?.session
                                                          }
                                                          className="pe-none"
                                                        />

                                                        {/* Date */}
                                                        <TextField
                                                          label="Date"
                                                          id="date"
                                                          name="date"
                                                          variant="standard"
                                                          fullWidth
                                                          value={
                                                            examsDetails[3]
                                                              ?.date
                                                              ? moment(
                                                                  examsDetails[3]
                                                                    .date
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                              : ""
                                                          }
                                                          className="pe-none"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}

                                {/* FIT/UN FIT */}
                                {typeOfData === "P" ? (
                                  <div className="col-xl-12">
                                    <div className="card h-100">
                                      <div className="card-header fw-bold">
                                        FIT/ UNFIT
                                      </div>
                                      <div className="card-body">
                                        <div className="row g-2">
                                          {/* Enquiry from other Agencies (Lokayukta/ Court ) */}
                                          <div className="col-xl-12">
                                            <RadioGroup
                                              row
                                              value={FIT_UN_FIT}
                                              className="pe-none"
                                            >
                                              <FormControlLabel
                                                value="FIT"
                                                control={<Radio size="small" />}
                                                label="FIT"
                                                name="FIT_UN_FIT"
                                                id="FIT_UN_FIT"
                                                className="pe-none"
                                              />
                                              <FormControlLabel
                                                value="UNFIT"
                                                control={<Radio size="small" />}
                                                label="UNFIT"
                                                name="FIT_UN_FIT"
                                                id="FIT_UN_FIT"
                                                className="pe-none"
                                              />
                                            </RadioGroup>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {/* Recomendation */}
                                <div className="col-xl-12 col-md-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Recommendation from DCP
                                    </div>
                                    <div className="card-body">
                                      <div className="row g-2">
                                        {/* From Name */}
                                        <div className="col-xl-6 ">
                                          <TextField
                                            variant="standard"
                                            fullWidth
                                            label="From Name"
                                            id="RecomFromName"
                                            name="RecomFromName"
                                            value={RecomFromName}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* From Designation */}
                                        <div className="col-xl-6">
                                          <TextField
                                            variant="standard"
                                            fullWidth
                                            label="From Designation"
                                            name="RecomFromDesignation"
                                            id="RecomFromDesignation"
                                            value={RecomFromDesignation}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Brief about Recommendation made */}
                                        <div className="col-xl-12">
                                          <TextField
                                            variant="standard"
                                            fullWidth
                                            label="Brief about Recommendation made"
                                            id="RecomMade"
                                            name="RecomMade"
                                            value={RecomMade}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Date recieved */}
                                        <div className="col-xl-6">
                                          <TextField
                                            label="Date Recieved"
                                            id="dateRecieved"
                                            name="dateRecieved"
                                            variant="standard"
                                            fullWidth
                                            value={dateRecieved}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Place recomended*/}
                                        <div className="col-xl-6">
                                          <TextField
                                            variant="standard"
                                            fullWidth
                                            label="Places recomended"
                                            id="RecomPlaceRecomended"
                                            name="RecomPlaceRecomended"
                                            value={RecomPlaceRecomended}
                                            className="pe-none"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Remarks from DCP’s  */}
                              <div className="row g-1 mt-1">
                                <div className="col-xl-12">
                                  <div className="card h-100">
                                    <div className="card-header fw-bold">
                                      Remarks from DCP
                                    </div>
                                    <div className="card-body">
                                      <div className="row g-2">
                                        {/* Entry By */}
                                        <div className="col-xl-12">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Entry By*"
                                            name="entryBy"
                                            id="entryBy"
                                            value={entryBy}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Checked By */}
                                        <div className="col-xl-12">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Checked By*"
                                            name="checkedBy"
                                            id="checkedBy"
                                            value={checkedBy}
                                            className="pe-none"
                                          />
                                        </div>

                                        {/* Remark */}
                                        <div className="col-xl-12">
                                          <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Remark"
                                            name="remark"
                                            id="remark"
                                            value={remark}
                                            className="pe-none"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* eligible list Details */}
                      <div className="col-xl-6">
                        <div className="card ">
                          <div className="card-header shadow d-flex flex-row justify-content-between align-items-center">
                            <div className=" fs-5 fw-bold">
                              {typeOfData === "P"
                                ? `Eligible List (${designationTo})`
                                : `Eligible List`}
                            </div>

                            {/* eligibleData options */}
                            <div className="eligible-list-options d-flex flex-row">
                              {/* potential vacancy */}
                              <div className="potential-vacancy">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        window.sessionStorage.getItem(
                                          "showBothVacancies"
                                        ) === "false"
                                          ? true
                                          : false
                                      }
                                      name="showByPotentialVacancy"
                                      id="showByPotentialVacancy"
                                      value={showByPotentialVacancy}
                                      checked={showByPotentialVacancy}
                                      onChange={(event) => {
                                        handleEligibleEvent(event);
                                        getEligibleDataByOption({
                                          empCode,
                                          transferYear,
                                          designation,
                                          currentWorkingPlace: workinPlace,
                                          selfNPS,
                                          spouseNPS,
                                          getByPotential: event.target.checked,
                                          getByExisting: showByExistingVacancy,
                                          typeOfData,
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Potenatial vacancy
                                    </span>
                                  }
                                />
                              </div>

                              {/* existing vacancy */}
                              <div className="existing-vacancy">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="showByExistingVacancy"
                                      id="showByExistingVacancy"
                                      value={showByExistingVacancy}
                                      checked={showByExistingVacancy}
                                      onChange={(event) => {
                                        handleEligibleEvent(event);

                                        getEligibleDataByOption({
                                          empCode,
                                          transferYear,
                                          designation,
                                          currentWorkingPlace: workinPlace,
                                          selfNPS,
                                          spouseNPS,
                                          getByPotential:
                                            showByPotentialVacancy,
                                          getByExisting: event.target.checked,
                                          typeOfData,
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Existing vacancy
                                    </span>
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            {/* indicators */}
                            <div className="d-flex flex-row mt-1 mb-3 d-flex justify-content-end">
                              <div className="green-indicator">
                                <span className="greenDot"></span>{" "}
                                <span>Potenatial vacancy</span>
                              </div>
                              <div className="blue-indicator ms-2">
                                <span className="blueDot"></span>{" "}
                                <span>Existing vacancy</span>
                              </div>
                              <div className="red-indicator ms-2">
                                <span className="redDot"></span>{" "}
                                <span>No vacancy</span>
                              </div>
                            </div>

                            {/* eligible Data */}
                            <div className="row ">
                              <div
                                className="col-xl-12"
                                style={{
                                  overflowY: "scroll",
                                  maxHeight: "500px",
                                }}
                              >
                                <table className="table table-bordered table-sm eligible-table">
                                  <thead className="position-sticky top-0">
                                    <tr>
                                      {/* ploice station label */}
                                      <th
                                        scope="col"
                                        className="text-start"
                                        style={{
                                          width: "200px",
                                        }}
                                      >
                                        Police Station
                                      </th>

                                      {/* search by policeStation */}
                                      <th
                                        scope="col"
                                        className="text-start"
                                        style={{
                                          background: "var(--white)",
                                          width: "150px",
                                        }}
                                        colSpan={3}
                                      >
                                        <Autocomplete
                                          disableClearable
                                          forcePopupIcon={false}
                                          onChange={(event, value) => {
                                            setUsedStates((prevValue) => {
                                              return {
                                                ...prevValue,
                                                searchDetails: {
                                                  ...prevValue.searchDetails,
                                                  stationName: value.Name,
                                                  divisionName: "",
                                                },
                                              };
                                            });
                                          }}
                                          inputValue={stationName}
                                          options={eligibleListData}
                                          getOptionLabel={(options) =>
                                            options.Name
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              required
                                              variant="standard"
                                              placeholder="Search By Station"
                                              {...params}
                                              onChange={(event) => {
                                                setUsedStates((prevValue) => {
                                                  return {
                                                    ...prevValue,
                                                    searchDetails: {
                                                      ...prevValue.searchDetails,
                                                      stationName:
                                                        event.target.value,
                                                    },
                                                  };
                                                });
                                              }}
                                            />
                                          )}
                                        />
                                      </th>

                                      {/* division label */}
                                      <th
                                        scope="col"
                                        className="text-start"
                                        style={{ width: "12px" }}
                                      >
                                        Division
                                      </th>

                                      {/* search by Division */}
                                      <th
                                        scope="col"
                                        className="text-start"
                                        style={{
                                          background: "white",
                                          width: "150px",
                                        }}
                                        colSpan={3}
                                      >
                                        <Autocomplete
                                          disableClearable
                                          forcePopupIcon={false}
                                          onChange={(event, value) => {
                                            setUsedStates((prevValue) => {
                                              return {
                                                ...prevValue,
                                                searchDetails: {
                                                  ...prevValue.searchDetails,
                                                  divisionName: value.Division,
                                                  stationName: "",
                                                },
                                              };
                                            });
                                          }}
                                          inputValue={divisionName}
                                          options={lodash.uniqBy(
                                            eligibleListData,
                                            "Division"
                                          )}
                                          getOptionLabel={(options) =>
                                            options.Division
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              required
                                              variant="standard"
                                              placeholder="Search By Division"
                                              {...params}
                                              onChange={(event) => {
                                                setUsedStates((prevValue) => {
                                                  return {
                                                    ...prevValue,
                                                    searchDetails: {
                                                      ...prevValue.searchDetails,
                                                      divisionName:
                                                        event.target.value,
                                                    },
                                                  };
                                                });
                                              }}
                                            />
                                          )}
                                        />
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {divisionName === ""
                                      ? eligibleListData
                                          .filter((item) =>
                                            item.Name.toLowerCase().includes(
                                              stationName.toLowerCase()
                                            )
                                          )
                                          .map((data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    color:
                                                      "var(--default-color)",
                                                    fontWeight: "bolder",
                                                    width: "200px",
                                                  }}
                                                  onClick={() =>
                                                    swal({
                                                      title: "Are You Sure ?",
                                                      icon: "info",
                                                      text: `${fullName} will be ${
                                                        typeOfData !== `P`
                                                          ? `transfered`
                                                          : `promoted`
                                                      }  from '${workinPlace}' to '${
                                                        data.Name
                                                      }'`,
                                                      buttons: [
                                                        true,
                                                        "confirm",
                                                      ],
                                                    }).then((value) => {
                                                      if (value) {
                                                        handleSubmit({
                                                          placeName: data.Name,
                                                        });
                                                      } else {
                                                        // disable loading
                                                        setUsedStates(
                                                          (prevValue) => {
                                                            return {
                                                              ...prevValue,
                                                              loading: false,
                                                            };
                                                          }
                                                        );
                                                      }
                                                    })
                                                  }
                                                  className={
                                                    data.potStatus
                                                      .concat(data.existStatus)
                                                      .filter(
                                                        (data) =>
                                                          data.status === "open"
                                                      ).length === 0
                                                      ? "disable-cell"
                                                      : "active-cell"
                                                  }
                                                >
                                                  {data.Name} (
                                                  {data.existStatus.filter(
                                                    (item) =>
                                                      item.status === "open"
                                                  ).length +
                                                    data.potStatus.filter(
                                                      (item) =>
                                                        item.status === "open"
                                                    ).length}
                                                  )
                                                </td>

                                                {/* No of vacancies */}
                                                {data.potStatus.map(
                                                  (data, index) => {
                                                    return (
                                                      <td key={index}>
                                                        <span
                                                          className={
                                                            data.status ===
                                                            "open"
                                                              ? "greenDot shadow"
                                                              : "redDot shadow"
                                                          }
                                                        ></span>
                                                      </td>
                                                    );
                                                  }
                                                )}

                                                {/* No of posting */}
                                                {data.existStatus.map(
                                                  (data, index) => {
                                                    return (
                                                      <td key={index}>
                                                        <span
                                                          className={
                                                            data.status ===
                                                            "open"
                                                              ? "blueDot shadow"
                                                              : "redDot shadow"
                                                          }
                                                        ></span>
                                                      </td>
                                                    );
                                                  }
                                                )}
                                              </tr>
                                            );
                                          })
                                      : eligibleListData
                                          .filter(
                                            (item) =>
                                              item.Division.toLowerCase() ===
                                              divisionName.toLowerCase()
                                          )
                                          .map((data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    color:
                                                      "var(--default-color)",
                                                    fontWeight: "bolder",
                                                    width: "200px",
                                                  }}
                                                  onClick={() =>
                                                    swal({
                                                      title: "Are You Sure ?",
                                                      icon: "info",
                                                      text: `${fullName} will be ${
                                                        typeOfData !== `P`
                                                          ? `transfered`
                                                          : `promoted`
                                                      }  from '${workinPlace}' to '${
                                                        data.Name
                                                      }'`,
                                                      buttons: [
                                                        true,
                                                        "confirm",
                                                      ],
                                                    }).then((value) => {
                                                      if (value) {
                                                        handleSubmit({
                                                          placeName: data.Name,
                                                        });
                                                      } else {
                                                        // disable loading
                                                        setUsedStates(
                                                          (prevValue) => {
                                                            return {
                                                              ...prevValue,
                                                              loading: false,
                                                            };
                                                          }
                                                        );
                                                      }
                                                    })
                                                  }
                                                  className={
                                                    data.potStatus
                                                      .concat(data.existStatus)
                                                      .filter(
                                                        (data) =>
                                                          data.status === "open"
                                                      ).length === 0
                                                      ? "disable-cell"
                                                      : "active-cell"
                                                  }
                                                >
                                                  {data.Name} (
                                                  {data.existStatus.filter(
                                                    (item) =>
                                                      item.status === "open"
                                                  ).length +
                                                    data.potStatus.filter(
                                                      (item) =>
                                                        item.status === "open"
                                                    ).length}
                                                  )
                                                </td>

                                                {/* No of potential vacancy */}
                                                {data.potStatus.map(
                                                  (data, index) => {
                                                    return (
                                                      <td key={index}>
                                                        <span
                                                          className={
                                                            data.status ===
                                                            "open"
                                                              ? "greenDot shadow"
                                                              : "redDot shadow"
                                                          }
                                                        ></span>
                                                      </td>
                                                    );
                                                  }
                                                )}

                                                {/* No of existing vacancy */}
                                                {data.existStatus.map(
                                                  (data, index) => {
                                                    return (
                                                      <td key={index}>
                                                        <span
                                                          className={
                                                            data.status ===
                                                            "open"
                                                              ? "blueDot shadow"
                                                              : "redDot shadow"
                                                          }
                                                        ></span>
                                                      </td>
                                                    );
                                                  }
                                                )}
                                              </tr>
                                            );
                                          })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Dialog for undo operation */}
      <Dialog fullWidth maxWidth="sm" open={undoModal.open}>
        <DialogTitle className="fw-bold">
          <div className="row">
            <div className="col-sm-3">
              <span>Undo Details</span>
            </div>
            <div className="col-sm">
              <RadioGroup row value={undoModal.searchOption}>
                <FormControlLabel
                  value="empCode"
                  control={<Radio size="small" />}
                  label="KGIDNo"
                  name="undoOption"
                  id="undoOption"
                  onChange={(event) => {
                    handleResetUndoDetails({ optionType: "specific" });

                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        undoModal: {
                          ...prevValue.undoModal,
                          searchOption: event.target.value,
                        },
                      };
                    });
                  }}
                />
                <FormControlLabel
                  value="metalNo"
                  control={<Radio size="small" />}
                  label="MetalNo"
                  name="undoOption"
                  id="undoOption"
                  onChange={(event) => {
                    handleResetUndoDetails({ optionType: "specific" });

                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        undoModal: {
                          ...prevValue.undoModal,
                          searchOption: event.target.value,
                        },
                      };
                    });
                  }}
                />
              </RadioGroup>
            </div>
            <div className="col-sm-1">
              <AiOutlineClose
                fontSize={25}
                style={{ cursor: "pointer" }}
                onClick={() => handleResetUndoDetails({ optionType: "All" })}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleUndo}>
            <div className="row g-2">
              {/* Transfer Year */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Transfer Year"
                  variant="standard"
                  value={undoDetails.transferYear}
                  className="pe-none"
                  required
                />
              </div>

              {/* Designation */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Designation"
                  variant="standard"
                  value={undoDetails.designation}
                  className="pe-none"
                  required
                />
              </div>

              {/* KGID No / metal No  */}
              <div className="col-xl-12">
                {undoModal.searchOption === "empCode" ? (
                  <Autocomplete
                    size="small"
                    disableClearable
                    forcePopupIcon={false}
                    onChange={(event, value) => {
                      setUsedStates((prevValue) => {
                        return {
                          ...prevValue,
                          undoModal: {
                            ...prevValue.undoModal,
                            undoDetails: {
                              ...prevValue.undoModal.undoDetails,
                              empCode: value.empCode,
                            },
                          },
                        };
                      });

                      getUndoDetails({
                        transferYear: undoDetails.transferYear,
                        designation: undoDetails.designation,
                        empCode: value.empCode,
                        optionType: "specificData",
                        metalNo: "",
                      });
                    }}
                    inputValue={undoDetails.empCode.toString()}
                    options={undoModal.empCodes}
                    getOptionLabel={(options) => options.empCode.toString()}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        {...params}
                        label="Select KGID No."
                        onChange={(event) => {
                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              undoModal: {
                                ...prevValue.undoModal,
                                undoDetails: {
                                  ...prevValue.undoModal.undoDetails,
                                  empCode: event.target.value,
                                },
                              },
                            };
                          });
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    disableClearable
                    forcePopupIcon={false}
                    onChange={(event, value) => {
                      setUsedStates((prevValue) => {
                        return {
                          ...prevValue,
                          undoModal: {
                            ...prevValue.undoModal,
                            undoDetails: {
                              ...prevValue.undoModal.undoDetails,
                              metalNo: value.MetalNo,
                            },
                          },
                        };
                      });

                      getUndoDetails({
                        transferYear: undoDetails.transferYear,
                        designation: undoDetails.designation,
                        metalNo: value.MetalNo,
                        optionType: "specificData",
                        empCode: "",
                      });
                    }}
                    inputValue={undoDetails.metalNo}
                    options={undoModal.metalNos}
                    getOptionLabel={(options) => options.MetalNo}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        {...params}
                        label="Select Metal No."
                        onChange={(event) => {
                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              undoModal: {
                                ...prevValue.undoModal,
                                undoDetails: {
                                  ...prevValue.undoModal.undoDetails,
                                  metalNo: event.target.value,
                                },
                              },
                            };
                          });
                        }}
                      />
                    )}
                  />
                )}
              </div>

              {/* Full Name */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Full Name"
                  variant="standard"
                  value={undoDetails.fullName}
                  className="pe-none"
                  required
                />
              </div>

              {/* Transfer From */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Transfer From"
                  variant="standard"
                  value={undoDetails.transferFrom}
                  className="pe-none"
                />
              </div>

              {/* Transfer to  */}
              <div className="col-xl-12">
                <TextField
                  fullWidth
                  label="Transfer To"
                  variant="standard"
                  value={undoDetails.transferTo}
                  className="pe-none"
                />
              </div>
            </div>

            {/* submit button */}
            <Button
              variant="outlined"
              className="success-button mt-3"
              type="submit"
            >
              undo
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminTransfer;
