/*******************************  Vacancy  Indicators **********************************************/

import {
  Button,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
  React,
  useState,
  useEffect,
  swal,
  lodash,
  axios,
  useCallback,
  Dialog,
  DialogContent,
  BeatLoader,
  Checkbox,
  Autocomplete,
  TextField,
} from "./commonFiles/Import";

const VacancyIndicator = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,

    interval: 0, // used for autoRefresh

    transferYears: [],
    designations: [],

    searchDetails: {
      typeOfData: "",
      transferYear: "",
      designation: "",
      designationTo: "", // only for promotion
    },

    vacancyListOption: {
      stationName: "",
      divisionName: "",
    },

    vacancyListData: [],

    redFlagData: [],

    totalTransfers: [],

    eligibleListoptions: {
      showByPotentialVacancy: false,
      showByExistingVacancy: true,
      showBothVacancies: false,
    },

    // maintain transfer start status
    transferStatus: false,
  });

  /********************* Destructring Objects start ************************************/
  const {
    loading,
    transferYears,
    designations,
    searchDetails,
    interval,
    vacancyListData,
    vacancyListOption,
    eligibleListoptions,
    redFlagData,
    totalTransfers,
    transferStatus,
  } = usedStates;

  const { typeOfData, transferYear, designation, designationTo } =
    searchDetails;

  const { stationName, divisionName } = vacancyListOption;

  const { showByExistingVacancy, showByPotentialVacancy, showBothVacancies } =
    eligibleListoptions;

  /********************* Destructring Objects end ************************************/

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // get transferYears
  const getTransferYears = useCallback(
    async ({ transferYear, typeOfData }) => {
      try {
        // enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: true,
          };
        });

        const result = await axios.get("/getUserDetailsSR", {
          params: { transferYear, typeOfData, userId },
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            transferYears: lodash.uniqBy(result.data, "transferYear"),
            loading: false,
          };
        });
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    },
    [userId]
  );

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        const { settingStatusDetails, transferStatus } = result.data;

        // get data of setting status
        const {
          transferYear,
          designation,
          actionType,
          vacancyListType,
          designationTo,
        } = settingStatusDetails;

        // update states
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            searchDetails: {
              ...prevValue.searchDetails,
              transferYear,
              designation,
              typeOfData: actionType,
              designationTo,
            },

            transferStatus,
            loading: false,

            eligibleListoptions: {
              ...prevValue.eligibleListoptions,
              showByPotentialVacancy: vacancyListType.includes("Potential")
                ? true
                : false,
              showBothVacancies: vacancyListType.includes("Potential")
                ? true
                : false,
            },
          };
        });

        getTransferYears({ typeOfData: actionType, transferYear: "" });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId, getTransferYears]);

  // handle  input event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        searchDetails: {
          ...prevValue.searchDetails,
          [name]: value,
        },
      };
    });
  };

  // handle Reset states
  const handleResetStates = ({ typeOfData }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loading: false,
        vacancyListData: [],
        redFlagData: [],
        totalTransfers: [],
        vacancyListOption: {
          ...prevValue.vacancyListOption,
          stationName: "",
          divisionName: "",
        },
        searchDetails: {
          ...prevValue.searchDetails,
          typeOfData,
        },
      };
    });

    clearInterval(interval);

    if (typeOfData === "All") {
      getSettingData();
    }
  };

  // update Vacancy Indicators
  const updateVacancyIndicators = ({
    vacancyListData,
    redFlagData,
    totalTransfers,
    showByPotentialVacancy,
    showByExistingVacancy,
  }) => {
    // add one more key value pair to differentiate vacancies is available or not
    if (vacancyListData.length > 0) {
      for (let i = 0; i < vacancyListData.length; i++) {
        //let intialize variable
        let potStatus = [],
          existStatus = [];
        const data = vacancyListData[i];

        // No of vacancies
        if (showByPotentialVacancy) {
          for (let index = 0; index < data.Nvac; index++) {
            potStatus.push({ status: "open" });
          }
        }

        // posting vacancies
        if (showByExistingVacancy) {
          for (let index = 0; index < data.exist_vac; index++) {
            existStatus.push({ status: "open" });
          }
        }

        Object.assign(data, { potStatus, existStatus });
      }
    }

    // update data
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        vacancyListData,
        totalTransfers,
        loading: false,
      };
    });

    // update status of vacancy status based on match of redFlagdata and eligible list data
    if (redFlagData.length > 0) {
      //  intialize variable
      let remainingRedFlag = 0;

      vacancyListData.forEach((eligibleData) => {
        const matchedData = redFlagData.filter(
          (item) => item.name.toLowerCase() === eligibleData.Name.toLowerCase()
        );

        if (matchedData.length > 0) {
          // first update pot status based on red flag
          if (showByPotentialVacancy) {
            if (eligibleData.potStatus.length > 0) {
              for (let index = 0; index < matchedData.length; index++) {
                if (index >= eligibleData.potStatus.length) {
                  remainingRedFlag =
                    matchedData.length - eligibleData.potStatus.length;
                  break;
                }
                eligibleData.potStatus[index].status = "close";
              }
            }
          }

          // // update existing status
          if (showByExistingVacancy) {
            if (eligibleData.existStatus.length > 0) {
              if (remainingRedFlag > 0) {
                for (let index = 0; index < remainingRedFlag; index++) {
                  eligibleData.existStatus[index].status = "close";
                }
                remainingRedFlag = 0;
              }

              if (eligibleData.potStatus.length === 0) {
                for (let index = 0; index < matchedData.length; index++) {
                  if (index > eligibleData.existStatus.length - 1) {
                    break;
                  }
                  eligibleData.existStatus[index].status = "close";
                }
              }
            }
          }
        }
      });
    }

    // update data
    setTimeout(() => {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          vacancyListData,
          redFlagData,
        };
      });
    }, 1000);
  };

  // get VacacancyIndicatorData
  const getVacacancyIndicatorData = async ({ searchDetails }) => {
    try {
      const result = await axios.get("/getVacancyIndicatorData", {
        params: Object.assign(searchDetails, { userId }),
      });

      // on success
      if (result.data) {
        const { redFlagData, vacancyList, totalTransfers } = result.data;

        updateVacancyIndicators({
          redFlagData,
          vacancyListData: vacancyList,
          totalTransfers,
          showByPotentialVacancy,
          showByExistingVacancy,
        });
      }
    } catch (err) {
      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // get all policeStation and red flag data
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      // call this function only one time without any interval
      getVacacancyIndicatorData({ searchDetails });

      // every 10 sec update vacancy list
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          interval: transferStatus
            ? setInterval(() => {
                getVacacancyIndicatorData({ searchDetails });
              }, 10000)
            : 0,
        };
      });
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle eligible event (checkbox)
  const handleEligibleEvent = (event) => {
    const { name, checked } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        eligibleListoptions: {
          ...prevValue.eligibleListoptions,
          [name]: checked,
        },
      };
    });
  };

  useEffect(() => {
    getDesignationDetails();
    getSettingData();

    // clear auto Referesh interval
    return () => {
      clearInterval(interval);
    };
  }, [interval, getSettingData, getDesignationDetails]);

  return (
    <>
      <section className="form-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="card border-0 mb-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-2">
                    <h1 className="fs-4 fw-bold">Vacancy Indicator</h1>
                  </div>

                  {/* Type of data */}
                  <div className="col-xl d-flex align-items-center">
                    <RadioGroup row value={typeOfData}>
                      {/* General Transfer */}
                      <FormControlLabel
                        value="G"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">General Transfer</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);
                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });
                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />

                      {/* Request Transfer */}
                      <FormControlLabel
                        value="T"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">Request Transfer</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);
                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });
                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />

                      {/* Promotion */}
                      <FormControlLabel
                        className="ms-1"
                        value="P"
                        control={<Radio size="small" />}
                        label={<span className="ms-2">Promotion</span>}
                        name="typeOfData"
                        id="typeOfData"
                        onChange={(event) => {
                          handleInputEvent(event);

                          getTransferYears({
                            transferYear: "",
                            typeOfData: event.target.value,
                          });

                          handleResetStates({ typeOfData: event.target.value });
                        }}
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row">
                  {/* generate Form */}
                  <div className="col-xl-12">
                    <form
                      method="POST"
                      autoComplete="Off"
                      onSubmit={handleSubmit}
                    >
                      <div className="row mb-3 g-3 mt-1 ">
                        {/* select transferYear */}
                        <div className="col-xl-3">
                          <InputLabel>
                            {typeOfData !== "P"
                              ? "Select transfer year*"
                              : "Select promotion year*"}
                          </InputLabel>
                          <NativeSelect
                            required
                            fullWidth
                            variant="standard"
                            inputProps={{
                              name: "transferYear",
                              id: "transferYear",
                            }}
                            onChange={(event) => {
                              handleInputEvent(event);
                            }}
                            value={transferYear}
                          >
                            {transferYears.map((data, index) => {
                              return (
                                <option key={index} value={data.transferYear}>
                                  {data.transferYear}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>

                        {/* select Designation */}
                        <div className="col-xl-3">
                          <InputLabel>Select Designation*</InputLabel>
                          <NativeSelect
                            required
                            fullWidth
                            variant="standard"
                            inputProps={{
                              name: "designation",
                              id: "designation",
                            }}
                            onChange={(event) => {
                              handleInputEvent(event);
                            }}
                            value={designation}
                          >
                            {designations.map((data, index) => {
                              return (
                                <option key={index} value={data.Cadre}>
                                  {data.Cadre}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>
                      </div>

                      <div className="buttons">
                        {/* submit button  */}
                        <Button
                          variant="outlined"
                          className="primary-button  shadow"
                          type="submit"
                        >
                          generate
                        </Button>

                        {/* reset button */}
                        <Button
                          type="button"
                          variant="outlined"
                          className="reset-button shadow ms-2"
                          onClick={() =>
                            handleResetStates({ typeOfData: "All" })
                          }
                        >
                          reset
                        </Button>
                      </div>
                    </form>
                  </div>

                  {/* Result */}
                  <div className="col-xl-12 mt-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          {/* heading */}
                          <div className="col-xl-6">
                            <h5 className="fw-bold">
                              {" "}
                              {typeOfData === "P"
                                ? `Vacancy List (${designationTo})`
                                : `Vacancy List`}
                            </h5>
                          </div>

                          {/* VacancyList options */}
                          <div className="col-xl">
                            <div className="row">
                              {/* potential vacancy */}
                              <div className="col-xl ">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={!showBothVacancies}
                                      name="showByPotentialVacancy"
                                      id="showByPotentialVacancy"
                                      value={showByPotentialVacancy}
                                      checked={showByPotentialVacancy}
                                      onChange={(event) => {
                                        handleEligibleEvent(event);
                                        updateVacancyIndicators({
                                          vacancyListData,
                                          redFlagData,
                                          totalTransfers,
                                          showByPotentialVacancy:
                                            event.target.checked,
                                          showByExistingVacancy,
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Potenatial vacancy
                                    </span>
                                  }
                                />
                              </div>
                              {/* existing vacancy */}
                              <div className="col-xl">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="showByExistingVacancy"
                                      id="showByExistingVacancy"
                                      value={showByExistingVacancy}
                                      checked={showByExistingVacancy}
                                      onChange={(event) => {
                                        handleEligibleEvent(event);
                                        updateVacancyIndicators({
                                          vacancyListData,
                                          redFlagData,
                                          totalTransfers,
                                          showByPotentialVacancy,
                                          showByExistingVacancy:
                                            event.target.checked,
                                        });
                                      }}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Existing vacancy
                                    </span>
                                  }
                                />
                              </div>
                              {/* total-transfers */}
                              <div className="col-xl shadow d-flex align-items-center">
                                <h5 className="fw-bold">
                                  Total{" "}
                                  {typeOfData !== "P"
                                    ? "Transfers"
                                    : "Promotions"}{" "}
                                  : {redFlagData.length}/{totalTransfers.length}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        {/* indicators */}
                        <div className="d-flex flex-row mt-1 mb-3 d-flex justify-content-end">
                          <div className="green-indicator">
                            <span className="greenDot"></span>{" "}
                            <span>Potenatial vacancy</span>
                          </div>
                          <div className="blue-indicator ms-2">
                            <span className="blueDot"></span>{" "}
                            <span>Existing vacancy</span>
                          </div>
                          <div className="red-indicator ms-2">
                            <span className="redDot"></span>{" "}
                            <span>No vacancy</span>
                          </div>
                        </div>

                        {/* eligible Data */}
                        <div className="row">
                          <div
                            className="col-xl-12"
                            style={{
                              overflowY: "scroll",
                              maxHeight: "500px",
                            }}
                          >
                            <table className="table table-bordered table-sm eligible-table">
                              <thead className="position-sticky top-0">
                                <tr>
                                  {/* ploice station label */}
                                  <th
                                    scope="col"
                                    className="text-start"
                                    style={{
                                      width: "350px",
                                    }}
                                  >
                                    Police Station
                                  </th>

                                  {/* search by policeStation */}
                                  <th
                                    scope="col"
                                    className="text-start"
                                    style={{
                                      background: "var(--white)",
                                      width: "150px",
                                    }}
                                    colSpan={3}
                                  >
                                    <Autocomplete
                                      disableClearable
                                      forcePopupIcon={false}
                                      onChange={(event, value) => {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            vacancyListOption: {
                                              ...prevValue.vacancyListOption,
                                              stationName: value.Name,
                                              divisionName: "",
                                            },
                                          };
                                        });
                                      }}
                                      inputValue={stationName.toString()}
                                      options={vacancyListData}
                                      getOptionLabel={(options) =>
                                        options.Name.toString()
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          variant="standard"
                                          placeholder="Search By Station"
                                          {...params}
                                          onChange={(event) => {
                                            setUsedStates((prevValue) => {
                                              return {
                                                ...prevValue,
                                                vacancyListOption: {
                                                  ...prevValue.vacancyListOption,
                                                  stationName:
                                                    event.target.value,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                      )}
                                    />
                                  </th>

                                  {/* division label */}
                                  <th
                                    scope="col"
                                    className="text-start"
                                    style={{ width: "20px" }}
                                  >
                                    Division
                                  </th>

                                  {/* search by Division */}
                                  <th
                                    scope="col"
                                    className="text-start"
                                    style={{
                                      background: "white",
                                      width: "150px",
                                    }}
                                    colSpan={3}
                                  >
                                    <Autocomplete
                                      disableClearable
                                      forcePopupIcon={false}
                                      onChange={(event, value) => {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            vacancyListOption: {
                                              ...prevValue.vacancyListOption,
                                              divisionName: value.Division,
                                              stationName: "",
                                            },
                                          };
                                        });
                                      }}
                                      inputValue={divisionName}
                                      options={lodash.uniqBy(
                                        vacancyListData,
                                        "Division"
                                      )}
                                      getOptionLabel={(options) =>
                                        options.Division
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          variant="standard"
                                          placeholder="Search By Division"
                                          {...params}
                                          onChange={(event) => {
                                            setUsedStates((prevValue) => {
                                              return {
                                                ...prevValue,
                                                vacancyListOption: {
                                                  ...prevValue.vacancyListOption,
                                                  divisionName:
                                                    event.target.value,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                      )}
                                    />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {divisionName === ""
                                  ? vacancyListData
                                      .filter((item) =>
                                        item.Name.toLowerCase().includes(
                                          stationName.toLowerCase()
                                        )
                                      )
                                      .map((data, index) => {
                                        return (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                color: "var(--default-color)",
                                                fontWeight: "bolder",
                                              }}
                                              className={
                                                data.potStatus
                                                  .concat(data.existStatus)
                                                  .filter(
                                                    (data) =>
                                                      data.status === "open"
                                                  ).length === 0
                                                  ? "disable-cell"
                                                  : ""
                                              }
                                            >
                                              {data.Name} (
                                              {data.existStatus.filter(
                                                (item) => item.status === "open"
                                              ).length +
                                                data.potStatus.filter(
                                                  (item) =>
                                                    item.status === "open"
                                                ).length}
                                              )
                                            </td>

                                            {/* No of potential Vacancy */}
                                            {data.potStatus.map(
                                              (data, index) => {
                                                return (
                                                  <td key={index}>
                                                    <span
                                                      className={
                                                        data.status === "open"
                                                          ? "greenDot shadow"
                                                          : "redDot shadow"
                                                      }
                                                    ></span>
                                                  </td>
                                                );
                                              }
                                            )}

                                            {/* No of existing vacancy */}
                                            {data.existStatus.map(
                                              (data, index) => {
                                                return (
                                                  <td key={index}>
                                                    <span
                                                      className={
                                                        data.status === "open"
                                                          ? "blueDot shadow"
                                                          : "redDot shadow"
                                                      }
                                                    ></span>
                                                  </td>
                                                );
                                              }
                                            )}
                                          </tr>
                                        );
                                      })
                                  : vacancyListData
                                      .filter(
                                        (item) =>
                                          item.Division.toLowerCase() ===
                                          divisionName.toLowerCase()
                                      )
                                      .map((data, index) => {
                                        return (
                                          <tr key={index}>
                                            <td
                                              style={{
                                                color: "var(--default-color)",
                                                fontWeight: "bolder",
                                              }}
                                              className={
                                                data.potStatus
                                                  .concat(data.existStatus)
                                                  .filter(
                                                    (data) =>
                                                      data.status === "open"
                                                  ).length === 0
                                                  ? "disable-cell"
                                                  : "active-cell"
                                              }
                                            >
                                              {data.Name} (
                                              {data.existStatus.filter(
                                                (item) => item.status === "open"
                                              ).length +
                                                data.potStatus.filter(
                                                  (item) =>
                                                    item.status === "open"
                                                ).length}
                                              )
                                            </td>

                                            {/* No of potential vacancy */}
                                            {data.potStatus.map(
                                              (data, index) => {
                                                return (
                                                  <td key={index}>
                                                    <span
                                                      className={
                                                        data.status === "open"
                                                          ? "greenDot shadow"
                                                          : "redDot shadow"
                                                      }
                                                    ></span>
                                                  </td>
                                                );
                                              }
                                            )}

                                            {/* No of existing vacancy */}
                                            {data.existStatus.map(
                                              (data, index) => {
                                                return (
                                                  <td key={index}>
                                                    <span
                                                      className={
                                                        data.status === "open"
                                                          ? "blueDot shadow"
                                                          : "redDot shadow"
                                                      }
                                                    ></span>
                                                  </td>
                                                );
                                              }
                                            )}
                                          </tr>
                                        );
                                      })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VacancyIndicator;
