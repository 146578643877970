/************************ Routing can handle by this file ************************/

import {
  React,
  Switch,
  Route,
  DashBoard,
  Login,
  Redirect,
  PageNotFound,
  useLocation,
  Cookies,
  useState,
  useEffect,
  swal,
  Dialog,
  DialogContent,
  BeatLoader,
  axios,
} from "../views/commonFiles/Import";

const Router = () => {
  const location = useLocation();

  const [usedStates, setUsedStates] = useState({
    loading: false,
    userName: location.pathname.split("/")[1],
    validAccount: false,
  });

  /******************** Destructering Objects start *****************/
  const { loading, userName, validAccount } = usedStates;

  /******************** Destructering Objects end *****************/

  // get admin login Details
  const getAdminLoginDetails = async ({ userName }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAdminLoginDetails", {
        params: { userName, password: "" },
      });

      // on success
      if (result.data) {
        // get data
        const { role, userId } = result.data;

        // store role of user in session storage
        window.sessionStorage.setItem("adminRole", role);

        // store userId of user in session storage
        window.sessionStorage.setItem("adminUserId", userId);

        // store userName of user in session storage
        window.sessionStorage.setItem("adminUserName", userName);

        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
            validAccount: true,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { loginError } = err.response.data;

      // anauthorized / inactive user
      if (loginError) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            validAccount: false,
          };
        });

        swal({
          title: "Error",
          icon: "error",
          text: loginError,
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal(`${err.response.status}`, `${err.response.data}`, "error")
          : swal(`Error`, `${err}`, "error");
      }
    }
  };

  // validate user
  useEffect(() => {
    getAdminLoginDetails({ userName });
  }, [userName]);

  return validAccount ? (
    <>
      <Switch>
        {/* Login Page */}
        <Route exact path="/:adminUserName">
          <Login />
        </Route>

        {/* Welcome Page */}
        <Route exact path="/:adminUserName/welcome">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* Product client Page */}
        <Route exact path="/:adminUserName/product-clients">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* proFarma Page */}
        <Route
          exact
          path={[
            "/:adminUserName/create-profarma",
            "/:adminUserName/edit-profarma",
            "/:adminUserName/view-profarma",
          ]}
        >
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* store proFarma Page for link purpose */}
        <Route exact path={["/:adminUserName/user-acknowledgement/:token"]}>
          {Cookies.get("linkToken") ? <DashBoard /> : null}
        </Route>

        {/* potential vacancy list */}
        <Route exact path="/:adminUserName/potential-vacancy-list">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* seniority  list */}
        <Route exact path="/:adminUserName/seniority-list">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* Control Panel */}
        <Route
          exact
          path={[
            "/:adminUserName/setting",
            "/:adminUserName/designations",
            "/:adminUserName/police-stations",
            "/:adminUserName/users",
          ]}
        >
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* online-transfer */}
        <Route exact path="/:adminUserName/online-transfer">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* transfer */}
        <Route
          exact
          path={[
            "/:adminUserName/general-transfer",
            "/:adminUserName/request-transfer",
            "/:adminUserName/promotion",
          ]}
        >
          {location.state ? <DashBoard /> : <Redirect to="/" />}
        </Route>

        {/* transfer-list */}
        <Route exact path="/:adminUserName/transfer-list">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* vacancy-indicator */}
        <Route exact path="/:adminUserName/vacancy-indicator">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* Acknowledgement */}
        <Route exact path="/:adminUserName/acknowledgement">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* Acknowledgement */}
        <Route exact path="/commingSoon">
          {sessionStorage.getItem("userName") ? (
            <DashBoard />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* page not found */}
        <Route component={PageNotFound} />
      </Switch>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <>
      {/* loading modal */}
      <BeatLoader color="black" size={15} loading={loading} />
    </>
  );
};

export default Router;
