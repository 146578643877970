/*************************************** DashBoard page *****************************************/

import {
  React,
  Welcome,
  Header,
  useLocation,
  PotentialVacanacyList,
  SeniorityList,
  SearchTransfer,
  Setting,
  Acknowledgement,
  CreateProforma,
  TransferList,
  VacancyIndicator,
  CommingSoon,
  EditProforma,
  ViewProforma,
  AdminTransfer,
  Designation,
  PoliceStation,
  User,
  AdminUser,
} from "./Import";

const DashBoard = () => {
  const location = useLocation();

  return (
    <>
      {/* header part */}
      {location.pathname.includes("/user-acknowledgement") ? null : <Header />}

      {/* content */}
      <div className="content">
        {location.pathname.includes("/create-profarma") ||
        location.pathname.includes("/user-acknowledgement") ? (
          <CreateProforma />
        ) : location.pathname.includes("/potential-vacancy-list") ? (
          <PotentialVacanacyList />
        ) : location.pathname.includes("/seniority-list") ? (
          <SeniorityList />
        ) : location.pathname.includes("/setting") ? (
          <Setting />
        ) : location.pathname.includes("/online-transfer") ? (
          <SearchTransfer />
        ) : ["/general-transfer", "/request-transfer", "/promotion"].some(
            (path) => location.pathname.includes(path)
          ) ? (
          <AdminTransfer />
        ) : location.pathname.includes("/transfer-list") ? (
          <TransferList />
        ) : location.pathname.includes("/vacancy-indicator") ? (
          <VacancyIndicator />
        ) : location.pathname.includes("/acknowledgement") ? (
          <Acknowledgement />
        ) : location.pathname.includes("/edit-profarma") ? (
          <EditProforma />
        ) : location.pathname.includes("/view-profarma") ? (
          <ViewProforma />
        ) : location.pathname === "/commingSoon" ? (
          <CommingSoon />
        ) : location.pathname.includes("/designations") ? (
          <Designation />
        ) : location.pathname.includes("/police-stations") ? (
          <PoliceStation />
        ) : location.pathname.includes("/users") ? (
          <User />
        ) : location.pathname.includes("/product-clients") ? (
          <AdminUser />
        ) : (
          <Welcome />
        )}
      </div>
    </>
  );
};

export default DashBoard;
