/*************************************** Setting page *****************************************/

import {
  React,
  TextField,
  BeatLoader,
  useState,
  useEffect,
  swal,
  axios,
  lodash,
  useCallback,
  MaterialTable,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Autocomplete,
} from "../commonFiles/Import";

import { AiOutlineClose } from "../commonFiles/Icons";

const Setting = () => {
  // get userId from session
  const userId = window.sessionStorage.getItem("adminUserId");

  const [usedStates, setUsedStates] = useState({
    loading: false,
    settingDetails: {
      policeStation: "",
      circle: "",
      division: "",
      updateCircleDetails: [],
      updateDivisionDetails: [],
      updatePoliceStationDetails: [],
      settingStatusDetails: {
        policeStationStatus: false,
        circleStatus: false,
        divisionStatus: false,
        selfNPSStatus: false,
        spouseNPSStatus: false,
        currentWorkingStatus: false,
        ACKRestrictedStatus: false,
        designation: "",
        designationTo: "", // only for promotion
        transferYear: "",
        DERunningStatus: false,
        DEPunishmentStatus: false,
        NKK_KKStatus: false,
        optionType: "", // this is will descirbe the operation type of transfer process
        vacancyListType: "", // this is will descirbe the vacancy type of transfer process
        actionType: "", // this will descirbe whether it's transfer / promotion
      },
    },

    circleDetails: [],
    divisionDetails: [],
    policeStationDetails: [],
    designations: [],

    mergeModal: {
      title: "",
      open: false,
      typeOfData: "",
      mergeTableData: [],
      mergedPoliceStationData: [],
      mergedCircleData: [],
      mergedDivisionData: [],
    },
  });

  /************  Destructring objects  start *****************************/
  const {
    loading,
    settingDetails,
    circleDetails,
    divisionDetails,
    policeStationDetails,
    mergeModal,
    designations,
  } = usedStates;

  const {
    policeStation,
    circle,
    division,
    updateCircleDetails,
    updateDivisionDetails,
    updatePoliceStationDetails,
    settingStatusDetails,
  } = settingDetails;

  const {
    title,
    open,
    mergeTableData,
    mergedCircleData,
    mergedDivisionData,
    mergedPoliceStationData,
    typeOfData,
  } = mergeModal;

  const {
    policeStationStatus,
    circleStatus,
    divisionStatus,
    selfNPSStatus,
    spouseNPSStatus,
    currentWorkingStatus,
    designation,
    designationTo,
    transferYear,
    ACKRestrictedStatus,
    DERunningStatus,
    DEPunishmentStatus,
    optionType,
    NKK_KKStatus,
    vacancyListType,
    actionType,
  } = settingStatusDetails;

  /************  Destructring objects  end *****************************/

  // add isMerged  key into all manual insert table
  const addIsMergedKey = ({ typeData }) => {
    typeData.forEach((data) => {
      Object.assign(data, {
        isMerged: data.groupType && data.groupType !== "" ? true : false,
      });
    });

    return typeData;
  };

  //get settingData
  const getSettingData = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSettingData", { params: { userId } });

      // on success
      if (result.data) {
        let {
          settingDetails,
          policeStationDetails,
          divisionDetails,
          circleDetails,
          settingStatusDetails,
        } = result.data;

        // get data
        const { PoliceStation, Division, Circle } = settingDetails;

        // get data of setting status
        const {
          PS_status,
          circle_status,
          division_Status,
          self_NPS,
          spounse_NPS,
          transferYear,
          designation,
          designationTo,
          PS_Restricted,
          ACK_status,
          DERunning_Status,
          DEPunishment_Status,
          operation_Type,
          NKK_KK_Status,
          vacancyListType,
          actionType,
        } = settingStatusDetails;

        // add isMerged key into manaual insert data
        policeStationDetails = addIsMergedKey({
          typeData: policeStationDetails,
        });

        divisionDetails = addIsMergedKey({ typeData: divisionDetails });
        circleDetails = addIsMergedKey({ typeData: circleDetails });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            settingDetails: {
              ...prevValue.settingDetails,
              policeStation: PoliceStation,
              circle: Circle,
              division: Division,
              settingStatusDetails: {
                ...prevValue.settingDetails.settingStatusDetails,
                policeStationStatus: PS_status === "Y" ? true : false,
                circleStatus: circle_status === "Y" ? true : false,
                divisionStatus: division_Status === "Y" ? true : false,
                selfNPSStatus: self_NPS === "Y" ? true : false,
                spouseNPSStatus: spounse_NPS === "Y" ? true : false,
                currentWorkingStatus: PS_Restricted === "Y" ? true : false,
                ACKRestrictedStatus: ACK_status === "Y" ? true : false,
                DERunningStatus: DERunning_Status === "Y" ? true : false,
                DEPunishmentStatus: DEPunishment_Status === "Y" ? true : false,
                NKK_KKStatus: NKK_KK_Status === "Y" ? true : false,
                vacancyListType,
                transferYear,
                designation,
                designationTo,
                optionType: operation_Type,
                actionType:
                  actionType === "G"
                    ? "General Transfer"
                    : actionType === "T"
                    ? "Request Transfer"
                    : "Promotion",
              },
            },
            policeStationDetails: lodash.uniqBy(policeStationDetails, "name"),
            circleDetails: lodash.uniqBy(circleDetails, "name"),
            divisionDetails: lodash.uniqBy(divisionDetails, "name"),

            loading: false,
          };
        });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  // handle Input Event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        settingDetails: {
          ...prevValue.settingDetails,
          [name]: value,
        },
      };
    });
  };

  // handle checkBox Event
  const handleCheckBoxEvent = (event) => {
    const { name, checked } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        settingDetails: {
          ...prevValue.settingDetails,
          settingStatusDetails: {
            ...prevValue.settingDetails.settingStatusDetails,
            [name]: checked,
          },
        },
      };
    });
  };

  // handle manaul insert
  const handleManualInsert = ({
    name,
    value,
    inputType,
    index,
    updateType,
    isMerged,
  }) => {
    inputType[index][name] = value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        inputType,
      };
    });

    //add data
    updateType.push({
      name: inputType[index]["name"],
      noOfYears: value,
      isMerged,
    });
  };

  // open modal
  const openMergeModal = ({ open, title, mergeTableData, typeOfData }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        mergeModal: {
          ...prevValue.mergeModal,
          open,
          title,
          mergeTableData,
          typeOfData,
        },
      };
    });
  };

  // handle merged input
  const handleMergedInput = ({ name, mergedArray, value, index }) => {
    mergedArray.push({
      name,
      value,
      index,
    });
  };

  // handle merge submit
  const handleMergeSubmit = ({ type }) => {
    // initailze varible
    let newUpdatedData = "",
      removeIndices = [],
      responseArray = [];

    // merge police station
    if (type === "mergePoliceStation") {
      //merge multiple name as single name
      mergedPoliceStationData.forEach((data) => {
        newUpdatedData += `${data.name},`;
      });

      // add removable indices based on merged data
      mergedPoliceStationData.forEach((data) => {
        removeIndices.push(data.index);
      });

      // remove police station data based on merge data's index
      responseArray = policeStationDetails.filter(
        (_, index) => !removeIndices.includes(index)
      );

      // add new merged Data at top of the policeStation Data
      responseArray.unshift({
        name: newUpdatedData.slice(0, -1),
        No_of_years: policeStation,
        isMerged: true,
      });

      // add new merged data into updatePolice Station data
      updatePoliceStationDetails.push({
        name: newUpdatedData.slice(0, -1),
        noOfYears: policeStation,
        isMerged: true,
      });

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          policeStationDetails: responseArray,
          mergeModal: {
            ...prevValue.mergeModal,
            open: false,
            mergeTableData: responseArray,
          },
        };
      });

      // after the  completion of process clear the array to store the next merged data
      mergedPoliceStationData.splice(0, mergedPoliceStationData.length);
    }

    // unmerge police station
    if (type === "unMergePoliceStation") {
      // add removable indices based on merged data
      mergedPoliceStationData.forEach((data) => {
        removeIndices.push(data.index);
      });

      // remove police station data based on merge data's index
      responseArray = policeStationDetails.filter(
        (_, index) => !removeIndices.includes(index)
      );

      // add unmerge data at top of merge index
      mergedPoliceStationData.forEach((data) => {
        //split data
        data.name.split(",").forEach((item) => {
          responseArray.unshift({
            name: item,
            No_of_years: policeStation,
            isMerged: false,
          });

          // add new unmerged data into updatePolice Station data
          updatePoliceStationDetails.push({
            name: item,
            noOfYears: policeStation,
            isMerged: false,
          });
        });
      });

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          policeStationDetails: responseArray,
          mergeModal: {
            ...prevValue.mergeModal,
            open: false,
            mergeTableData: responseArray,
          },
        };
      });

      // after the  completion of process clear the array to store the next unmeged data
      mergedPoliceStationData.splice(0, mergedPoliceStationData.length);
    }

    // merge circle
    if (type === "mergeCircle") {
      //merge multiple name as single name
      mergedCircleData.forEach((data) => {
        newUpdatedData += `${data.name},`;
      });

      // add removable indices based on merged data
      mergedCircleData.forEach((data) => {
        removeIndices.push(data.index);
      });

      // remove police station data based on merge data's index
      responseArray = circleDetails.filter(
        (_, index) => !removeIndices.includes(index)
      );

      // add new merged Data at top of the circle Data
      responseArray.unshift({
        name: newUpdatedData.slice(0, -1),
        No_of_years: circle,
        isMerged: true,
      });

      // add new merged data into update circle data
      updateCircleDetails.push({
        name: newUpdatedData.slice(0, -1),
        noOfYears: circle,
        isMerged: true,
      });

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          circleDetails: responseArray,
          mergeModal: {
            ...prevValue.mergeModal,
            open: false,
            mergeTableData: responseArray,
          },
        };
      });

      // after the  completion of process clear the array to store the next merged data
      mergedCircleData.splice(0, mergedCircleData.length);
    }

    // unmerge Circle
    if (type === "unMergeCircle") {
      // add removable indices based on merged data
      mergedCircleData.forEach((data) => {
        removeIndices.push(data.index);
      });

      // remove police station data based on merge data's index
      responseArray = circleDetails.filter(
        (_, index) => !removeIndices.includes(index)
      );

      // add unmerge data at top of merge index
      mergedCircleData.forEach((data) => {
        //split data
        data.name.split(",").forEach((item) => {
          responseArray.unshift({
            name: item,
            No_of_years: circle,
            isMerged: false,
          });

          // add new unmerged data into  update circle data
          updateCircleDetails.push({
            name: item,
            noOfYears: circle,
            isMerged: false,
          });
        });
      });

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          circleDetails: responseArray,
          mergeModal: {
            ...prevValue.mergeModal,
            open: false,
            mergeTableData: responseArray,
          },
        };
      });

      // after the  completion of process clear the array to store the next unmeged data
      mergedCircleData.splice(0, mergedCircleData.length);
    }

    // merge division
    if (type === "mergeDivision") {
      //merge multiple name as single name
      mergedDivisionData.forEach((data) => {
        newUpdatedData += `${data.name},`;
      });

      // add removable indices based on merged data
      mergedDivisionData.forEach((data) => {
        removeIndices.push(data.index);
      });

      // remove police station data based on merge data's index
      responseArray = divisionDetails.filter(
        (_, index) => !removeIndices.includes(index)
      );

      // add new merged Data at top of the division Data
      responseArray.unshift({
        name: newUpdatedData.slice(0, -1),
        No_of_years: division,
        isMerged: true,
      });

      // add new merged data into update division data
      updateDivisionDetails.push({
        name: newUpdatedData.slice(0, -1),
        noOfYears: division,
        isMerged: true,
      });

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          divisionDetails: responseArray,
          mergeModal: {
            ...prevValue.mergeModal,
            open: false,
            mergeTableData: responseArray,
          },
        };
      });

      // after the  completion of process clear the array to store the next merged data
      mergedDivisionData.splice(0, mergedDivisionData.length);
    }

    // unmerge division
    if (type === "unMergeDivision") {
      // add removable indices based on merged data
      mergedDivisionData.forEach((data) => {
        removeIndices.push(data.index);
      });

      // remove police station data based on merge data's index
      responseArray = divisionDetails.filter(
        (_, index) => !removeIndices.includes(index)
      );

      // add unmerge data at top of merge index
      mergedDivisionData.forEach((data) => {
        //split data
        data.name.split(",").forEach((item) => {
          responseArray.unshift({
            name: item,
            No_of_years: division,
            isMerged: false,
          });

          // add new unmerged data into update division data
          updateDivisionDetails.push({
            name: item,
            noOfYears: division,
            isMerged: false,
          });
        });
      });

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          divisionDetails: responseArray,
          mergeModal: {
            ...prevValue.mergeModal,
            open: false,
            mergeTableData: responseArray,
          },
        };
      });

      // after the  completion of process clear the array to store the next unmeged data
      mergedDivisionData.splice(0, mergedDivisionData.length);
    }
  };

  // reset states
  const handleResetStates = ({ type }) => {
    if (type === "update") {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
          settingDetails: {
            ...prevValue.settingDetails,
            policeStation: "",
            circle: "",
            division: "",
            updateCircleDetails: [],
            updateDivisionDetails: [],
            updatePoliceStationDetails: [],
            settingStatusDetails: {
              ...prevValue.settingDetails.settingStatusDetails,
              policeStationStatus: false,
              divisionStatus: false,
              circleStatus: false,
              selfNPSStatus: false,
              spouseNPSStatus: false,
              currentWorkingStatus: false,
              ACKRestrictedStatus: false,
              designation: "",
              transferYear: "",
              optionType: "",
            },
          },

          mergeModal: {
            ...prevValue.mergeModal,
            title: "",
            open: false,
            typeOfData: "",
            mergeTableData: [],
            mergedPoliceStationData: [],
            mergedCircleData: [],
            mergedDivisionData: [],
          },
        };
      });
    }
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.patch("/updateSettingData", {
        settingDetails,
        userId,
      });

      // on success
      if (result.data.success) {
        //  reset  states
        handleResetStates({ type: "update" });

        // get updated setting data
        getSettingData();

        // show success messaage
        swal({
          icon: "success",
          title: "success",
          text: `Your record updated successfully`,
          timer: "3000",
          buttons: false,
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle Apply To ALL
  const handleApplyToALL = ({ policeStation, circle, division }) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        policeStationDetails: policeStationDetails.map((obj) => ({
          ...obj,
          No_of_years: policeStation,
        })),
        circleDetails: circleDetails.map((obj) => ({
          ...obj,
          No_of_years: circle,
        })),

        divisionDetails: divisionDetails.map((obj) => ({
          ...obj,
          No_of_years: division,
        })),
        settingDetails: {
          ...prevValue.settingDetails,
          updateCircleDetails: circleDetails.map((obj) => ({
            ...obj,
            noOfYears: circle,
          })),
          updateDivisionDetails: divisionDetails.map((obj) => ({
            ...obj,
            noOfYears: division,
          })),
          updatePoliceStationDetails: policeStationDetails.map((obj) => ({
            ...obj,
            noOfYears: policeStation,
          })),
        },
      };
    });
  };

  // get designation Details
  const getDesignationDetails = useCallback(async () => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getDesignationDetails", {
        params: { userId },
      });

      // on success
      if (result.data) {
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            designations: lodash.uniqBy(result.data, "Cadre"),
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  }, [userId]);

  useEffect(() => {
    getDesignationDetails();
    getSettingData();
  }, [getSettingData, getDesignationDetails]);

  return (
    <>
      <section className="setting-section" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="card border-0 mb-4">
            <div className="card-header">
              <h1 className="fs-4 fw-bold">Setting</h1>
            </div>
            <div className="card-body">
              <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
                <div className="row">
                  {/* Conditions for Transfer / Promotion */}
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header fw-bold">
                        Conditions for Transfer / Promotion
                      </div>
                      <div className="card-body">
                        <div className="row g-2">
                          {/* Criteria for PoliceStation/Circle/Division */}
                          <div className="col-xl">
                            <div className="card h-100">
                              <div className="card-header fw-bold">
                                Criteria for PoliceStation/Circle/Division
                              </div>
                              <div className="card-body">
                                <div className="row g-2">
                                  {/* Police station */}
                                  <div className="col-xl-12">
                                    <div className="row">
                                      <div className="col-xl">
                                        <div className="d-flex flex-row">
                                          <TextField
                                            variant="standard"
                                            label="Police Station"
                                            id="policeStation"
                                            name="policeStation"
                                            value={policeStation}
                                            onChange={handleInputEvent}
                                          />
                                          <label className="d-flex align-items-end ms-2">
                                            Years
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-xl d-flex align-items-end ms-2">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="policeStationStatus"
                                              id="policeStationStatus"
                                              value={policeStationStatus}
                                              checked={policeStationStatus}
                                              onChange={handleCheckBoxEvent}
                                            />
                                          }
                                          label={
                                            <span className="ms-1">
                                              Police Station
                                            </span>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Circle */}
                                  <div className="col-xl-12">
                                    <div className="row">
                                      <div className="col-xl">
                                        <div className="d-flex flex-row">
                                          <TextField
                                            variant="standard"
                                            label="Circle"
                                            id="circle"
                                            name="circle"
                                            value={circle}
                                            onChange={handleInputEvent}
                                          />
                                          <label className="d-flex align-items-end ms-2">
                                            Years
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-xl d-flex align-items-end ms-2">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="circleStatus"
                                              id="circleStatus"
                                              value={circleStatus}
                                              checked={circleStatus}
                                              onChange={handleCheckBoxEvent}
                                            />
                                          }
                                          label={
                                            <span className="ms-1">Circle</span>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* Division */}
                                  <div className="col-xl-12">
                                    <div className="row">
                                      <div className="col-xl">
                                        <div className="d-flex flex-row">
                                          <TextField
                                            variant="standard"
                                            label="Division"
                                            id="division"
                                            name="division"
                                            value={division}
                                            onChange={handleInputEvent}
                                          />
                                          <label className="d-flex align-items-end ms-2">
                                            Years
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-xl d-flex align-items-end ms-2">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="divisionStatus"
                                              id="divisionStatus"
                                              value={divisionStatus}
                                              checked={divisionStatus}
                                              onChange={handleCheckBoxEvent}
                                            />
                                          }
                                          label={
                                            <span className="ms-1">
                                              Division
                                            </span>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* apply all button */}
                                  <div className="col-xl-12">
                                    <Button
                                      variant="outlined"
                                      className="primary-button shadow"
                                      onClick={() =>
                                        handleApplyToALL({
                                          policeStation,
                                          circle,
                                          division,
                                        })
                                      }
                                    >
                                      {" "}
                                      Apply to all
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Restricted Context */}
                          <div className="col-xl">
                            <div className="card h-100">
                              <div className="card-header fw-bold">
                                Restricted Context
                              </div>
                              <div className="card-body row g-2">
                                {/* Native self police station restricted */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="selfNPSStatus"
                                      id="selfNPSStatus"
                                      value={selfNPSStatus}
                                      checked={selfNPSStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Native self police station restricted.
                                    </span>
                                  }
                                />

                                {/* Native spouse police station restricted */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="spouseNPSStatus"
                                      id="spouseNPSStatus"
                                      value={spouseNPSStatus}
                                      checked={spouseNPSStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Native spouse police station restricted.
                                    </span>
                                  }
                                />

                                {/* Previous Worked PS - restrictions */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="currentWorkingStatus"
                                      id="currentWorkingStatus"
                                      value={currentWorkingStatus}
                                      checked={currentWorkingStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Previous Worked Police Station restricted.
                                    </span>
                                  }
                                />

                                {/* Accept user acknowledgement */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="ACKRestrictedStatus"
                                      id="ACKRestrictedStatus"
                                      value={ACKRestrictedStatus}
                                      checked={ACKRestrictedStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Accept User Acknowledgement.
                                    </span>
                                  }
                                />

                                {/* DE Running Restricted */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="DERunningStatus"
                                      id="DERunningStatus"
                                      value={DERunningStatus}
                                      checked={DERunningStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      DE running restricted.
                                    </span>
                                  }
                                />

                                {/* DE  punishment  Restricted */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="DEPunishmentStatus"
                                      id="DEPunishmentStatus"
                                      value={DEPunishmentStatus}
                                      checked={DEPunishmentStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      DE punisment restricted.
                                    </span>
                                  }
                                />

                                {/* Conduct transfers and promotions as per NKK/KK. */}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="NKK_KKStatus"
                                      id="NKK_KKStatus"
                                      value={NKK_KKStatus}
                                      checked={NKK_KKStatus}
                                      onChange={handleCheckBoxEvent}
                                    />
                                  }
                                  label={
                                    <span className="ms-1">
                                      Conduct transfers and promotions as per
                                      NKK/KK.
                                    </span>
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          {/* Additional process-related settings */}
                          <div className="col-xl">
                            <div className="card h-100">
                              <div className="card-header fw-bold">
                                Additional Settings
                              </div>
                              <div className="card-body">
                                <div className="row g-1">
                                  {/* current Action type  */}
                                  <div className="col-xl-12">
                                    <Autocomplete
                                      disableClearable
                                      popupIcon={false}
                                      options={[
                                        "General Transfer",
                                        "Request Transfer",
                                        "Promotion",
                                      ]}
                                      onChange={(event, value) => {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            settingDetails: {
                                              ...prevValue.settingDetails,
                                              settingStatusDetails: {
                                                ...prevValue.settingDetails
                                                  .settingStatusDetails,
                                                actionType: value,
                                                vacancyListType: value.includes(
                                                  "General"
                                                )
                                                  ? "Display Potential and Existing List"
                                                  : value.includes("Promotion")
                                                  ? "Display Existing List"
                                                  : vacancyListType,
                                              },
                                            },
                                          };
                                        });
                                      }}
                                      value={actionType}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Action Type"
                                          variant="standard"
                                          id="actionType"
                                          name="actionType"
                                        />
                                      )}
                                    />
                                  </div>

                                  {/* current transferYear */}
                                  <div className="col-xl-12">
                                    <TextField
                                      label="Transfer Year"
                                      id="transferYear"
                                      name="transferYear"
                                      variant="standard"
                                      fullWidth
                                      value={transferYear}
                                      onChange={(event) =>
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            settingDetails: {
                                              ...prevValue.settingDetails,
                                              settingStatusDetails: {
                                                ...prevValue.settingDetails
                                                  .settingStatusDetails,
                                                transferYear:
                                                  event.target.value,
                                              },
                                            },
                                          };
                                        })
                                      }
                                      required
                                    />
                                  </div>

                                  {/* Current Designation */}
                                  <div className="col-xl-12">
                                    <Autocomplete
                                      disableClearable
                                      popupIcon={false}
                                      options={designations.map(
                                        (value, index) => {
                                          return value.Cadre.toString();
                                        }
                                      )}
                                      onChange={(event, value) => {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            settingDetails: {
                                              ...prevValue.settingDetails,
                                              settingStatusDetails: {
                                                ...prevValue.settingDetails
                                                  .settingStatusDetails,
                                                designation: value,
                                              },
                                            },
                                          };
                                        });
                                      }}
                                      value={designation.toString()}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={
                                            <span>
                                              {actionType === "Promotion"
                                                ? "Designation From"
                                                : "Designation"}
                                            </span>
                                          }
                                          variant="standard"
                                          id="designation"
                                          name="designation"
                                        />
                                      )}
                                    />
                                  </div>

                                  {/* Designation to */}
                                  {actionType === "Promotion" ? (
                                    <div className="col-xl-12">
                                      <Autocomplete
                                        disableClearable
                                        popupIcon={false}
                                        options={designations.map(
                                          (value, index) => {
                                            return value.Cadre.toString();
                                          }
                                        )}
                                        onChange={(event, value) => {
                                          setUsedStates((prevValue) => {
                                            return {
                                              ...prevValue,
                                              settingDetails: {
                                                ...prevValue.settingDetails,
                                                settingStatusDetails: {
                                                  ...prevValue.settingDetails
                                                    .settingStatusDetails,
                                                  designationTo: value,
                                                },
                                              },
                                            };
                                          });
                                        }}
                                        value={designationTo.toString()}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Designation To"
                                            variant="standard"
                                            id="designation"
                                            name="designation"
                                          />
                                        )}
                                      />
                                    </div>
                                  ) : null}

                                  {/* operation Type */}
                                  <div className="col-xl-12">
                                    <Autocomplete
                                      disabled
                                      disableClearable
                                      popupIcon={false}
                                      options={[
                                        "Administrative",
                                        "Divisional",
                                        "User-Specific",
                                      ]}
                                      onChange={(event, value) => {
                                        setUsedStates((prevValue) => {
                                          return {
                                            ...prevValue,
                                            settingDetails: {
                                              ...prevValue.settingDetails,
                                              settingStatusDetails: {
                                                ...prevValue.settingDetails
                                                  .settingStatusDetails,
                                                optionType: value,
                                              },
                                            },
                                          };
                                        });
                                      }}
                                      value={optionType}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Operation Type"
                                          variant="standard"
                                          id="optionType"
                                          name="optionType"
                                        />
                                      )}
                                    />
                                  </div>

                                  {/* Vacancy Type */}
                                  <div className="col-xl-12">
                                    {actionType.includes("Request") ? (
                                      <Autocomplete
                                        disableClearable
                                        popupIcon={false}
                                        options={[
                                          "Display Existing List",
                                          "Display Potential and Existing List",
                                        ]}
                                        onChange={(event, value) => {
                                          setUsedStates((prevValue) => {
                                            return {
                                              ...prevValue,
                                              settingDetails: {
                                                ...prevValue.settingDetails,
                                                settingStatusDetails: {
                                                  ...prevValue.settingDetails
                                                    .settingStatusDetails,
                                                  vacancyListType: value,
                                                },
                                              },
                                            };
                                          });
                                        }}
                                        value={vacancyListType}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="VacancyList Type"
                                            variant="standard"
                                            id="vacancyListType"
                                            name="vacancyListType"
                                          />
                                        )}
                                      />
                                    ) : (
                                      <TextField
                                        label="VacancyList Type"
                                        variant="standard"
                                        id="vacancyListType"
                                        name="vacancyListType"
                                        value={vacancyListType}
                                        disabled
                                        fullWidth
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Manual insertion */}
                <div className="row mt-4">
                  <div className="col-xl-12">
                    <div className="card ">
                      <div className="card-header fw-bold ">Manual Insert</div>
                      <div className="card-body">
                        <div className="row g-1">
                          {/* Police Station */}
                          <div className="col-xl-4">
                            <MaterialTable
                              title={<span>Police Station</span>}
                              columns={[
                                {
                                  title: "Name",
                                  field: "name",
                                },

                                {
                                  title: "No of Years",
                                  field: "No_of_years",
                                  render: (rowData) => {
                                    return (
                                      <TextField
                                        variant="standard"
                                        fullWidth
                                        value={rowData.No_of_years}
                                        type="number"
                                        name="policeStation"
                                        onChange={(event) => {
                                          handleManualInsert({
                                            inputType: policeStationDetails,
                                            name: "No_of_years",
                                            value: event.target.value,
                                            index: rowData.tableData.id,
                                            updateType:
                                              updatePoliceStationDetails,
                                            isMerged: rowData.isMerged,
                                          });
                                        }}
                                      />
                                    );
                                  },
                                },
                              ]}
                              data={policeStationDetails}
                              options={{
                                paging: false,
                                headerStyle: {
                                  backgroundColor: "var(--default-bg-color)",
                                  color: "black",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                  position: "sticky",
                                  zIndex: 100,
                                },
                                maxBodyHeight: 350,
                                rowStyle: {
                                  whiteSpace: "nowrap",
                                },
                              }}
                            />

                            {/* Merge */}
                            <Button
                              type="reset"
                              variant="outlined"
                              className="success-button shadow mt-4"
                              onClick={() =>
                                openMergeModal({
                                  open: true,
                                  title: "Merge Police Stations",
                                  mergeTableData: policeStationDetails,
                                  typeOfData: "mergePoliceStation",
                                })
                              }
                            >
                              Merge
                            </Button>

                            {/* un Merge */}
                            <Button
                              type="reset"
                              variant="outlined"
                              className="reset-button shadow mt-4 ms-3"
                              onClick={() =>
                                openMergeModal({
                                  open: true,
                                  title: "Unmerge Police Stations",
                                  mergeTableData: policeStationDetails.filter(
                                    (data) => data.isMerged
                                  ),
                                  typeOfData: "unMergePoliceStation",
                                })
                              }
                            >
                              UnMerge
                            </Button>
                          </div>

                          {/* Circle */}
                          <div className="col-xl-4 ">
                            <MaterialTable
                              title={<span>Circle</span>}
                              columns={[
                                {
                                  title: "Name",
                                  field: "name",
                                },

                                {
                                  title: "No of Years",
                                  field: "No_of_years",
                                  render: (rowData) => {
                                    return (
                                      <TextField
                                        variant="standard"
                                        fullWidth
                                        value={rowData.No_of_years}
                                        type="number"
                                        name="circle"
                                        onChange={(event) => {
                                          handleManualInsert({
                                            inputType: circleDetails,
                                            name: "No_of_years",
                                            value: event.target.value,
                                            index: rowData.tableData.id,
                                            updateType: updateCircleDetails,
                                            isMerged: rowData.isMerged,
                                          });
                                        }}
                                      />
                                    );
                                  },
                                },
                              ]}
                              data={circleDetails}
                              options={{
                                paging: false,
                                headerStyle: {
                                  backgroundColor: "var(--default-bg-color)",
                                  color: "black",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                  position: "sticky",
                                  zIndex: 100,
                                },
                                maxBodyHeight: 350,
                                rowStyle: {
                                  whiteSpace: "nowrap",
                                },
                              }}
                            />

                            {/* Merge */}
                            <Button
                              type="reset"
                              variant="outlined"
                              className="success-button shadow mt-4"
                              onClick={() =>
                                openMergeModal({
                                  open: true,
                                  title: "Merge Circles",
                                  mergeTableData: circleDetails,
                                  typeOfData: "mergeCircle",
                                })
                              }
                            >
                              Merge
                            </Button>

                            {/* un Merge */}
                            <Button
                              type="reset"
                              variant="outlined"
                              className="reset-button shadow mt-4 ms-3"
                              onClick={() =>
                                openMergeModal({
                                  open: true,
                                  title: "Unmerge Circles",
                                  mergeTableData: circleDetails.filter(
                                    (data) => data.isMerged
                                  ),
                                  typeOfData: "unMergeCircle",
                                })
                              }
                            >
                              UnMerge
                            </Button>
                          </div>

                          {/* Division */}
                          <div className="col-xl-4">
                            <MaterialTable
                              title={<span>Division</span>}
                              columns={[
                                {
                                  title: "Name",
                                  field: "name",
                                },

                                {
                                  title: "No of Years",
                                  field: "No_of_years",
                                  render: (rowData) => {
                                    return (
                                      <TextField
                                        variant="standard"
                                        fullWidth
                                        value={rowData.No_of_years}
                                        type="number"
                                        name="division"
                                        onChange={(event) => {
                                          handleManualInsert({
                                            inputType: divisionDetails,
                                            name: "No_of_years",
                                            value: event.target.value,
                                            index: rowData.tableData.id,
                                            updateType: updateDivisionDetails,
                                            isMerged: rowData.isMerged,
                                          });
                                        }}
                                      />
                                    );
                                  },
                                },
                              ]}
                              data={divisionDetails}
                              options={{
                                paging: false,
                                headerStyle: {
                                  backgroundColor: "var(--default-bg-color)",
                                  color: "black",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                  position: "sticky",
                                  zIndex: 100,
                                },
                                maxBodyHeight: 350,
                                rowStyle: {
                                  whiteSpace: "nowrap",
                                },
                              }}
                            />

                            {/* Merge */}
                            <Button
                              type="reset"
                              variant="outlined"
                              className="success-button shadow mt-4"
                              onClick={() =>
                                openMergeModal({
                                  open: true,
                                  title: "Merge Divisions",
                                  mergeTableData: divisionDetails,
                                  typeOfData: "mergeDivision",
                                })
                              }
                            >
                              Merge
                            </Button>

                            {/* un Merge */}
                            <Button
                              type="reset"
                              variant="outlined"
                              className="reset-button shadow mt-4 ms-3"
                              onClick={() =>
                                openMergeModal({
                                  open: true,
                                  title: "Unmerge Divisions",
                                  mergeTableData: divisionDetails.filter(
                                    (data) => data.isMerged
                                  ),
                                  typeOfData: "unMergeDivision",
                                })
                              }
                            >
                              UnMerge
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* submit button */}
                <div className="buttons mt-1">
                  <Button
                    type="submit"
                    variant="outlined"
                    className="primary-button  shadow mt-3"
                  >
                    save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* show individual insertion */}
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        className="dialog-box"
        style={{ fontSize: "14px" }}
      >
        <DialogTitle className="fw-bold d-flex flex-row justify-content-end">
          <AiOutlineClose
            fontSize={25}
            style={{ cursor: "pointer" }}
            onClick={() =>
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  mergeModal: {
                    ...prevValue.mergeModal,
                    open: false,
                  },
                };
              })
            }
          />
        </DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            id="mergeList"
            title={<h5 className="fw-bold">{title}</h5>}
            columns={
              typeOfData.includes("un")
                ? [
                    {
                      title: "Name",
                      field: "name",
                    },

                    {
                      title: "",
                      field: "No_of_Years",
                      render: (rowData) => {
                        return (
                          <FormControlLabel
                            className="checkbox"
                            control={
                              <Checkbox
                                name="merge"
                                value={rowData.mergedValue}
                                onChange={(event) =>
                                  handleMergedInput({
                                    name: rowData.name,
                                    mergedArray: title.includes("Police")
                                      ? mergedPoliceStationData
                                      : title.includes("Circles")
                                      ? mergedCircleData
                                      : mergedDivisionData,
                                    value: event.target.checked,
                                    index: rowData.tableData.id,
                                  })
                                }
                                checked={rowData.mergedValue}
                              />
                            }
                          />
                        );
                      },
                    },
                  ]
                : [
                    {
                      title: "Name",
                      field: "name",
                      render: (rowData) => {
                        return rowData.isMerged ? "-" : rowData.name;
                      },
                    },

                    {
                      title: "",
                      field: "No_of_Years",
                      render: (rowData) => {
                        return rowData.isMerged ? (
                          "-"
                        ) : (
                          <FormControlLabel
                            className="checkbox"
                            control={
                              <Checkbox
                                name="merge"
                                value={rowData.mergedValue}
                                onChange={(event) =>
                                  handleMergedInput({
                                    name: rowData.name,
                                    mergedArray: title.includes("Police")
                                      ? mergedPoliceStationData
                                      : title.includes("Circles")
                                      ? mergedCircleData
                                      : mergedDivisionData,
                                    value: event.target.checked,
                                    index: rowData.tableData.id,
                                  })
                                }
                                checked={rowData.mergedValue}
                              />
                            }
                          />
                        );
                      },
                    },
                  ]
            }
            data={mergeTableData}
            options={{
              paging: false,
              headerStyle: {
                backgroundColor: "var(--default-bg-color)",
                color: "black",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                position: "sticky",
                zIndex: 100,
              },
              maxBodyHeight: 350,
              rowStyle: {
                whiteSpace: "nowrap",
              },
            }}
          />

          {/* Merge */}
          <Button
            type="reset"
            variant="outlined"
            className="primary-button shadow mt-4"
            onClick={() => handleMergeSubmit({ type: typeOfData })}
          >
            submit
          </Button>
        </DialogContent>
      </Dialog>

      {/* loading modal */}
      <Dialog className="loading-modal" open={loading}>
        <DialogContent>
          <BeatLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Setting;
