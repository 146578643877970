/*******************************  Comming Soon page *****************************************************/

import { React } from "./commonFiles/Import";

const CommingSoon = () => {
  return (
    <>
      {/* comming soon section */}
      <section className="comming-soon-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card border-0">
                <div className="card-body text-center">
                  <h1 className="card-title fs-3 fw-bold d-flex flex-column ">
                    <span>Comming Soon ......</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommingSoon;
